import { Common } from './Common.js';

var UserPwdResetVM = function () {
  var self = this;

  //console.log('Reset user password');

  self.$container = $(document);
  self.$form = $('');

  self.init = function ($dlgcontainer) {
    if ($dlgcontainer) {
      self.$container = $dlgcontainer;
      self.$form = self.$container.find('form');
    }

    ko.applyBindings(self, self.$container[0]);
  };

  self.SaveMyAccount = function () {
    if (!self.$form.validate().form()) return false;

    $.post(RazorShared.baseUrl + 'UserMgmt/SaveAccountSecurity', self.$form.serialize(), function (data) {
      if (!data.success) {
        Common.Dialog('Error saving user', null, data.error.split('|').join('<br>'));
      } else {
        self.$container.modal('hide');
        window.location = RazorShared.baseUrl + 'Login/Index';
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Save Password', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.CancelReset = function () {
    window.location = RazorShared.baseUrl + 'Login/Logout';
  };
};

export default UserPwdResetVM;
