import { JSPost } from './Common.js';
import Cookies from 'js-cookie';
import { Common } from './Common.js';
import DialogMgr from './DialogMgr.js';

var StaticFrequencyMgmtVM = function (jsonData) {
  var self = this;

  self.defaultListCount = 50;
  self.loadedCount = 0;
  self.cookieName = 'efcStaticFrequencySearch';
  var searchCookie = Cookies.getJSON(self.cookieName);

  var filterOn = jsonData.FilterOnSetOnly == true ? jsonData.StaticFrequencySearch : searchCookie || jsonData.StaticFrequencySearch;
  self.StaticFrequencySearch = new StaticFrequencySearch(filterOn, self.defaultListCount);
  self.StaticFrequencySearchHold = new StaticFrequencySearch(filterOn, self.defaultListCount);

  if (
    jsonData.StaticFrequencySearch &&
    jsonData.StaticFrequencySearch.StaticFrequencySetIDs != undefined &&
    jsonData.StaticFrequencySearch.StaticFrequencySetIDs.length > 0
  ) {
    self.StaticFrequencySearch.StaticFrequencySetIDs(jsonData.StaticFrequencySearch.StaticFrequencySetIDs);
    self.StaticFrequencySearchHold.StaticFrequencySetIDs(jsonData.StaticFrequencySearch.StaticFrequencySetIDs);
  }

  self.Areas = ko.observableArray((jsonData.Areas || []).sort(Common.StringSortFunc('Name')));
  self.Locations = ko.observableArray((jsonData.Locations || []).sort(Common.StringSortFunc('Name')));
  self.StaticFrequencySets = ko.observableArray((jsonData.StaticFrequencySets || []).sort(Common.StringSortFunc('Name')));
  self.Statuses = ko.observableArray(jsonData.Statuses || []);
  self.EnableAreas = ko.observable(jsonData.Areas && jsonData.Areas.length > 1);
  self.StaticFrequencyContacts = ko.observableArray((jsonData.StaticFrequencyContacts || []).sort(Common.StringSortFunc('Name')));

  self.load = function () {
    if (self.StaticFrequencySearchHold.AreaIDs().length > 0) {
      self.ReloadSearchLocations(self.StaticFrequencySearchHold.AreaIDs());
      if (self.StaticFrequencySearchHold.LocationIDs().length == 0) self.ReloadSearchFrequencySets(self.StaticFrequencySearchHold.AreaIDs(), true);
    }

    if (self.StaticFrequencySearchHold.LocationIDs().length > 0)
      self.ReloadSearchFrequencySetsByLocation(self.StaticFrequencySearchHold.LocationIDs(), true);

    self.setupSearchToggle($('#menu-toggle'));
    self.setAreaChangeTrigger($('#searchAreaID'));
    self.setLocationChangeTrigger($('#searchLocationID'));
    ko.applyBindings(self, document.getElementById('wrapper'));
    self.updateAllSelecListSelectedItemSort();
    self.LoadList();
  };

  self.LoadList = function (callback) {
    $('#loadMessage').show();
    $('#sectStaticFrequencyList').load(
      RazorShared.baseUrl + 'StaticFrequency/GetStaticFrequencyList',
      ko.toJS(self.StaticFrequencySearch),
      function (response, status, xhr) {
        if (status == 'error') Common.Dialog('Search', 'An error has occurred: ' + xhr.status + ' ' + xhr.statusText, null, xhr.status);
        else self.loadedCount = $('#numLoaded').val();
        $('#loadMessage').hide();
      }
    );
    Cookies.set(self.cookieName, ko.toJS(self.StaticFrequencySearch), {
      expires: 30
    });
  };

  self.ApplyFilters = function () {
    var temp = self.StaticFrequencySearch;
    self.StaticFrequencySearch = new StaticFrequencySearch(ko.toJS(self.StaticFrequencySearchHold), self.defaultListCount);
    self.StaticFrequencySearch.SortBy = temp.SortBy;
    self.StaticFrequencySearch.SortDesc = temp.SortDesc;
    self.LoadList();
  };

  self.ListMore = function () {
    if (self.StaticFrequencySearch.RetrieveCount > self.loadedCount)
      // already loaded everything
      return;
    self.StaticFrequencySearch.RetrieveCount += self.defaultListCount;
    self.LoadList();
  };

  self.SetSort = function (sort) {
    if (self.StaticFrequencySearch.SortBy == sort) {
      self.StaticFrequencySearch.SortDesc = !self.StaticFrequencySearch.SortDesc;
    } else {
      self.StaticFrequencySearch.SortBy = sort;
      self.StaticFrequencySearch.SortDesc = false;
    }
    self.LoadList();
  };

  self.ClearSearch = function () {
    self.StaticFrequencySearch.ClearSearch(self.defaultListCount);
    self.StaticFrequencySearchHold.ClearSearch(self.defaultListCount);
    if (!self.EnableAreas()) {
      //User only has access to 1 area and should always stay selected
      self.StaticFrequencySearch.AreaIDs(jsonData.StaticFrequencySearch.AreaIDs);
      self.StaticFrequencySearchHold.AreaIDs(jsonData.StaticFrequencySearch.AreaIDs);
    }
    self.LoadList();
  };

  $('#keyTrigger').on('click', () => {
    if ($('.freq.freqRow').hasClass('active')) {
      return;
    }
    setTimeout(() => {
      $('#keyTrigger').attr('data-balloon', null);
         $('.freq.freqRow').attr('style', `top: ${$('#keyTrigger').offset().top + $('#keyTrigger').height() + 2}px`).addClass('active');
    });
  });

  $('body.staticFrequencyMgmt').on('click', function (e) {
    $('.freq.freqRow').removeClass('active');
    $('#keyTrigger').attr('data-balloon', 'Frequency Key');
  });

  self.ActivateFrequency = function (id) {
    $.post(RazorShared.baseUrl + 'StaticFrequency/ActivateStaticFrequency/' + id, function (data) {
      if (data.success) self.LoadList();
      else Common.Dialog('Error activating Frequency', null, data.error.split('|').join('<br>'));
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Activate Frequency', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.ShowDeactivateConfirmation = function (id, name) {
    Common.DialogConfirmation(
      'Deactivate Static Frequency',
      null,
      'Do you want to remove frequency ' + name + '?',
      function () {
        self.DeactivateFrequency(id);
      },
      null
    );
  };

  self.DeactivateFrequency = function (id) {
    $.post(RazorShared.baseUrl + 'StaticFrequency/DeactivateStaticFrequency/' + id, function (data) {
      if (data.success) self.LoadList();
      else Common.Dialog('Error deactivating Frequency', null, data.error.split('|').join('<br>'));
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Deactivate Frequency', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.AddStaticFrequency = function (staticFrequencySetID) {
    DialogMgr.StaticFrequencyAdd(staticFrequencySetID || 0, self.LoadList);
  };

  self.EditStaticFrequency = function (staticFrequencyID) {
    DialogMgr.StaticFrequencyEdit(staticFrequencyID, self.LoadList);
  };

  self.EditStaticFrequencySet = function (staticFrequencySetID) {
    DialogMgr.AddEditStaticFrequencySet(staticFrequencySetID, self.LoadList);
  };

  self.EditStaticFrequencyContact = function (staticFrequencyContactID, name) {
    var title = 'Edit Contact: ' + name;
    DialogMgr.EditStaticFrequencyContact(title, staticFrequencyContactID, self.StaticFrequencyContactEdited);
  };

  self.StaticFrequencyContactEdited = function (staticFrequencyContact) {
    if (staticFrequencyContact && staticFrequencyContact.StaticFrequencyContactID) self.LoadList();
  };

  self.EditStaticFrequencyUser = function (appUserID, name) {
    var title = 'Edit Contact: ' + name;
    DialogMgr.UserAddEdit(appUserID, name, self.LoadList);
  };

  self.ReloadSearchLocations = function (areaIDs) {
    $.post(RazorShared.baseUrl + 'StaticFrequency/GetLocationsForAreas', { areaIDs: areaIDs }, function (data) {
      if (data.success) {
        self.StaticFrequencySearch.LocationIDs.removeAll();
        self.Locations(data.AvailLocations.sort(Common.StringSortFunc('Name')));
      } else {
        Common.Dialog('Error finding locations', null, data.error.split('|').join('<br>'));
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Find Locations', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.ReloadSearchFrequencySets = function (areaIDs, initialLoad) {
    $.post(RazorShared.baseUrl + 'StaticFrequency/GetStaticFrequencySetsForAreas', { areaIDs: areaIDs }, function (data) {
      if (data.success) {
        var selectedSets = [];
        self.StaticFrequencySearch.StaticFrequencySetIDs().forEach(function (id) {
          selectedSets.push(id);
        });

        if (!initialLoad) {
          self.StaticFrequencySearchHold.StaticFrequencySetIDs.removeAll();
          self.StaticFrequencySearch.StaticFrequencySetIDs(selectedSets); //Removeing from search hold removes from search so add it back
        }

        self.StaticFrequencySets(data.AvailStaticFrequencySets.sort(Common.StringSortFunc('Name')));

        if (!initialLoad) {
          var intersection = self
            .StaticFrequencySets()
            .filter((e) => selectedSets.indexOf(e.ID) !== -1)
            .map((e) => e.ID);
          self.StaticFrequencySearchHold.StaticFrequencySetIDs(intersection);
        }
      } else {
        Common.Dialog('Error finding static frequency sets', null, data.error.split('|').join('<br>'));
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Find Static Frequency Sets', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.ReloadSearchFrequencySetsByLocation = function (locationIDs, initialLoad) {
    $.post(RazorShared.baseUrl + 'StaticFrequency/GetStaticFrequencySetsForLocations', { locationIDs: locationIDs }, function (data) {
      if (data.success) {
        var selectedSets = [];
        self.StaticFrequencySearch.StaticFrequencySetIDs().forEach(function (id) {
          selectedSets.push(id);
        });

        if (!initialLoad) {
          self.StaticFrequencySearchHold.StaticFrequencySetIDs.removeAll();
          self.StaticFrequencySearch.StaticFrequencySetIDs(selectedSets); //Removeing from search hold removes from search so add it back
        }

        self.StaticFrequencySets(data.AvailStaticFrequencySets.sort(Common.StringSortFunc('Name')) || []);

        if (!initialLoad) {
          var intersection = self
            .StaticFrequencySets()
            .filter((e) => selectedSets.indexOf(e.ID) !== -1)
            .map((e) => e.ID);
          self.StaticFrequencySearchHold.StaticFrequencySetIDs(intersection);
        }
      } else {
        Common.Dialog('Error finding static frequency sets', null, data.error.split('|').join('<br>'));
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Find Static Frequency Sets', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.setupSearchToggle = function ($fld) {
    var toggleCookieName = self.cookieName + '_displayToggled';
    var cookie = Cookies.get(toggleCookieName);
    if (cookie) $fld.click();

    $fld.on('displayToggled', function () {
      if ($(this).hasClass('toggled')) Cookies.set(toggleCookieName, 'toggled');
      else Cookies.remove(toggleCookieName);
    });
  };

  self.setAreaChangeTrigger = function ($fld) {
    self.StaticFrequencySearchHold.AreaIDs.subscribe(function (newVaules) {
      self.ReloadSearchLocations(newVaules);
      self.ReloadSearchFrequencySets(newVaules);
    });
  };

  self.setLocationChangeTrigger = function ($fld) {
    self.StaticFrequencySearchHold.LocationIDs.subscribe(function (newValues) {
      self.ReloadSearchFrequencySetsByLocation(newValues);
    });
  };

  self.ExportCheck = null;
  self.Export = function () {
    Common.BlockUI('Export in progress...');

    var params = { search: ko.toJS(self.StaticFrequencySearch) };
    JSPost(RazorShared.baseUrl + 'StaticFrequency/ExportCSV', params);

    $.unblockUI();

    return false;
  };

  self.updateAllSelecListSelectedItemSort = function () {
    Common.ShowSelectedItemsOnTop('#searchAreaID');
    Common.ShowSelectedItemsOnTop('#searchLocationID');
    Common.ShowSelectedItemsOnTop('#searchStaticFrequencySetID');
    Common.ShowSelectedItemsOnTop('#searchStaticFrequencyContact');
  };
};

var StaticFrequencySearch = function (jsonData, defaultCount) {
  var self = this;

  self.SortBy = jsonData.SortBy || 0;
  self.SortDesc = jsonData.SortDesc || false;
  self.RetrieveCount = defaultCount;
  self.RetrieveStartAt = jsonData.RetrieveStartAt || null;

  self.AreaIDs = ko.observableArray(jsonData.AreaIDs || []);
  self.LocationIDs = ko.observableArray(jsonData.LocationIDs || []);
  self.StaticFrequencySetIDs = ko.observableArray(jsonData.StaticFrequencySetIDs || []);
  self.DescriptionLike = ko.observable(jsonData.DescriptionLike || null);
  self.FrequencyStart = ko.observable(jsonData.FrequencyStart || null);
  self.FrequencyEnd = ko.observable(jsonData.FrequencyEnd || null);
  self.Statuses = ko.observableArray(jsonData.Statuses || [1, 3]);
  self.StaticFrequencyContactIDs = ko.observableArray(jsonData.StaticFrequencyContactIDs || []);
  self.TrackInfo = '';

  self.ClearSearch = function (defaultCount) {
    if (defaultCount == undefined) return;

    self.AreaIDs([]);
    self.LocationIDs([]);
    self.StaticFrequencySetIDs([]);
    self.DescriptionLike(null);
    self.FrequencyStart(null);
    self.FrequencyEnd(null);
    self.Statuses([1, 3]);
    self.StaticFrequencyContactIDs([]);

    self.RetrieveCount = defaultCount;
    self.RetrieveStartAt = null;
  };
};

export default StaticFrequencyMgmtVM;
