import moment from 'moment/min/moment-with-locales';
import { Common } from './Common.js';

var WirelessSpectrumEditVM = function () {
  if (RazorShared.isDebug) console.log('WirelessSpectrumEditVM locator');

  var self = this;

  self.$container = $(document);
  self.$form = $('');
  self.okCallback = function () {};

  self.WirelessSpectrum = null;

  self.Countries = ko.observableArray([]);
  self.Markets = ko.observableArray([]);
  self.Blocks = ko.observableArray([]);

  self.AvailMarkets = ko.pureComputed(function () {
    if (!self.WirelessSpectrum) return [];
    return self.Markets().filter(function (x) {
      return self.WirelessSpectrum.CountryID() == x.ParentID;
    });
  });

  self.AvailBlocks = ko.pureComputed(function () {
    if (!self.WirelessSpectrum) return [];
    return self.Blocks().filter(function (x) {
      return self.WirelessSpectrum.CountryID() == x.ParentID;
    });
  });

  self.init = function ($dlgcontainer, params) {
    if ($dlgcontainer) {
      self.$container = $dlgcontainer;
      self.$form = self.$container.find('form');
    }

    if (params.okCallback) self.okCallback = params.okCallback;

    self.load(WirelessSpectrumEditVM.jsonData);

    // set title after loading entity data
    var $title = self.$container.find('.modal-title');
    if (WirelessSpectrumEditVM.jsonData.EntityViewModel.BlockName)
      $title.text(
        'Edit Wireless Spectrum: ' +
          WirelessSpectrumEditVM.jsonData.EntityViewModel.BlockName +
          ' ' +
          WirelessSpectrumEditVM.jsonData.EntityViewModel.MarketName
      );
    else $title.text('Add New Wireless Spectrum');
  };

  self.load = function (jsonData) {
    if (!jsonData) return;

    self.WirelessSpectrum = new WirelessSpectrumVM(jsonData.WirelessSpectrum || null);

    self.Countries(jsonData.Countries || []);
    self.Markets((jsonData.Markets || []).sort(Common.StringSortFunc('Name')));
    self.Blocks(jsonData.Blocks || []);

    ko.applyBindings(self, self.$container[0]);
  };

  self.Save = function () {
    if (!self.$form.validate().form()) return;

    if (self.WirelessSpectrum.InvalidDateOrder()) return;

    $.post(RazorShared.baseUrl + 'TVWireless/SaveWirelessSpectrum', ko.toJS(self.WirelessSpectrum), function (data) {
      if (!data.success) {
        Common.Dialog('Error saving Wireless Spectrum', null, data.error.split('|').join('<br>'));
      } else {
        self.$container.modal('hide');
        self.okCallback();
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Save Wireless Spectrum', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };
};

var WirelessSpectrumVM = function (jsonData) {
  var self = this;

  self.WirelessSpectrumID = jsonData.WirelessSpectrumID || 0;
  self.Owner = ko.observable(jsonData.Owner || '');
  self.CountryID = ko.observable(jsonData.CountryID || 0);
  self.MarketID = ko.observable(jsonData.MarketID || 0);
  self.WirelessBlockID = ko.observable(jsonData.WirelessBlockID || 0);
  self.ActiveDate = ko.observable(jsonData.ActiveDate || null);
  self.InactiveDate = ko.observable(jsonData.InactiveDate || null);

  self.InvalidDateOrder = ko.pureComputed(function () {
    return self.ActiveDate() && self.InactiveDate() && moment(self.ActiveDate()).isAfter(moment(self.InactiveDate()));
  });
};

export default WirelessSpectrumEditVM;
