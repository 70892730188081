import { Common } from './Common.js';

var CompanyEditVM = function () {
  var self = this;
  if (RazorShared.isDebug) console.log('CompanyEditVM locator');

  self.$container = $(document);
  self.okCallback = function () {};

  self.SelectedMergeInto = 0;
  self.MergeIDList = [];
  self.returnCompanyObj = false;

  self.init = function ($dlgcontainer, params) {
    if ($dlgcontainer) self.$container = $dlgcontainer;
    if (params.okCallback) self.okCallback = params.okCallback;
    if (params.returnCompanyObj) self.returnCompanyObj = params.returnCompanyObj;

    var $title = self.$container.find('.modal-title');
    if (params.title) $title.text(params.title);

    if (params.mergeIDList) self.MergeIDList = params.mergeIDList;
    if (self.MergeMode()) {
      self.ShowMerge();
      self.$container.find('#sectMergeList').show();
    } else {
      self.$container.find('#sectMergeList').hide();
    }

    if (params.companyID) self.ShowAddEdit(params.companyID);
    else self.ShowAddEdit(0);
  };

  self.MergeMode = function () {
    return $.isArray(self.MergeIDList) && self.MergeIDList.length > 0;
  };

  self.SelectCompany = function (companyID, control) {
    if (control) {
      self.$container.find('#divMerge article').removeClass('selected');
      if (control) $(control).addClass('selected');
    }
    self.SelectedMergeInto = companyID;
    self.ShowAddEdit(companyID);
  };

  self.SaveOrMerge = function () {
    var $usa = $('.usa');
    var isDisabled = $usa.attr('disabled');
    $usa.attr('disabled', false);

    var $companyForm = self.$container.find('#CompanyForm');

    var validator = $companyForm.data('validator');
    validator.settings.ignore = ':hidden:not(select.countryid, select.usa)';

    if (!$companyForm.validate().form()) return;

    if (isDisabled) $usa.attr('disabled', true);

    if (self.MergeMode()) {
      if (!self.SelectedMergeInto) {
        Common.Dialog('Merge Companies', 'Please select a company');
        return;
      }
      Common.DialogConfirmation(
        'Confirmation',
        'Are you sure you want to merge these companies into the selected one?',
        null,
        function () {
          self.MergeCompanies($companyForm);
        },
        null
      );
    } else {
      self.SaveCompany($companyForm);
    }
  };

  self.SaveCompany = function ($companyForm) {
    $.post(RazorShared.baseUrl + 'CompanyMgmt/SaveCompany', $companyForm.serialize(), function (data) {
      if (!data.success) {
        Common.Dialog('Error saving company', null, data.error.split('|').join('<br>'));
      } else {
        self.$container.modal('hide');
        self.okCallback(data.company);
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Save Company', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.MergeCompanies = function ($companyForm) {
    var postdata = $companyForm.serialize() + '&' + $.param({ mergeIDs: self.MergeIDList }, true); // sloppy but it works...
    $.post(RazorShared.baseUrl + 'CompanyMgmt/MergeCompanies', postdata, function (data) {
      if (!data.success) {
        Common.Dialog('Error merging companies', null, data.error.split('|').join('<br>'));
      } else {
        self.$container.modal('hide');
        self.okCallback(data.companyID, data.companyName);
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Merge Companies', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.ShowAddEdit = function (companyID) {
    self.$container
      .find('#sectAddEdit')
      .load(RazorShared.baseUrl + 'CompanyMgmt/AddEditCompany/' + (companyID ? companyID : ''), function (response, status, xhr) {
        if (status == 'error') Common.Dialog('Search', 'An error has occurred: ' + xhr.status + ' ' + xhr.statusText, null, xhr.status);
      });
  };

  self.ShowMerge = function () {
    self.$container
      .find('#sectMergeList')
      .load(
        RazorShared.baseUrl + 'CompanyMgmt/GetCompanyMergeList',
        $.param({ companyIDs: self.MergeIDList }, true),
        function (response, status, xhr) {
          if (status == 'error') Common.Dialog('Search', 'An error has occurred: ' + xhr.status + ' ' + xhr.statusText, null, xhr.status);
        }
      );
  };
};

export default CompanyEditVM;
