import 'vite/modulepreload-polyfill';

//Packages or style sheets you want to build need to be imported here
import './wrappers/jqueryWrapper.js';
import './wrappers/jqueryUIWrapper.js';
import './wrappers/knockoutWrapper.js';

import 'knockout-mapping';
import 'jquery-blockui';
import 'bootstrap-sass';
import 'bootstrap-colorpicker';
import 'bootstrap-select';
import 'tooltipster';
import 'bootstrap-tokenfield';

import moment from 'moment/min/moment-with-locales';

import './otherJS/Master.js';
import './otherJS/Login.js';
import './otherJS/Registration.js';
import './otherJS/EventFrequencyNotification.js';

import './thirdParty/kendo.ui.core.min.js';

//We must refer to this one by exact file path, because if we refer to it by package name, the package is configured to use the minified file.
//We can't use the minified file, as we need to apply a patch to the original to get it to work with our build process. This all works automatically.
import './node_modules/knockout-kendo/build/knockout-kendo.js';

import 'chosen-js';

import './all-styles.js';

import { Common } from './modules/Common.js';
import StartupFunction from './modules/StartupFunctions.js';
import { AssignFrequenciesVM, FrequencyAssignmentNoteDialogVM } from './modules/AssignFrequencies.js';
import { CompanyMgmtVM, CompanySearch } from './modules/CompanyMgmt.js';
import { EventAddEditVM } from './modules/EventAddEdit.js';
import { FreqBlockMgmtVM } from './modules/FrequencyBlockMgmt.js';
import { submitForm, enterNewCompany, selectCompany, frequencyReportPDFSetup } from './modules/GlobalFunctions.js';

import ActivityAddEditVM from './modules/ActivityAddEdit.js';
import AssignFrequencyNotificationHubVM from './modules/AssignFrequencyNotification.js';
import AssignToActivitiesVM from './modules/AssignToActivities.js';
import CompanyEditVM from './modules/CompanyEdit.js';
import ContactEditVM from './modules/OnsiteContactEdit.js';
import ContactMgmtVM from './modules/OnsiteContacts.js';
import DashboardVM from './modules/Dashboard.js';
import DialogMgr from './modules/DialogMgr.js';
import EquipmentTypeAddEditVM from './modules/EquipmentTypeAddEdit.js';
import EquipmentTypeMgmtVM from './modules/EquipmentTypeMgmt.js';
import EventFrequenciesVM from './modules/EventFrequenciesChart.js';
import EventMgmtVM from './modules/EventMgmt.js';
import AreaMgmtVM from './modules/AreaMgmt.js';
import AreaAddEditVM from './modules/AreaAddEdit.js';
import FrequencyBlockEditVM from './modules/FrequencyBlockAddEdit.js';
import LocationAddEditVM from './modules/LocationAddEdit.js';
import LocationMgmtVM from './modules/LocationMgmt.js';
import ManufacturerEditVM from './modules/ManufacturerAddEdit.js';
import ManufacturerMgmtVM from './modules/Manufacturers.js';
import ModelEditVM from './modules/EquipmentModelAddEdit.js';
import ModelMgmtVM from './modules/EquipmentModels.js';
import NavigationVM from './modules/Navigation.js';
import RequestEditVM from './modules/RequestAddEdit.js';
import RequestEquipmentEditVM from './modules/RequestEquipmentAddEdit.js';
import RequestNoteListEditVM from './modules/RequestNoteListEdit.js';
import StaticFrequencyAddEditVM from './modules/StaticFrequencyAddEdit.js';
import StaticFrequencyContactAddEditVM from './modules/StaticFrequencyContactAddEdit.js';
import StaticFrequencyMgmtVM from './modules/StaticFrequencyMgmt.js';
import StaticFrequencySetAddEditVM from './modules/StaticFrequencySetAddEdit.js';
import StaticFrequencySetMgmtVM from './modules/StaticFrequencySetMgmt.js';
import TVStationEditVM from './modules/TVStationAddEdit.js';
import TVWirelessMgmtVM from './modules/TVWirelessMgmt.js';
import UserAccountEditVM from './modules/UserAccountEdit.js';
import UserEditVM from './modules/UserEdit.js';
import UserMgmtVM from './modules/UserMgmt.js';
import UserPwdResetVM from './modules/UserPwdReset.js';
import WalkInEquipmentAddEditVM from './modules/WalkInEquipmentAddEdit.js';
import WalkInEquipmentMgmtVM from './modules/WalkInEquipmentMgmt.js';
import WirelessSpectrumEditVM from './modules/WirelessSpectrumAddEdit.js';

$.ajaxSetup({ cache: true });

//All of this is the equivalent of export to make it available in Razor
const ThirdPartyModules = {
  moment
};

window.ThirdPartyModules = ThirdPartyModules;

//This is used to share types between razor and built JS.
const GlobalModules = {
  DashboardVM,
  ActivityAddEditVM,
  AssignFrequencyNotificationHubVM,
  CompanyEditVM,
  DialogMgr,
  ModelEditVM,
  ManufacturerEditVM,
  ContactEditVM,
  RequestEditVM,
  RequestEquipmentEditVM,
  RequestNoteListEditVM,
  UserAccountEditVM,
  UserEditVM,
  UserPwdResetVM,
  WalkInEquipmentMgmtVM,
  AssignFrequenciesVM,
  AssignToActivitiesVM,
  Common,
  CompanyMgmtVM,
  CompanySearch,
  ContactMgmtVM,
  EquipmentTypeAddEditVM,
  EquipmentTypeMgmtVM,
  EventAddEditVM,
  EventFrequenciesVM,
  EventMgmtVM,
  AreaMgmtVM,
  AreaAddEditVM,
  FreqBlockMgmtVM,
  FrequencyAssignmentNoteDialogVM,
  FrequencyBlockEditVM,
  LocationAddEditVM,
  LocationMgmtVM,
  ManufacturerMgmtVM,
  ModelMgmtVM,
  StaticFrequencyAddEditVM,
  StaticFrequencyContactAddEditVM,
  StaticFrequencyMgmtVM,
  StaticFrequencySetAddEditVM,
  StaticFrequencySetMgmtVM,
  TVStationEditVM,
  TVWirelessMgmtVM,
  UserMgmtVM,
  WalkInEquipmentAddEditVM,
  WirelessSpectrumEditVM
};

const GlobalFunctions = {
  submitForm,
  enterNewCompany,
  selectCompany,
  frequencyReportPDFSetup
};

//This is used to share data (as opposed to types) between razor and built JS.
var Navigation = new NavigationVM();
let RazorShared = { Navigation };

Object.assign(window, { GlobalModules, GlobalFunctions, RazorShared });

$(function () {
  StartupFunction();
});
