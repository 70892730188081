import moment from 'moment/min/moment-with-locales';
import { Common, DegMinSecVM } from './Common.js';

var TVStationEditVM = function () {
  if (RazorShared.isDebug) console.log('TVStationEditVM locator');

  var self = this;

  self.$container = $(document);
  self.$form = $('');
  self.okCallback = function () {};

  self.TVStation = null;
  self.LatitudeDMS = null;
  self.LongitudeDMS = null;

  self.Countries = ko.observableArray([]);
  self.Channels = ko.observableArray([]);
  self.StandardTypes = ko.observableArray([]);
  self.DMSMode = ko.observable(false);

  self.AvailChannels = ko.pureComputed(function () {
    var avail = [];
    if (self.TVStation && self.TVStation.CountryID()) {
      self.Channels().forEach(function (c) {
        if (c.ParentID == self.TVStation.CountryID()) avail.push(c.ID);
      });
    }
    return avail;
  });

  self.init = function ($dlgcontainer, params) {
    if ($dlgcontainer) {
      self.$container = $dlgcontainer;
      self.$form = self.$container.find('form');
    }

    if (params.okCallback) self.okCallback = params.okCallback;

    self.load(TVStationEditVM.jsonData);

    // set title after loading entity data
    var $title = self.$container.find('.modal-title');
    $title.text(self.TVStation.CallSign() ? 'Edit TV Station ' + self.TVStation.CallSign() : 'Add New TV Station');
  };

  self.load = function (jsonData) {
    if (!jsonData) return;

    self.TVStation = new TVStationVM(jsonData.TVStation || null);

    self.Countries(jsonData.Countries || []);
    self.Channels(jsonData.Channels || []);
    self.StandardTypes(jsonData.StandardTypes || []);

    self.LatitudeDMS = new DegMinSecVM(self.TVStation.Latitude);
    self.LongitudeDMS = new DegMinSecVM(self.TVStation.Longitude);

    ko.applyBindings(self, self.$container[0]);
  };

  self.ToggleDMSMode = function () {
    self.DMSMode(!self.DMSMode());
  };

  self.Save = function () {
    if (!self.$form.validate().form()) return;

    if (self.TVStation.InvalidDateOrder()) return;

    $.post(RazorShared.baseUrl + 'TVWireless/SaveTVStation', ko.toJS(self.TVStation), function (data) {
      if (!data.success) {
        Common.Dialog('Error saving TV Station', null, data.error.split('|').join('<br>'));
      } else {
        self.$container.modal('hide');
        self.okCallback();
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Save TV Station', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };
};

var TVStationVM = function (jsonData) {
  var self = this;

  self.TVStationID = jsonData.TVStationID || 0;
  self.CountryID = ko.observable(jsonData.CountryID || 0);
  self.Channel = ko.observable(jsonData.Channel || 0);
  self.CallSign = ko.observable(jsonData.CallSign || '');
  self.Latitude = ko.observable(jsonData.Latitude || 0);
  self.Longitude = ko.observable(jsonData.Longitude || 0);
  self.Type = ko.observable(jsonData.Type || '');
  self.ProtectionDistance = ko.observable(jsonData.ProtectionDistance || 0);
  self.Power = ko.observable(jsonData.Power || 0);
  self.ActiveDate = ko.observable(jsonData.ActiveDate || null);
  self.InactiveDate = ko.observable(jsonData.InactiveDate || null);

  self.InvalidDateOrder = ko.pureComputed(function () {
    return self.ActiveDate() && self.InactiveDate() && moment(self.ActiveDate()).isAfter(moment(self.InactiveDate()));
  });
};

export default TVStationEditVM;
