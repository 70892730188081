import { Common } from './Common.js';

var EquipmentTypeAddEditVM = function (jsonData) {
  var self = this;
  if (RazorShared.isDebug) console.log('EquipmentTypeAddEditVM locator');

  self.$container = $(document);
  self.$form = $('');
  self.okCallback = function () {};
  self.EquipmentType = null;
  self.AddEditText = '';

  self.init = function ($dlgcontainer, params) {
    if ($dlgcontainer) {
      self.$container = $dlgcontainer;
      self.$form = self.$container.find('form');
    }

    if (params.okCallback) {
      self.okCallback = params.okCallback;
    }

    self.load(EquipmentTypeAddEditVM.jsonData);
  };

  self.load = function (jsonData) {
    self.EquipmentType = new EquipmentTypeVM(jsonData.EquipmentType);

    self.AddEditText = self.EquipmentType.EquipmentTypeID ? 'Edit Equipment Type: ' + self.EquipmentType.Description() : 'Add Equipment Type';

    ko.applyBindings(self, self.$container[0]);
  };

  self.Save = function () {
    if (!self.$form.validate().form()) return;

    $.post(RazorShared.baseUrl + 'EquipmentModel/SaveEquipmentType', self.$form.serialize(), function (data) {
      if (!data.success) {
        Common.Dialog('Error saving equipment type', null, data.error.split('|').join('<br>'));
      } else {
        self.$container.modal('hide');
        self.okCallback();
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Save Equipment Type', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };
};

var EquipmentTypeVM = function (jsonData) {
  var self = this;

  self.EquipmentTypeID = jsonData.EquipmentTypeID || 0;
  self.Description = ko.observable(jsonData.Description || '');
  self.Power = ko.observable(jsonData.Power);
  self.Bandwidth = ko.observable(jsonData.Bandwidth);
  self.ColorHex = ko.observable(jsonData.ColorHex || '#ef8b8b');
  self.Active = ko.observable(jsonData.Active || 0);
};

export default EquipmentTypeAddEditVM;
