﻿// Master.js

function getFirstTextNode($element) {
  return $element
    .contents()
    .filter(function () {
      return this.nodeType == 3;
    })
    .first();
}

function animateLabels(jElement, popClass) {
  var text = jElement.val();
  var label = jElement.closest('.input-group').find(popClass);

  if (typeof text === 'string') text = text.trim();
  if (text === 0) text = '0';

  if (label.length) {
    if (text || document.activeElement == jElement[0]) label.addClass('hasInput');
    else label.removeClass('hasInput');
  }
  if (jElement.hasClass('tokenHolder')) {
    if (text || document.activeElement == jElement[0]) jElement.parent().addClass('hasFocusOrContent');
    else jElement.parent().removeClass('hasFocusOrContent');
  }
  if (text || document.activeElement == jElement[0]) jElement.addClass('hasFocusOrContent');
  else jElement.removeClass('hasFocusOrContent');
}

function setupForms(selector) {
  if (!selector) selector = $(document);

  SetupTableHeaders(selector);

  selector.find('form').each(function () {
    var f = $(this);

    if (!f.hasClass('gdc-init')) {
      //DJM modified 7.21.17 - added 'container' parameter for the cases in which selectpickers are contained in modals
      //DJM modified 9.06.17 - added the if/else to determine if the page loading is Registration, which was facing an issue with dropdown population.
      if (document.getElementsByClassName('register').length !== 0 && document.getElementById('SubmitRegistration')) {
        f.find('.selectpicker').selectpicker({ selectOnTab: true });
      } else {
        f.find('.selectpicker').selectpicker({
          selectOnTab: true,
          container: 'div.modal-body:last'
        });
      }

      // enter and (when dropdown open) esc are are handled in bs-select's keydown, so we need to suppress our own keyup handling
      // DJM 4.03.19 - Added chosen-container class to ensure same functionality for tokenfields and dropdowns across the board
      f.find('.bootstrap-select, .chosen-container')
        .on('keyup', function (e) {
          var $c = $(this);
          if ($c.data('keydownhandled') == e.keyCode) {
            e.preventDefault();
            e.stopPropagation();
          }
          $c.removeData('keydownhandled');
        })
        .on('keydown', function (e) {
          var $c = $(this);
          // $($c.children()[1])[0].attributes[1].value.indexOf('left') !== -1
          // is a very complicated way of saying "does the child chosen-drop have a 'left' attribute in its styles or not?"
          // because it's the most reliable way of tracking if the dropdown is open.
          // if it's closed, it'll default to its -9999px class-based "left" value.
          if ((e.keyCode == 27 && $($c.children()[1])[0].attributes[1].value.indexOf('left') !== -1) || e.keyCode == 13)
            $c.data('keydownhandled', e.keyCode);
          else $c.removeData('keydownhandled');
        });

      f.find('.token-input').on('keyup', function (e) {
        e.preventDefault();
        e.stopPropagation();
      });

      f.find('.token-input').autocomplete({
        focus: function (event, ui) {
          event.preventDefault();
          $(this).val(ui.item.label);
        }
      });

      f.find('.datepicker').datetimepicker({
        format: 'L',
        viewMode: 'days',
        focusOnShow: false
      });

      f.find('.datetimepicker').datetimepicker({
        format: 'lll',
        viewMode: 'days',
        focusOnShow: false
      });

      f.find('select.countryid, input.countryid').change(function () {
        var $usa = $('.usa');
        var curVal = $(this).val();
        if (!curVal || curVal == 234) {
          $usa.removeAttr('disabled');
          $usa.removeClass('dynamic-disabled');
        } else {
          $usa.val(null).trigger('change');
          $usa.addClass('dynamic-disabled');
          $usa.attr('disabled', true);
        }
      });

      f.find('input[data-val-number]').each(function () {
        if (/^The field .* must be a number\./.test($(this).attr('data-val-number'))) $(this).attr('data-val-number', 'Must be a number');
      });

      f.find('input[data-val-length-max]').each(function (index) {
        $(this).attr('maxlength', $(this).attr('data-val-length-max'));
      });

      f.find('input[data-val-required]').each(function () {
        var $inp = $(this);
        if ($inp.attr('placeholder')) $inp.attr('placeholder', $inp.attr('placeholder') + ' *');

        var $label = $inp.closest('.input-group').find('label.pop');
        if ($label.text()) getFirstTextNode($label).replaceWith($label.text() + ' * ');
      });

      f.find('select[data-val-required]').each(function () {
        var $inp = $(this);
        var $opt = $inp.find("[value='']");
        if ($opt.text()) $opt.text($opt.text() + ' *');

        if ($inp.hasClass('selectpicker')) {
          $inp.selectpicker('render');
        }

        var $label = $inp.closest('.input-group').find('label.pop');
        if ($label.text()) getFirstTextNode($label).replaceWith($label.text() + ' * ');
      });

      f.find('input[data-val-requiredif]').each(function () {
        var $inp = $(this);
        $inp.attr('base-placeholder', $inp.attr('placeholder'));
        var otherFld = $inp.attr('data-val-requiredif-other');
        var otherVal = $inp.attr('data-val-requiredif-otherval');
        if (otherFld.indexOf('*.') === 0) {
          otherFld = otherFld.replace('*.', '.');
        }

        var $label = $inp.closest('.input-group').find('label.pop');
        $label.attr('base-text', $label.text());

        var otherElem = f.find(':input').filter("[name$='" + otherFld + "']");
        otherElem.on('check-dependencies', function () {
          var newVal = $(this).val();
          if (newVal == otherVal) {
            if ($inp.attr('base-placeholder')) $inp.attr('placeholder', $inp.attr('base-placeholder') + ' *');
            if ($label.attr('base-text')) getFirstTextNode($label).replaceWith($label.attr('base-text') + ' * ');
          } else {
            $inp.attr('placeholder', $inp.attr('base-placeholder'));
            getFirstTextNode($label).replaceWith($label.attr('base-text'));
          }
        });
      });

      f.find('select[data-val-requiredif]').each(function () {
        var $inp = $(this);
        var $opt = $inp.find("[value='']");
        $opt.attr('base-text', $opt.text());

        var otherFld = $inp.attr('data-val-requiredif-other');
        var otherVal = $inp.attr('data-val-requiredif-otherval');
        if (otherFld.indexOf('*.') === 0) {
          otherFld = otherFld.replace('*.', '.');
        }

        var $label = $inp.closest('.input-group').find('label.pop');
        $label.attr('base-text', $label.text());

        var otherElem = f.find(':input').filter("[name$='" + otherFld + "']");
        otherElem.on('check-dependencies', function () {
          var newVal = $(this).val();
          if (newVal == otherVal) {
            if ($opt.attr('base-text')) $opt.text($opt.attr('base-text') + ' *');
            if ($label.attr('base-text')) getFirstTextNode($label).replaceWith($label.attr('base-text') + ' * ');
          } else {
            $opt.text($opt.attr('base-text'));
            getFirstTextNode($label).replaceWith($label.attr('base-text'));
          }

          if ($inp.hasClass('selectpicker')) {
            $inp.selectpicker('render');
          }
        });
      });

      f.find('*').trigger('change');
      f.find('*').trigger('check-dependencies');

      f.data('validator', null);
      $.validator.unobtrusive.parse(f);

      f.addClass('gdc-init');
    }
  });
}

// Production steps of ECMA-262, Edition 6, 22.1.2.1
// Polyfill for IE
if (!Array.from) {
  Array.from = (function () {
    var toStr = Object.prototype.toString;
    var isCallable = function (fn) {
      return typeof fn === 'function' || toStr.call(fn) === '[object Function]';
    };
    var toInteger = function (value) {
      var number = Number(value);
      if (isNaN(number)) {
        return 0;
      }
      if (number === 0 || !isFinite(number)) {
        return number;
      }
      return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
    };
    var maxSafeInteger = Math.pow(2, 53) - 1;
    var toLength = function (value) {
      var len = toInteger(value);
      return Math.min(Math.max(len, 0), maxSafeInteger);
    };

    // The length property of the from method is 1.
    return function from(arrayLike /*, mapFn, thisArg */) {
      // 1. Let C be the this value.
      var C = this;

      // 2. Let items be ToObject(arrayLike).
      var items = Object(arrayLike);

      // 3. ReturnIfAbrupt(items).
      if (arrayLike == null) {
        throw new TypeError('Array.from requires an array-like object - not null or undefined');
      }

      // 4. If mapfn is undefined, then let mapping be false.
      var mapFn = arguments.length > 1 ? arguments[1] : void undefined;
      var T;
      if (typeof mapFn !== 'undefined') {
        // 5. else
        // 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
        if (!isCallable(mapFn)) {
          throw new TypeError('Array.from: when provided, the second argument must be a function');
        }

        // 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
        if (arguments.length > 2) {
          T = arguments[2];
        }
      }

      // 10. Let lenValue be Get(items, "length").
      // 11. Let len be ToLength(lenValue).
      var len = toLength(items.length);

      // 13. If IsConstructor(C) is true, then
      // 13. a. Let A be the result of calling the [[Construct]] internal method
      // of C with an argument list containing the single item len.
      // 14. a. Else, Let A be ArrayCreate(len).
      var A = isCallable(C) ? Object(new C(len)) : new Array(len);

      // 16. Let k be 0.
      var k = 0;
      // 17. Repeat, while k < len… (also steps a - h)
      var kValue;
      while (k < len) {
        kValue = items[k];
        if (mapFn) {
          A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
        } else {
          A[k] = kValue;
        }
        k += 1;
      }
      // 18. Let putStatus be Put(A, "length", len, true).
      A.length = len;
      // 20. Return A.
      return A;
    };
  })();
}

$(function () {
  //$('body').keyup(function (e) {
  //   if (e.keyCode == 13 && $('#myModal').is(":visible")) {
  //      $('#myModal').modal('hide');
  //   }
  //});

  $('body').on('changed.bs.select', '.selectpicker', function () {
    animateLabels($(this), 'label.pop');
    $(this).trigger('check-dependencies');
  });

  $('body').on('change keyup input focus blur', 'input[type=text], input[type=password], select, textarea', function () {
    animateLabels($(this), 'label.pop');
    $(this).trigger('check-dependencies');
  });

  $('body').on('dp.change', 'div.datepicker, div.datetimepicker', function () {
    animateLabels($(this).find('input[type=text]'), 'label.pop');
    $(this).find('input[type=text]').trigger('check-dependencies');
  });

  // PAGE SIDEBAR OPENING AND CLOSING + ANIMATION
  $('#menu-toggle').click(function (e) {
    e.preventDefault();
    $('#wrapper').toggleClass('toggled');
    $('#menu-toggle').toggleClass('toggled');
    $('#menu-toggle').trigger('displayToggled');

    // var trigger = document.getElementById("menu-toggle");
    // if ($("#wrapper").hasClass("toggled")) {
    // trigger.setAttribute("from", "14.3,11.7 0.7,30.1 14.3,48.5 21.1,48.5 9.7,33 62.8,33 62.8,27.2 9.7,27.2 21.1,11.7");
    // trigger.setAttribute("to", "62.8,0.7 31.8,0.7 10,0.7 0.8,0.7 27.8,31.5 27.8,59.4 35.7,55.5 35.7,31.5 52.7,12.2");
    // } else {
    // trigger.setAttribute("from", "62.8,0.7 31.8,0.7 10,0.7 0.8,0.7 27.8,31.5 27.8,59.4 35.7,55.5 35.7,31.5 52.7,12.2");
    // trigger.setAttribute("to", "14.3,11.7 0.7,30.1 14.3,48.5 21.1,48.5 9.7,33 62.8,33 62.8,27.2 9.7,27.2 21.1,11.7");
    // }
    //trigger.beginElement();
  });
  // PAGE SIDEBAR END

  $(document).ajaxComplete(function (event) {
    setupForms();
    UpdateTableHeaders();
  });

  $.ajaxSetup({ cache: false });

  $(document).on('tokenfield:createdtoken', 'input.tokenMaxOne', function (e) {
    //only one token allowed, so clear placeholder when token selected
    $(this).parent().find('input.token-input').attr('placeholder', '');
  });

  // [DJM] jquery API for dragging - used in Modal windows in this application
  $(document).on('dragstart', function (e) {
    removeDropdown();
  });

  $(document).on('shown.bs.modal', '.modal', function (e) {
    var inputs = $(this).find('select, input, textarea, button, a').filter(':visible').filter(':not(.close)').filter(':not([disabled])');
    var firstInput = inputs.first();
    var lastInput = inputs.last();
    firstInput.focus();
    firstInput.select();
    if ($('div#divNoteListEdit textarea#newNoteText').length) $('div#divNoteListEdit textarea#newNoteText').focus();
    if (firstInput.parents('div#divModel.addAnother').length) $('div#divModel.addAnother  input#Name').focus();

    lastInput.on('keydown', function (e) {
      if (e.which === 9 && !e.shiftKey) {
        e.preventDefault();
        firstInput.focus();
        firstInput.select();
      }
    });

    firstInput.on('keydown', function (e) {
      if (e.which === 9 && e.shiftKey) {
        e.preventDefault();
        lastInput.focus();
        lastInput.select();
      }
    });

    // Added by DJM 8-18-17 for dropdown in modals fix
    /*   This is doubled in order to ensure that the fields are captured when the modal opens - 
           unfortunately while we can catch the modal opening as an event, we can't catch all
           the fields loading. So we need to do two setTimeouts to ensure that the fields have
           all the necessary properties set. One at the inner-range of when they're likely to load
           and one at the outer-range. */
    setTimeout(function () {
      setClickFields();
    }, 100);
    setTimeout(function () {
      setClickFields();
    }, 750);
  });

  $(document).on('hidden.bs.modal', '.modal', function (e) {
    if ($('.modal-content.ui-draggable').length > 0) $('body').css('overflow-y', 'hidden');
    else $('body').css('overflow-y', '');
  });

  // Added by DJM 3-08-18 in order to ensure that the [...] detail dropdowns drop-up if at bottom of screen
  $(document).delegate('.btn.btn-default.btn-xs.dropdown-toggle', 'click', function () {
    if ($('.modal-content.ui-draggable').length > 0) return;

    var spaceFromBottom = 200;
    var paneScrollVal = $(window).scrollTop();
    var paneHeight = $(window).height();
    var thisval = $(this).parent().position().top;

    if ($('section#sectRequestList').length > 0 && $('div#Requests.body').length > 0) spaceFromBottom = 350;
    if ($('body.assignFrequency').length > 0 && $('div.table.equipment').length > 0) {
      paneScrollVal = $('div.table.equipment').scrollTop();
      paneHeight = $('div.table.equipment').height();
      //we want the calculated value to be the distance between the top of the [...] and the top of the pane.
      //so we calculate and subtract offsets from document top.
      thisval = $(this).parent().offset().top - $('div.table.equipment').offset().top;
      //var positionWithin = $(this).parent().offset().top -$('div.table.equipment').offset().top;
      spaceFromBottom = $(this).siblings('ul.dropdown-menu').height() + thisval + 10;
      //console.log(paneScrollVal);
      //console.log('+');
      //console.log(paneHeight);
      //console.log('-');
      //console.log(thisval);
      //console.log('+');
      //console.log(spaceFromBottom + ' or (' + $(this).siblings('ul.dropdown-menu').height() + '+' + (thisval + 10) + ')');
      //console.log('Is ' + ((paneScrollVal + paneHeight) - (thisval + spaceFromBottom)) + ' lesser than or equal to 0?');
      //console.log((((paneScrollVal + paneHeight) - (thisval + spaceFromBottom)) <= 0));

      //Sort of a temporary fix for Assign Freqs, to be implmented across the other [...]s too, later.
      if (paneHeight - (thisval + spaceFromBottom) <= 0 && $(this).find('.glyphicon.glyphicon-option-horizontal').length > 0)
        $(this).siblings('ul.dropdown-menu').css({ top: 'auto', bottom: '100%' });
      else $('ul.dropdown-menu').css({ top: '100%', bottom: 'auto' });
      return;
    }

    if (paneScrollVal + paneHeight - thisval <= spaceFromBottom && $(this).find('.glyphicon.glyphicon-option-horizontal').length > 0)
      $(this).siblings('ul.dropdown-menu').css({ top: 'auto', bottom: '100%' });
    else $('ul.dropdown-menu').css({ top: '100%', bottom: 'auto' });
  });
  // Same as above but specifically pertaining to EFC-367: the Frequency dropdowns on Assign Frequencies
  $(document).delegate('.btn.btn-default.btn-xs.dropdown-toggle.divBtn', 'click', function () {
    // Currently this isn't working because the screen dims and it loads before the dropdown shows
    // in other words, the dropdown isn't dropping fast enough to be caught by this function.
    //if ($('div.table.equipment').find('.body').css('padding-bottom') !== '100px' && $('div.table.equipment').height() <= ($('div.table.equipment').find('.body').height() + 100)) {
    //    $('div.table.equipment').find('.body').css('padding-bottom', '100px');
    //    $('div.table.equipment').find('.body').animate({ scrollTop: $('div.table.equipment').find('.body').height()}, "slow");
    //}
    //console.log($('div.table.equipment').find('.body')[0]);
    //console.log($('div.table.equipment').find('.body'));
    //console.log($('div.table.equipment').find('.body').css('padding-bottom'));
    //if (($('div.table.equipment').scrollTop() + $('div.table.equipment').height() - $(this).offset().top) <= 200) {
    //    $('div.arrow-up').remove();
    //    $('ul.dropdown-menu.dropdown-menu-right').css({ top: 'auto', bottom: '100%' });
    //}
    //else
    //    $('ul.dropdown-menu.dropdown-menu-right').css({ top: '100%', bottom: 'auto' });
  });

  setupForms();
});

// Added by DJM 8-18-17 for dropdown in modals fix ***************************
// For ensuring the loop when checking for open dropdowns doesn't execute after it's found one
var m = false;
// setClickFields affixes all bootstrap-select elements with the necessary onclicks to give their child dropdowns the dimension parameters they need
// it also affixes all modal-body elements with the onscroll attribute for closing aforementioned dropdowns
function setClickFields() {
  var n = document.getElementsByClassName('modal-body');
  //console.log(n);
  for (let v = 0; v < n.length; v++) {
    n[v].setAttribute('onscroll', 'removeDropdown()');
    var m = n[v].getElementsByClassName('bootstrap-select');
    for (let w = 0; w < m.length; w++) {
      m[w].setAttribute('onclick', 'setCoords(this)');
    }
    var o = n[v].getElementsByClassName('input-group date');
    for (let y = 0; y < o.length; y++) {
      o[y].children[0].setAttribute('onfocus', 'setCoords(this.parentElement)');
      o[y].children[1].setAttribute('onclick', 'setCoords(this.parentElement)');
    }
    var p = n[v].getElementsByClassName('dropdown-toggle');
    for (let y = 0; y < p.length; y++) {
      p[y].setAttribute('onclick', 'setCoords(this)');
    }
  }
}
//setCoords obtains the coordinates for the dropdown's parent button and passes them as a string to searchNodes
function setCoords(a) {
  var modals = document.getElementsByClassName('modal-body');
  var coords = a.getBoundingClientRect();
  var coordsP = modals[modals.length - 1].getBoundingClientRect();
  var leftBit = coords.left - coordsP.left;
  var rightBit = coordsP.right - coords.right;
  var width = coords.right - coords.left;
  var parentHeight = a.clientHeight;
  var bottom = coords.top - parentHeight;
  // top is the parent node's top bound
  // left is the parent node's left bound minus the parent modal's
  // right is the parent node's right bound subtracted *from* the parent modal's
  var coordString = {
    top: coords.top,
    bottom: bottom,
    left: leftBit,
    right: rightBit,
    minWidth: width,
    parentRight: coordsP.right
  };
  searchNodes(modals[modals.length - 1], coordString);
}
// searchNodes checks all nodes branching from the parent bootstrap-select element for dropdown-menu elements to provide with proper dimensions
// Correction: It *used* to search all nodes from the parent bootstrap-select. Now it actually searches for all of them contained in the same modal;
// The reason for this is that Bootstrap doesn't apply all dropdowns as children of their parents. Some are appended to the end of the modal instead.
function searchNodes(n, coordString) {
  var menuArray = n.getElementsByClassName('dropdown-menu');
  var calendar = n.getElementsByClassName('bootstrap-datetimepicker-widget')[0];
  if (calendar) {
    //console.log('calendar!');
    if (calendar.clientHeight >= window.innerHeight - coordString.top) {
      calendar.style['top'] = coordString.bottom - calendar.clientHeight + 'px';
      //calendar.style["bottom"] = (coordString.top) + 'px';
      //calendar.style["marginBottom"] = (!!document.documentMode) ? '45px' : '5px';
    } else {
      calendar.style['top'] = coordString.top + 'px';
      calendar.style['marginTop'] = '5px';
    }
    // (!!document.documentMode) is to detect IE
    calendar.style['right'] = !!document.documentMode
      ? window.innerWidth - coordString.parentRight + coordString.right + 'px'
      : coordString.right + 'px';
    calendar.style['left'] = !!document.documentMode ? 'auto' : 'unset';
    calendar.style['width'] = 'fit-content';
    calendar.style['height'] = 'fit-content';
  } else {
    for (let x = 0; x < menuArray.length; x++) {
      if (menuArray[x].className.indexOf('open') !== -1) {
        menuArray[x].style['marginTop'] = '5px';
        menuArray[x].style['top'] = coordString.top + 'px';
        menuArray[x].style['left'] = 'unset';
        menuArray[x].style['width'] = coordString.minWidth + 'px';
        menuArray[x].style['minWidth'] = coordString.minWidth + 'px';
        menuArray[x].style['minHeight'] = 'fit-content';

        // DJM fix for EFC-961: the select contact dropdown should always drop up instead.
        if ($(menuArray[x]).parent().parent().attr('id') == 'selectContact' && !(/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream)) {
          menuArray[x].style['top'] = '';
          menuArray[x].style['bottom'] = '100px';
        }

        // navigator.vendor.indexOf('Apple Computer') !== -1 is, sort of obviously, a method for finding whether or not the browser is Safari.
        if (navigator.vendor.indexOf('Apple Computer') !== -1) {
          menuArray[x].style['height'] = '-webkit-fit-content';
          menuArray[x].style['maxHeight'] = 'none';
        }
      } else if (
        menuArray[x].parentElement.className.indexOf('dropdown') !== -1 &&
        menuArray[x].parentElement.className.indexOf('dropdown-menu') === -1
      ) {
        menuArray[x].style['position'] = 'fixed';
        menuArray[x].style['min-width'] = '100px';
        menuArray[x].style['top'] = !!document.documentMode ? coordString.top + 25 + 'px' : coordString.top - 5 + 'px';
        menuArray[x].style['right'] = !!document.documentMode
          ? window.innerWidth - coordString.parentRight + coordString.right + 'px'
          : coordString.right + 'px';
        menuArray[x].style['left'] = !!document.documentMode ? 'auto' : 'unset';
      }
    }

    var observer = new MutationObserver(function (mutations) {
      if (target.style['min-width'] == '' || !target.style['min-width']) {
        mutations.forEach(function (mutationRecord) {
          //console.log('called observer');
          target.style['minWidth'] = coordString.minWidth + 'px';
        });
      }
    });

    var target = menuArray[0];
    //console.log('Target is:');
    //console.log(target);
    observer.observe(target, { attributes: true, attributeFilter: ['style'] });
  }
  /* if (m) {
       return;
   }
   n = n.firstChild;
   while (n) {
       if (n.nodeType !== 3 &&
           n.nodeType !== 8 &&
           n.getAttribute('class') &&
           n.getAttribute('class').indexOf('dropdown-menu') !== -1) {
               n.style["top"] = coordString.top + 'px';
               n.style["left"] = coordString.left + 'px';
               n.style["minWidth"] = coordString.minWidth + 'px';
               m = true;
               return;
           }
       searchNodes(n, coordString);
       n = n.nextSibling;
   } */
}

// removeDropdown is triggered on modal-body onscroll in order to close any open dropdowns
function removeDropdown() {
  //n = n.firstChild;
  var n = document.getElementsByClassName('btn-group');
  for (let v = 0; v < n.length; v++) {
    if (n[v].getAttribute('class').indexOf('open') !== -1) {
      var styleString = n[v].getAttribute('class');
      styleString = styleString.replace('open', '');
      n[v].setAttribute('class', styleString);
      return;
    }
  }
  n = document.getElementsByClassName('bootstrap-datetimepicker-widget')[0];
  if (n) n.parentElement.removeChild(n);
}

// SIDEBAR STICK

$(window).scroll(function () {
  var scroll = $(window).scrollTop();

  if (scroll >= 91) {
    $('.sideBar').addClass('fixed');
  } else {
    $('.sideBar').removeClass('fixed');
  }
});

// END SIDEBAR STICK

// PERSISTANT HEADERS

function UpdateTableHeaders() {
  $('.persist-area').each(function () {
    var el = $(this),
      offset = el.offset(),
      scrollTop = $(window).scrollTop(),
      floatingHeader = $('.floatingHeader', this);

    if (scrollTop > offset.top && scrollTop < offset.top + el.height()) {
      floatingHeader.css({
        display: 'flex',
        top: $(window).scrollTop() - offset.top + 89 + 'px',
        width: $(this).width()
      });
    } else {
      floatingHeader.css({
        display: 'none'
      });
    }
  });
}

$(window).scroll(UpdateTableHeaders);
$(window).resize(UpdateTableHeaders);

$(function () {
  SetupTableHeaders($(document));
});

function SetupTableHeaders($section) {
  var clonedHeaderRow;
  $section.find('.persist-area').each(function () {
    clonedHeaderRow = $('.persist-header', this);
    if (!clonedHeaderRow.hasClass('floatingHeader')) {
      clonedHeaderRow.before(clonedHeaderRow.clone()).css('width', clonedHeaderRow.width()).addClass('floatingHeader');
    }
  });
}

// END PERSISTANT HEADERS

// FIXED SCROLL BARS
$(function ($) {
  var fixedBarTemplate = '<div class="fixed-scrollbar"><div></div></div>';
  var fixedBarCSS = {
    display: 'none',
    overflowX: 'scroll',
    position: 'fixed',
    width: '100%',
    bottom: 0
  };

  $('.fixed-scrollbar-container').each(function () {
    var $container = $(this);
    if ($container[0].offsetWidth < $container[0].scrollWidth) {
      var $bar = $(fixedBarTemplate).appendTo($container).css(fixedBarCSS);

      $bar.scroll(function () {
        $container.scrollLeft($bar.scrollLeft());
      });

      $bar.data('status', 'off');
    }
  });

  // var scrollBarContainerUpdate = function($container) {
  //    if ($container[0].offsetWidth < $container[0].scrollWidth) {
  //       var $bar = $(fixedBarTemplate).appendTo($container).css(fixedBarCSS);
  //
  //       $bar.scroll(function () {
  //          $container.scrollLeft($bar.scrollLeft());
  //       });
  //
  //       $bar.data("status", "off");
  //    }
  // }
  //
  // $('.fixed-scrollbar-container').each(function () {
  //    var $container = $(this);
  //
  //    scrollBarContainerUpdate($container);
  //
  //    $(window).resize(function(){
  //       scrollBarContainerUpdate($container);
  //       $container.trigger("scroll.fixedbar");
  //    })
  // });

  var fixSize = function () {
    $('.fixed-scrollbar').each(function () {
      var $bar = $(this);
      var $container = $bar.parent();

      $bar.children('div').height(1).width($container[0].scrollWidth);
      $bar.width($container.width()).scrollLeft($container.scrollLeft());
    });

    $(window).trigger('scroll.fixedbar');
  };

  $(window).on('load.fixedbar resize.fixedbar', function () {
    fixSize();
  });

  var scrollTimeout = null;

  $(window).on('scroll.fixedbar', function () {
    clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(function () {
      $('.fixed-scrollbar-container').each(function () {
        var $container = $(this);
        var $bar = $container.children('.fixed-scrollbar');

        if ($bar.length && $container[0].scrollWidth > $container.width()) {
          var containerOffset = {
            top: $container.offset().top,
            bottom: $container.offset().top + $container.height()
          };
          var windowOffset = {
            top: $(window).scrollTop(),
            bottom: $(window).scrollTop() + $(window).height()
          };

          if (containerOffset.top > windowOffset.bottom || windowOffset.bottom > containerOffset.bottom) {
            if ($bar.data('status') == 'on') {
              $bar.hide().data('status', 'off');
            }
          } else {
            if ($bar.data('status') == 'off') {
              $bar.show().data('status', 'on');
              $bar.scrollLeft($container.scrollLeft());
            }
          }
        } else {
          if ($bar.data('status') == 'on') {
            $bar.hide().data('status', 'off');
          }
        }
      });
    }, 50);
  });

  $(window).trigger('scroll.fixedbar');
});
// END FIXED SCROLL BARS

// function updateHeaderTop() {
//    console.log('updateHeaderTop');
//    if ($(window).scrollTop() > 1) {
//      $('.floatingHeader').css('top', $(window).scrollTop() + 'px');
//   }
// }

// DJM Addition, 2.20.19
// We temporarily switched over from bootstrap-select to chosen.js for only country and state dropdowns
// There was a long load-time issue which only impacted modals loading country and/or state
// This is the temporary solution until we update bootstrap.
// The code below is just to make sure the chosenjs dropdowns float fixed below their fields.

// DJM Addition, 3.27.19
// Haha, just kidding. Now we're changing a bunch of other fields to the new control, too. Added chosen-drop.
(function ($) {
  $('*').on('chosen:ready', function () {
    $('.countryid, .usa, select.chosen-ident').on('chosen:showing_dropdown', function (event, params) {
      // The setTimeout is here because it needs to follow AFTER the input has been displayed
      //setTimeout(function () {
      // x is the offset of the div that contains the text field and dropdown.
      var m = $(params.chosen.container[0]).parents('.modal-content')[0] ? $($(params.chosen.container[0]).parent()) : $(params.chosen.container[0]);
      var x = m.offset();
      // w is the horizontal offset
      var w = x.left;
      // If we're inside a modal, then we want to subtract the modal offset's top.
      // If we're not, then just use the normal top param.
      x = $(params.chosen.container[0]).parents('.modal-content')[0]
        ? x.top - $($(params.chosen.container[0]).parents('.modal-content')[0]).offset().top
        : x.top;
      // Then we want to add the height of the container to x
      x = x + m.height();
      // params.chosen.container[0] is the parent <div> element to the dropdown
      $(params.chosen.container[0]).css('z-index', '3');
      w = $(params.chosen.container[0]).parents('.modal-content')[0]
        ? w - $($(params.chosen.container[0]).parents('.modal-content')[0]).offset().left
        : w;
      //y = $(params.chosen.container[0]).parents('.modal-content')[0] ? y : y + $(params.chosen.container[0]).height();
      $(params.chosen.dropdown).css({
        width: m.width(),
        top: x + 'px',
        left: w + 'px',
        display: ''
      });
      //}, 150);
    });
    $('.countryid, .usa, select.chosen-ident').on('chosen:hiding_dropdown', function (event, params) {
      $(params.chosen.container[0]).css('z-index', '');
      $(params.chosen.dropdown).css('left', '');
      //$(params.chosen.container).removeClass('opened');
    });
    $('.countryid').change(function () {
      setTimeout(function () {
        $('.usa').trigger('chosen:updated');
      }, 10);
    });
    $('.chosen-drop-multi').on('change', function () {
      $('.chosen-choices').scrollTop($('.chosen-choices').height());
    });
    // EFC-1175 - I set the display to 'none' as a default, and only if an area that isn't the scrollbar is clicked will the display be overridden.
    //$('.chosen-container').click(function (e) {
    //    if (e.offsetX < ($(e.target).width() - 1)) {
    //        $('.chosen-container').addClass('opened');
    //        $('.chosen-drop').css('display', 'none');
    //        if ($('.chosen-container-multi').length > 0)
    //            $('li.search-field input[type=text]')[0].focus();
    //    }
    //})
  });
})(jQuery);

// DJM Addition on 7.21.17
// Added the below code to override the bootstrap-select insantiation of selectpicker's setSize attribute
// There's was an issue wherein a select box inside a modal would still calculate "bottom" as the value given from bottom of screen
// In addition I've changed the line at ~114 where the .selectpicker attribute is interpolated into a directive recognized by bootstrap
(function ($) {
  var oldsetSize = $.fn.selectpicker.Constructor.prototype.setSize;

  $.fn.selectpicker.Constructor.prototype.setSize = function () {
    this.findLis();
    this.liHeight();

    if (this.options.header) this.$menu.css('padding-top', 0);
    if (this.options.size === false) return;

    var that = this,
      $menu = this.$menu,
      $menuInner = this.$menuInner,
      $window = $(window),
      selectHeight = this.$newElement[0].offsetHeight,
      selectWidth = this.$newElement[0].offsetWidth,
      liHeight = this.sizeInfo['liHeight'],
      headerHeight = this.sizeInfo['headerHeight'],
      searchHeight = this.sizeInfo['searchHeight'],
      actionsHeight = this.sizeInfo['actionsHeight'],
      doneButtonHeight = this.sizeInfo['doneButtonHeight'],
      divHeight = this.sizeInfo['dividerHeight'],
      menuPadding = this.sizeInfo['menuPadding'],
      menuExtras = this.sizeInfo['menuExtras'],
      notDisabled = this.options.hideDisabled ? '.disabled' : '',
      menuHeight,
      menuWidth,
      getHeight,
      getWidth,
      selectOffsetTop,
      selectOffsetBot,
      selectOffsetLeft,
      selectOffsetRight,
      getPos = function () {
        var pos = that.$newElement.offset(),
          $container = $(that.options.container),
          containerPos;

        if (that.options.container && !$container.is('body')) {
          containerPos = $container.offset();
          containerPos.top += parseInt($container.css('borderTopWidth'));
          containerPos.left += parseInt($container.css('borderLeftWidth'));
        } else {
          containerPos = { top: 0, left: 0 };
        }

        var winPad = that.options.windowPadding;
        selectOffsetTop = pos.top - containerPos.top - $window.scrollTop();
        // This right here is the change to the original method.
        selectOffsetBot =
          containerPos.top == 0
            ? $window.height() - selectOffsetTop - selectHeight - containerPos.top - winPad[2]
            : $container.height() - (selectOffsetTop + selectHeight);
        selectOffsetLeft = pos.left - containerPos.left - $window.scrollLeft();
        selectOffsetRight = $window.width() - selectOffsetLeft - selectWidth - containerPos.left - winPad[1];
        selectOffsetTop -= winPad[0];
        selectOffsetLeft -= winPad[3];
      };

    getPos();

    if (this.options.size === 'auto') {
      var getSize = function () {
        var minHeight,
          hasClass = function (className, include) {
            return function (element) {
              if (include) {
                return element.classList ? element.classList.contains(className) : $(element).hasClass(className);
              } else {
                return !(element.classList ? element.classList.contains(className) : $(element).hasClass(className));
              }
            };
          },
          lis = that.$menuInner[0].getElementsByTagName('li'),
          lisVisible = Array.prototype.filter ? Array.prototype.filter.call(lis, hasClass('hidden', false)) : that.$lis.not('.hidden'),
          optGroup = Array.prototype.filter
            ? Array.prototype.filter.call(lisVisible, hasClass('dropdown-header', true))
            : lisVisible.filter('.dropdown-header');

        getPos();
        menuHeight = selectOffsetBot - menuExtras.vert;
        menuWidth = selectOffsetRight - menuExtras.horiz;

        if (that.options.container) {
          if (!$menu.data('height')) $menu.data('height', $menu.height());
          getHeight = $menu.data('height');

          if (!$menu.data('width')) $menu.data('width', $menu.width());
          getWidth = $menu.data('width');
        } else {
          getHeight = $menu.height();
          getWidth = $menu.width();
        }

        if (that.options.dropupAuto) {
          that.$newElement.toggleClass('dropup', selectOffsetTop > selectOffsetBot && menuHeight - menuExtras.vert < getHeight);
        }

        if (that.$newElement.hasClass('dropup')) {
          menuHeight = selectOffsetTop - menuExtras.vert;
        }

        if (that.options.dropdownAlignRight === 'auto') {
          $menu.toggleClass('dropdown-menu-right', selectOffsetLeft > selectOffsetRight && menuWidth - menuExtras.horiz < getWidth - selectWidth);
        }

        if (lisVisible.length + optGroup.length > 3) {
          minHeight = liHeight * 3 + menuExtras.vert - 2;
        } else {
          minHeight = 0;
        }

        $menu.css({
          //'max-height': menuHeight + 'px',
          overflow: 'hidden'
          //'min-height': minHeight + headerHeight + searchHeight + actionsHeight + doneButtonHeight + 'px'
        });
        $menuInner.css({
          'max-height': menuHeight - headerHeight - searchHeight - actionsHeight - doneButtonHeight - menuPadding.vert + 'px',
          'overflow-y': 'auto',
          'min-height': Math.max(minHeight - menuPadding.vert, 0) + 'px'
        });
      };
      getSize();
      this.$searchbox.off('input.getSize propertychange.getSize').on('input.getSize propertychange.getSize', getSize);
      $window.off('resize.getSize scroll.getSize').on('resize.getSize scroll.getSize', getSize);
    } else if (this.options.size && this.options.size != 'auto' && this.$lis.not(notDisabled).length > this.options.size) {
      var optIndex = this.$lis.not('.divider').not(notDisabled).children().slice(0, this.options.size).last().parent().index(),
        divLength = this.$lis.slice(0, optIndex + 1).filter('.divider').length;
      menuHeight = liHeight * this.options.size + divLength * divHeight + menuPadding.vert;

      if (that.options.container) {
        if (!$menu.data('height')) $menu.data('height', $menu.height());
        getHeight = $menu.data('height');
      } else {
        getHeight = $menu.height();
      }

      if (that.options.dropupAuto) {
        //noinspection JSUnusedAssignment
        this.$newElement.toggleClass('dropup', selectOffsetTop > selectOffsetBot && menuHeight - menuExtras.vert < getHeight);
      }
      $menu.css({
        'max-height': menuHeight + headerHeight + searchHeight + actionsHeight + doneButtonHeight + 'px',
        overflow: 'hidden',
        'min-height': ''
      });
      $menuInner.css({
        'max-height': menuHeight - menuPadding.vert + 'px',
        'overflow-y': 'auto',
        'min-height': ''
      });
    }
  };
})(jQuery);

// DJM 2.20.19 again
// EFC-1086 - the users want multi-selects to close whenever one option is selected
// ...For some reason.
(function ($) {
  $('*').on('loaded.bs.select', function (a) {
    $(a.target).on('changed.bs.select', function (v, clickedIndex, isSelected, previousValue) {
      setTimeout(function () {
        // DJM 3.15.19, EFC-1086
        // Added the below specifically because the State dropdown was triggering this on .change()
        // It was causing the field to remain open after the first click in any situation when
        // the state dropdown would become disabled.
        if (v.currentTarget.className.indexOf('dynamic-disabled') != -1 || v.currentTarget.attributes.getNamedItem('multiple') == null) return;
        $(v.target).selectpicker('toggle');
      }, 10);
    });
    // DJM 4.01.19
    // EFC-1155: Modal dropdown positioning is messed up for bootstrap selects
    setTimeout(function () {
      setClickFields();
    }, 100);
    setTimeout(function () {
      setClickFields();
    }, 750);
  });
})(jQuery);

// DJM addition 4.17 for EFC-1333
(function ($) {
  $.fn.hasScrollBar = function () {
    return this.get(0).scrollHeight > this.height();
  };
})(jQuery);

//These functions are used "dynamically", and must be on "window"
Object.assign(window, { setCoords });
Object.assign(window, { removeDropdown });
Object.assign(window, { setClickFields });
