import { parsePhoneNumberFromString, AsYouType } from 'libphonenumber-js';
import { Common } from './Common.js';
import DialogMgr from './DialogMgr.js';

var ContactEditVM = function () {
  var self = this;
  if (RazorShared.isDebug) console.log('ContactEditVM locator');

  self.$container = $(document);
  self.$focusOnReturn = null;
  self.okCallback = function () {};
  //self.AvailCompaniesCountryCode = [];
  self.defaultCountryISOCode = ko.observable('US');

  self.isPhoneValid = ko.observable(true);
  self.Phone = ko.observable('');
  self.FormattedPhone = ko.observable('');
  self.NonUSPhone = ko.observable(false);

  self.init = function ($dlgcontainer, params) {
    if ($dlgcontainer) self.$container = $dlgcontainer;
    if (params.okCallback) self.okCallback = params.okCallback;

    var $title = self.$container.find('.modal-title');
    if (params.title) $title.text(params.title);

    var $idFld = self.$container.find('#OnsiteContactID');
    var $ActiveFld = self.$container.find('#Active');

    if ($idFld.val() == 0) {
      $ActiveFld.val(true);
      $ActiveFld.parent().toggle(false);
    }

    if (ContactEditVM.jsonData) {
      self.NonUSPhone(ContactEditVM.jsonData.NonUSPhone);
    }
    self.setNonUSPhoneChangeTrigger($('#NonUSPhone'));

    ko.applyBindings(self, self.$container[0]);

    $('#Phone').keyup(function () {
      var val_old = $(this).val();
      //var countryCode = $("#companyCountryCode").val() || self.defaultCountryISOCode();
      //if (countryCode != self.defaultCountryISOCode() && !val_old.startsWith("+")) {
      if (self.NonUSPhone() && !val_old.startsWith('+')) {
        val_old = '+' + val_old;
      }
      var newString = new AsYouType(self.defaultCountryISOCode()).input(val_old);
      $(this).focus().val('').val(newString);
    });
  };

  self.setNonUSPhoneChangeTrigger = function ($fld) {
    self.NonUSPhone.subscribe(function (newValue) {
      self.NonUSPhone(newValue);
      self.UpdatePhoneFormat(newValue);
    });
  };

  self.SaveContact = function () {
    var $form = self.$container.find('#ContactForm');

    //var countryCode = $("#companyCountryCode").val();

    if (!self.NonUSPhone()) {
      var parsePhone = parsePhoneNumberFromString($('#Phone').val(), self.defaultCountryISOCode());
      self.isPhoneValid(parsePhone.isValid()); //libphonenumber.isValidNumber(parsePhone);
    } else self.isPhoneValid(true);
    //var parsePhone = libphonenumber.parsePhoneNumberFromString($("#Phone").val(), countryCode);
    //var isValid = libphonenumber.isValidNumber($("#Phone").val(), countryCode);
    //var isValid2 = parsePhone.isValid(); //libphonenumber.isValidNumber(parsePhone);

    if (!$form.validate().form() || (!self.NonUSPhone() && !self.isPhoneValid())) return;

    $.post(RazorShared.baseUrl + 'OnsiteContacts/SaveContact', $form.serialize(), function (data) {
      if (!data.success) {
        Common.Dialog('Error saving contact', null, data.error.split('|').join('<br>'));
      } else {
        self.$container.modal('hide');
        self.okCallback(data.Contact);
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Save Contact', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.CompanySearch = function (data, event) {
    self.$focusOnReturn = self.$container.find('#ContactForm #CompanySearch');
    DialogMgr.CompanySearch(self.CompanySelected);
  };

  self.CompanySelected = function (companyID, newCompanyName) {
    if (companyID > 0) {
      var $fld = self.$container.find('#ContactForm #CompanyID');
      if ($fld.find('option[value="' + companyID + '"]').length == 0) {
        var newOpt = document.createElement('option');
        $(newOpt).attr('value', companyID);
        $(newOpt).text(newCompanyName ? newCompanyName : '(New Company)');
        $fld.append(newOpt);
      }

      $fld.val(companyID);

      if ($fld.hasClass('chosen-ident')) $fld.trigger('chosen:updated'); //$fld.selectpicker('render');

      self.$focusOnReturn = $fld;
    }
  };

  self.returnFocus = function () {
    if (self.$focusOnReturn) self.$focusOnReturn.focus();
    else self.$container.find('.modal-content').focus();
    self.$focusOnReturn = null;
  };

  self.UpdatePhoneFormat = function (nonUSPhone) {
    //var countryCode = $("#companyCountryCode").val() || self.defaultCountryISOCode();

    var val_old = $('#Phone').val();
    var startsWithPlus = val_old.startsWith('+');
    if (nonUSPhone) {
      if (!startsWithPlus) val_old = '+' + val_old;
      //var countryCallingCode = libphonenumber.getCountryCallingCode('GB')
      let newString = new AsYouType().input(val_old);
      $('#Phone').focus().val('').val(newString);
    } else {
      if (startsWithPlus) val_old = val_old.substring(1);
      //self.phoneFormatter = new AsYouType(self.selectedCountryISOCode());
      //var newString = self.phoneFormatter.input(val_old);
      let newString = new AsYouType(self.defaultCountryISOCode()).input(val_old);
      $('#Phone').focus().val('').val(newString);
    }
  };
};

export default ContactEditVM;
