// Common.js
import moment from 'moment/min/moment-with-locales';

ko.bindingHandlers.chosen = {
  init: function (element, valueAccessor, allBindingsAccessor) {
    //$(element).chosen({"disable_search": true});
    $(element).chosen();
  },
  update: function (element, valueAccessor, allBindingsAccessor) {}
};

ko.bindingHandlers.selectpicker = {
  after: ['options'],
  /* KO 3.0 feature to ensure binding execution order */
  init: function (element, valueAccessor, allBindingsAccessor) {
    var $element = $(element);
    $element.addClass('selectpicker').selectpicker();

    var doRefresh = function () {
        $element.selectpicker('refresh');
      },
      subscriptions = [];

    // KO 3 requires subscriptions instead of relying on this binding's update
    // function firing when any other binding on the element is updated.

    // Add them to a subscription array so we can remove them when KO
    // tears down the element.  Otherwise you will have a resource leak.
    var addSubscription = function (bindingKey) {
      var targetObs = allBindingsAccessor.get(bindingKey);

      if (targetObs && ko.isObservable(targetObs)) {
        subscriptions.push(targetObs.subscribe(doRefresh));
      }
    };

    addSubscription('options');
    addSubscription('value'); // Single
    addSubscription('selectedOptions'); // Multiple

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      while (subscriptions.length) {
        subscriptions.pop().dispose();
      }
    });
  },
  update: function (element, valueAccessor, allBindingsAccessor) {}
};

ko.bindingHandlers.datetext = {
  init: function (element, valueAccessor, allBindingsAccessor) {
    // Provide a custom text value
    var value = valueAccessor(),
      allBindings = allBindingsAccessor();
    var dateFormat = allBindingsAccessor.dateFormat || 'M/D/YYYY';
    var strDate = window.ko.utils.unwrapObservable(value);
    if (strDate) {
      if (moment(strDate).year() > 1970) {
        var date = moment(strDate).format(dateFormat);
        $(element).text(date);
      } else {
        $(element).text('-');
      }
    }
  },
  update: function (element, valueAccessor, allBindingsAccessor) {
    // Provide a custom text value
    var value = valueAccessor(),
      allBindings = allBindingsAccessor();
    var dateFormat = allBindingsAccessor.dateFormat || 'M/D/YYYY';
    var strDate = window.ko.utils.unwrapObservable(value);
    if (strDate) {
      if (moment(strDate).year() > 1970) {
        var date = moment(strDate).format(dateFormat);
        $(element).text(date);
      } else {
        $(element).text('-');
      }
    }
  }
};

ko.bindingHandlers.datetimetext = {
  init: function (element, valueAccessor, allBindingsAccessor) {
    // Provide a custom text value
    var value = valueAccessor(),
      allBindings = allBindingsAccessor();
    var dateFormat = allBindingsAccessor.dateFormat || 'M/D/YYYY h:mm a';
    var strDate = window.ko.utils.unwrapObservable(value);
    if (strDate) {
      if (moment(strDate).year() > 1970) {
        var date = moment(strDate).format(dateFormat);
        $(element).text(date);
      } else {
        $(element).text('-');
      }
    }
  },
  update: function (element, valueAccessor, allBindingsAccessor) {
    // Provide a custom text value
    var value = valueAccessor(),
      allBindings = allBindingsAccessor();
    var dateFormat = allBindingsAccessor.dateFormat || 'M/D/YYYY h:mm a';
    var strDate = window.ko.utils.unwrapObservable(value);
    if (strDate) {
      if (moment(strDate).year() > 1970) {
        var date = moment(strDate).format(dateFormat);
        $(element).text(date);
      } else {
        $(element).text('-');
      }
    }
  }
};

ko.bindingHandlers.datetimepicker = {
  init: function (element, valueAccessor, allBindingsAccessor) {
    var options = allBindingsAccessor().dateTimePickerOptions || {};

    $(element).parent().datetimepicker(options);

    window.ko.utils.registerEventHandler($(element).parent(), 'dp.change', function (event) {
      var value = valueAccessor();
      if (window.ko.isObservable(value)) {
        var thedate = $(element).parent().data('DateTimePicker').date();
        if (thedate) value(moment(thedate).format('YYYY-MM-DDTHH:mm:ss.SSS'));
        else value(null);
      }
    });
  },
  update: function (element, valueAccessor) {
    var widget = $(element).parent().data('DateTimePicker');
    //when the view model is updated, update the widget
    var thedate = ko.utils.unwrapObservable(valueAccessor());
    var momentdate = moment(thedate);
    // widget.date(moment(thedate)); // didn't clear for null date
    if (momentdate._isValid) widget.date(momentdate);
    else widget.date(null);
  }
};

ko.bindingHandlers.timeonlypicker = {
  init: function (element, valueAccessor, allBindingsAccessor) {
    var options = allBindingsAccessor().dateTimePickerOptions || {};

    $(element).parent().datetimepicker(options);

    window.ko.utils.registerEventHandler($(element).parent(), 'dp.change', function (event) {
      var value = valueAccessor();
      if (window.ko.isObservable(value)) {
        var thedate = $(element).parent().data('DateTimePicker').date();
        value(moment(thedate).format('00:mm:ss'));
      }
    });
  },
  update: function (element, valueAccessor) {
    var widget = $(element).parent().data('DateTimePicker');
    //when the view model is updated, update the widget
    var clock = ko.utils.unwrapObservable(valueAccessor());
    widget.date(moment().startOf('day').add(moment.duration(clock)));
  }
};

ko.bindingHandlers.tokenField = {
  update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
    var source = valueAccessor().source;
    var limit = valueAccessor().limit;

    if (typeof limit == 'undefined') limit = 0;

    $(element).tokenfield('destroy');
    $(element).tokenfield({
      autocomplete: {
        source: source,
        delay: 100
      },
      limit: limit,
      showAutocompleteOnFocus: true
    });
  }
};

ko.bindingHandlers.fileUpload = {
  init: function (element, valueAccessor) {
    $(element).change(function () {
      valueAccessor()(element.files[0]);
    });
  },
  update: function (element, valueAccessor) {
    if (ko.unwrap(valueAccessor()) === null) {
      $(element).wrap('<form>').closest('form').get(0).reset();
      $(element).unwrap();
    }
  }
};

ko.bindingHandlers.colorPicker = {
  init: function (element, valueAccessor, allBindingsAccessor) {
    var options = allBindingsAccessor().colorPickerOptions || {};

    var value = ko.utils.unwrapObservable(valueAccessor());
    $(element).val(value);

    $(element).parent().colorpicker(options);

    ko.utils.registerEventHandler($(element).parent(), 'change', function () {
      if ($(element).val() != '#aN') {
        var observable = valueAccessor();
        observable($(element).val());
      }
    });
  },
  update: function (element, valueAccessor, allBindingAccessor) {
    var value = ko.utils.unwrapObservable(valueAccessor());
    $(element).val(value);
    $(element).change();
  }
};

if (!Array.prototype.find) {
  Array.prototype.find = function (predicate) {
    'use strict';
    if (this == null) {
      throw new TypeError('Array.prototype.find called on null or undefined');
    }
    if (typeof predicate !== 'function') {
      throw new TypeError('predicate must be a function');
    }
    var list = Object(this);
    var length = list.length >>> 0;
    var thisArg = arguments[1];
    var value;

    for (let i = 0; i < length; i++) {
      value = list[i];
      if (predicate.call(thisArg, value, i, list)) {
        return value;
      }
    }
    return undefined;
  };
}

// https://tc39.github.io/ecma262/#sec-array.prototype.findIndex
if (!Array.prototype.findIndex) {
  Object.defineProperty(Array.prototype, 'findIndex', {
    value: function (predicate) {
      // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      var o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0;

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== 'function') {
        throw new TypeError('predicate must be a function');
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      var thisArg = arguments[1];

      // 5. Let k be 0.
      var k = 0;

      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return k.
        var kValue = o[k];
        if (predicate.call(thisArg, kValue, k, o)) {
          return k;
        }
        // e. Increase k by 1.
        k++;
      }

      // 7. Return -1.
      return -1;
    }
  });
}

if (!Array.prototype.filter) {
  Array.prototype.filter = function (fun /*, thisArg*/) {
    'use strict';

    if (this === void 0 || this === null) {
      throw new TypeError();
    }

    var t = Object(this);
    var len = t.length >>> 0;
    if (typeof fun !== 'function') {
      throw new TypeError();
    }

    var res = [];
    var thisArg = arguments.length >= 2 ? arguments[1] : void 0;
    for (let i = 0; i < len; i++) {
      if (i in t) {
        var val = t[i];

        // NOTE: Technically this should Object.defineProperty at
        //       the next index, as push can be affected by
        //       properties on Object.prototype and Array.prototype.
        //       But that method's new, and collisions should be
        //       rare, so use the more-compatible alternative.
        if (fun.call(thisArg, val, i, t)) {
          res.push(val);
        }
      }
    }

    return res;
  };
}

// Production steps of ECMA-262, Edition 5, 15.4.4.18
// Reference: http://es5.github.io/#x15.4.4.18
if (!Array.prototype.forEach) {
  Array.prototype.forEach = function (callback, thisArg) {
    var T, k;

    if (this === null) {
      throw new TypeError(' this is null or not defined');
    }

    var O = Object(this);
    var len = O.length >>> 0;
    if (typeof callback !== 'function') {
      throw new TypeError(callback + ' is not a function');
    }

    if (arguments.length > 1) {
      T = thisArg;
    }

    k = 0;

    while (k < len) {
      var kValue;

      if (k in O) {
        kValue = O[k];

        callback.call(T, kValue, k, O);
      }
      k++;
    }
  };
}

$.validator.addMethod(
  'notEqualTo',
  function (value, element, param) {
    return this.optional(element) || !$.validator.methods.equalTo.call(this, value, element, param);
  },
  'Please enter a different value, values must not be the same.'
);

$.validator.unobtrusive.getOtherFields = function (options) {
  var prefix = options.element.name.substr(0, options.element.name.lastIndexOf('.') + 1);
  var other = options.params.other;

  if (other.indexOf('*.') === 0) {
    other = other.replace('*.', prefix);
  }
  // escape characters for jQuery interpretation
  // eslint-disable-next-line no-useless-escape
  other = other.replace(/([!"#$%&'()*+,./:;<=>?@\[\\\]^`{|}~])/g, '\\$1');

  return $(options.form)
    .find(':input')
    .filter("[name='" + other + "']");
};

$.validator.unobtrusive.adapters.add('notequalto', ['other'], function (options) {
  options.rules['notEqualTo'] = $.validator.unobtrusive.getOtherFields(options)[0];
  options.messages['notEqualTo'] = options.message;
});

$.validator.unobtrusive.adapters.add('requiredif', ['other', 'otherval'], function (options) {
  var $otherFields = $.validator.unobtrusive.getOtherFields(options);
  var otherVal = options.params.otherval;
  options.rules['required'] = function (element) {
    var checkValues = [];
    if ($otherFields.length > 1) {
      $otherFields.filter(':checked').each(function (idx, f) {
        checkValues.push(f.value);
      });
    } else {
      checkValues = $otherFields.val(); // will already be array if multi-select list
      if (!Array.isArray(checkValues)) checkValues = [checkValues];
    }
    return (
      checkValues.find(function (v) {
        if (v == null || v == undefined) return false;
        else return v.toLowerCase() == otherVal.toLowerCase();
      }) !== undefined
    );
  };
  options.messages['required'] = options.message;
});

$.validator.unobtrusive.adapters.addSingleVal('step', 'step');

var consoleEnabled = window.console && window.console.log;

var Common = {
  ParseJSONdate: function (d) {
    //The JSON spec does not account for Date values.
    //MS had to make a call, and the path they chose was to exploit a little trick in the javascript representation of strings: the string literal "/" is the same as "\/",
    //and a string literal will never get serialized to "\/" (even "\/" must be mapped to "\\/").
    return parseInt(d.replace('/Date(', '').replace(')/', ''), 10);
  },
  FormattedDate: function (d, includeTime) {
    if (typeof d == 'undefined' || d == null) d = new Date();
    if (typeof includeTime == 'undefined' || includeTime == null) includeTime = false;

    var month = d.getMonth() + 1;
    var day = d.getDate();
    var ret = d.getFullYear() + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;

    if (includeTime)
      ret += ' ' + d.getHours() + ':' + (d.getMinutes() < 10 ? '0' : '') + d.getMinutes() + ':' + (d.getSeconds() < 10 ? '0' : '') + d.getSeconds();

    return ret;
  },
  BlockUI: function (msg) {
    var spinnerUrl = RazorShared.baseUrl + 'assets/img/spinner.svg';

    $.blockUI({
      message: msg ? "<div id='divBlockUI' class='blockUI'><h3>" + msg + '</h3></div>' : "<img src='" + spinnerUrl + "' />",
      centerY: false,
      bindEvents: true,
      constrainTabKey: true,
      focusInput: true,
      css: {
        top: '300px',
        border: 'none',
        color: 'rgba(0,0,0,0)',
        opacity: '100',
        backgroundColor: 'rgba(0,0,0,0)'
      },
      overlayCSS: {},
      baseZ: 2000
    });

    $(':focus').blur();
  },
  StringSortFunc: function (propName) {
    return function (a, b) {
      return a[propName].localeCompare(b[propName]);
    };
  },
  NumberAscSortFunc: function (propName) {
    return function (a, b) {
      return a[propName] - b[propName];
    };
  },
  NumberDescSortFunc: function (propName) {
    return function (a, b) {
      return b[propName] - a[propName];
    };
  },
  DateSortAsc: function (propName) {
    return function (a, b) {
      return moment(a[propName]).format('YYYYMMDD') - moment(b[propName]).format('YYYYMMDD');
    };
  },
  DateSortDesc: function (propName) {
    return function (a, b) {
      return moment(b[propName]).format('YYYYMMDD') - moment(a[propName]).format('YYYYMMDD');
    };
  },
  ShowSelectedItemsOnTop: function (myElemSelector) {
    $(myElemSelector + ' option:selected').prependTo(myElemSelector);
    $(myElemSelector).selectpicker('refresh');
  },
  ConfigDialog: function ($modal) {
    var dialog = $modal.find('.modal-content');

    if (dialog.length > 0) {
      //allow dialog to be draggable.
      dialog.draggable({
        handle: '.modal-header',
        containment: 'window',
        scroll: false
      });

      //allow dialog to receive keys
      dialog.attr('tabindex', '-1');
      dialog.css('outline', 'none');

      //now find standard buttons...
      var cancelButton = $modal.find('.sk-cancel');
      var okButton = $modal.find('.sk-ok');

      dialog.on('keyup', function (event) {
        //if esc is pressed, push close button if exists
        if (event.keyCode == 27 && cancelButton.length > 0) {
          // esc
          $('.colorpicker-component').colorpicker('destroy');
          cancelButton.click();
        }

        if (event.keyCode == 13 && okButton.length > 0) {
          // enter -> OK
          if ($(event.target).prop('type') != 'button' && $(event.target).prop('type') != 'textarea')
            // ... but only if not on a button, since that would have already triggered click
            okButton.click();
        }
      });
    }
  },
  LogConsole: function (msg) {
    if (consoleEnabled) {
      var d = new Date();
      console.log(('0' + d.getHours()).slice(-2) + ':' + ('0' + d.getMinutes()).slice(-2) + ':' + ('0' + d.getSeconds()).slice(-2) + ' ' + msg);
    }
  },
  Dialog: function (title, bodyText, bodyHtml, errorStatusCode) {
    if (errorStatusCode && errorStatusCode == 403) JSPost(RazorShared.baseUrl + 'Login/AccountDisabled');
    else {
      var modalTitle = $('#myModal').find('.modal-title');
      var modalBody = $('#myModal').find('.modal-body-p');

      modalTitle.text(title);
      if (bodyText) modalBody.text(bodyText);
      if (bodyHtml) modalBody.html(bodyHtml);

      $('#myModal .modal-footer #confirm-ok').hide();
      $('#myModal .modal-footer #confirm-cancel').hide();
      $('#myModal .modal-footer #confirm-close').show();

      $('#myModal').modal({
        backdrop: false,
        keyboard: true,
        show: true
      });

      $('#myModal').css('z-index', 5000);

      $('#myModal .modal-dialog').draggable({
        handle: '.modal-header'
      });

      setTimeout(function () {
        $('#myModal').find('button.btn-default').focus();
      }, 500);
    }
  },
  DialogWithCallback: function (title, bodyText, bodyHtml, callbackClose) {
    var modalTitle = $('#myModal').find('.modal-title');
    var modalBody = $('#myModal').find('.modal-body-p');

    modalTitle.text(title);
    if (bodyText) modalBody.text(bodyText);
    if (bodyHtml) modalBody.html(bodyHtml);

    $('#myModal .modal-footer #confirm-ok').hide();
    $('#myModal .modal-footer #confirm-cancel').hide();
    $('#myModal .modal-footer #confirm-close').show();

    var buttonClicked = 0;
    $('#myModal .modal-footer #confirm-close').click(function () {
      buttonClicked = 3;
    });

    $('#myModal').one('hidden.bs.modal', function (e) {
      $('#myModal .modal-footer #confirm-close').unbind('click');

      if (buttonClicked == 3 && typeof callbackClose == 'function') callbackClose();
    });

    $('#myModal').modal({
      backdrop: false,
      keyboard: true,
      show: true
    });

    $('#myModal').css('z-index', 5000);

    $('#myModal .modal-dialog').draggable({
      handle: '.modal-header'
    });

    setTimeout(function () {
      $('#myModal').find('button.btn-default').focus();
    }, 500);
  },
  DialogConfirmation: function (title, bodyText, bodyHtml, callbackOK, callbackCancel) {
    var modalTitle = $('#myModal').find('.modal-title');
    var modalBody = $('#myModal').find('.modal-body-p');

    modalTitle.text(title);
    if (bodyText) modalBody.text(bodyText);
    if (bodyHtml) modalBody.html(bodyHtml);

    $('#myModal .modal-footer #confirm-close').hide();
    $('#myModal .modal-footer #confirm-cancel').show();
    $('#myModal .modal-footer #confirm-ok').show();

    var buttonClicked = 0;
    $('#myModal .modal-footer #confirm-ok').click(function () {
      buttonClicked = 1;
    });
    $('#myModal .modal-footer #confirm-cancel').click(function () {
      buttonClicked = 2;
    });

    $('#myModal').one('hidden.bs.modal', function (e) {
      $('#myModal .modal-footer #confirm-ok').unbind('click');
      $('#myModal .modal-footer #confirm-cancel').unbind('click');

      if (buttonClicked == 1 && typeof callbackOK == 'function') callbackOK();
      if (buttonClicked == 2 && typeof callbackCancel == 'function') callbackCancel();
    });

    $('#myModal').modal({
      backdrop: false,
      keyboard: true,
      show: true
    });

    $('#myModal').css('z-index', 5000);

    $('#myModal .modal-dialog').draggable({
      handle: '.modal-header'
    });

    setTimeout(function () {
      $('#myModal').find('button.btn-default').focus();
    }, 500);
  }
};

var JSPost = function (path, parameters) {
  var self = window;

  self.buildItem = function (form, key, value) {
    if (Array.isArray(value)) {
      $.each(value, function (subkey, subvalue) {
        var field = $('<input />');
        field.attr('type', 'hidden');
        field.attr('name', key + '[]');
        field.attr('value', subvalue);
        form.append(field);
      });
    } else if (typeof value == 'object') {
      $.each(value, function (subkey, subvalue) {
        self.buildItem(form, key + '.' + subkey, subvalue);
      });
    } else {
      var field = $('<input />');
      field.attr('type', 'hidden');
      field.attr('name', key);
      field.attr('value', value);
      form.append(field);
    }
  };

  var form = $('<form></form>');

  form.attr('method', 'post');
  form.attr('action', path);

  $.each(parameters, function (key, value) {
    self.buildItem(form, key, value);
  });

  $(document.body).append(form);
  form.submit();
};

var DialogManager = function () {
  var self = this;

  self.ModalStack = [];

  self.VM = function (elem) {
    return $(elem).closest('.modal').data('vm');
  };

  self.LoadModal = function (url, loadData, vmType, initparams) {
    Common.BlockUI();
    let vm = new vmType();
    BeginModalSetup(url, loadData, vm, initparams);
  };

  self.LoadModalWithoutVM = function (url, loadData) {
    Common.BlockUI();
    BeginModalSetup(url, loadData, null, null);
  };

  function BeginModalSetup(url, loadData, vm, initparams) {
    var idx = self.ModalStack.length;

    var $sect = $(document.createElement('section'));
    $sect.attr('id', '_dlg' + idx);
    $sect.attr('modalnum', idx);
    $('body').append($sect);

    $sect.load(url, loadData, function (response, status, xhr) {
      if (status == 'error') {
        $.unblockUI();
        if (xhr.status == 403) JSPost(RazorShared.baseUrl + 'Login/AccountDisabled');
        else Common.Dialog('Error', 'An error has occurred: ' + xhr.status + ' ' + xhr.statusText);
      } else {
        CompleteModalSetup(idx, $sect, vm, initparams);
      }
    });
  }

  function CompleteModalSetup(idx, $sect, vm, initparams) {
    self.ModalStack.push($sect);
    var $modal = $sect.find('.modal');
    $modal.css('z-index', 1040 + 10 * idx);

    $modal.on('shown.bs.modal', function (event) {
      $('.modal-backdrop')
        .not('.stacked')
        .css('z-index', 1039 + 10 * idx);
      $('.modal-backdrop').not('.stacked').addClass('stacked');
    });

    $modal.on('hidden.bs.modal', function (event) {
      $(this).removeData('vm');
      var $sect = $(this).closest('section');
      self.ModalStack[$sect.attr('modalnum')] = null;
      $sect.remove();
      while (self.ModalStack.length > 0 && self.ModalStack[self.ModalStack.length - 1] == null) self.ModalStack.pop();

      if (self.ModalStack.length > 0) {
        var $last = self.ModalStack[self.ModalStack.length - 1].find('.modal');
        var lastVM = $last.data('vm');
        if (lastVM && typeof lastVM.returnFocus == 'function') lastVM.returnFocus();
        else $last.find('.modal-content').focus();
      }
    });

    if (vm) {
      vm.init($modal, initparams);
      $modal.data('vm', vm);
    }

    // MODAL HAS OFFIICALY FINISHED LOADING
    $modal.on('shown.bs.modal', function () {
      // WHEN THE FREQUENCY NOTES LOADS. THIS FUNCTION CHECKS THE HEIGHT AND ADDS A CLASS TO THE BODY IF THE LIST IS TALLER THAN THE CONTAINER.
      // IF THIS IS THE CASE THEN CSS STYLES THE DIV TO ACCOUNT FOR THE ADDITIONAL PADDING FOR WINDOWS BROWSERS.
      var bodyContentHeight = 0;
      $('.frequencyList .body ul').each(function () {
        bodyContentHeight = bodyContentHeight + $(this).outerHeight(true);
      });
      if (bodyContentHeight > $('.frequencyList .body').height()) {
        $('.frequencyList').addClass('headerScrollFix');
      }
    });

    //make the modal an actual modal...
    $modal.modal({
      backdrop: 'static',
      keyboard: false,
      show: true
    });

    Common.ConfigDialog($modal);

    $.unblockUI();
  }
};

/**
 * encode special HTML characters, so text is safe when building HTML dynamically
 * @param {String} text the text to encode
 * @return {String}
 */
var EncodeHTML = (function () {
  var self = this;
  var encodeHTMLmap = {
    '&': '&amp;',
    '"': '&quot;',
    '<': '&lt;',
    '>': '&gt;'
  };
  /**
   * encode character as HTML entity
   * @param {String} ch character to map to entity
   * @return {String}
   */
  function encodeHTMLmapper(ch) {
    return encodeHTMLmap[ch];
  }

  return function (text) {
    // search for HTML special characters, convert to HTML entities
    return text.replace(/[&"<>]/g, encodeHTMLmapper);
  };
})();

/**
 * decode special HTML characters, so text is safe when building HTML dynamically
 * @param {String} text the text to decode
 * @return {String}
 */
var DecodeHTML = (function () {
  var self = this;
  var decodeHTMLmap = {
    '&amp;': '&',
    '&quot;': '"',
    '&lt;': '<',
    '&gt;': '>'
  };
  /**
   * decode character as HTML entity
   * @param {String} ch character to map to entity
   * @return {String}
   */
  function decodeHTMLmapper(ch) {
    return decodeHTMLmap[ch];
  }

  return function (text) {
    // search for HTML special characters, convert to HTML entities
    if (text != undefined) return text.replace(/&amp;|&quot;|&lt;|&gt;/g, decodeHTMLmapper);
    else return '';
  };
})();

/**
 * Polyfill function for Math.sign that is not supported in IE (except for Edge).
 **/
if (!Math.sign) {
  Math.sign = function (x) {
    // If x is NaN, the result is NaN.
    // If x is -0, the result is -0.
    // If x is +0, the result is +0.
    // If x is negative and not -0, the result is -1.
    // If x is positive and not +0, the result is +1.
    x = +x; // convert to a number
    if (x === 0 || isNaN(x)) {
      return Number(x);
    }
    return x > 0 ? 1 : -1;
  };
}

// pass in either a decimal value or an observable for Lat or Lon
var DegMinSecVM = function (value) {
  var self = this;
  self.FullDecs = 7;
  self.SecsDecs = 2;

  if (ko.isObservable(value)) {
    self.Value = value; // hook to existing observable
    if (typeof self.Value() != 'number') self.Value(Number.parseFloat(self.Value()) || 0);
  } else {
    self.Value = ko.observable(Number.parseFloat(value) || 0);
  }

  self.DecimalValue = ko.pureComputed({
    read: function () {
      return self.Value().toFixed(self.FullDecs);
    },
    write: function (value) {
      var newVal = Number.parseFloat(value);
      if (Number.isNaN(newVal)) newVal = 0;
      if (self.Value() != newVal) self.Value(newVal);
    }
  });

  self.Degrees = ko.pureComputed({
    read: function () {
      var x = self.Value();
      if (x < 0 && x > -1) return '-0'; // special handling for "-0" (fractional degrees W or S)
      else if (x > 0) return Math.floor(x);
      else return Math.ceil(x);
    },
    write: function (value) {
      if (value === '-0') {
        // special handling for "-0" (fractional degrees W or S)
        if (self.Degrees() !== '-0') self.setValue(0, null, null, -1);
      } else {
        if (self.Degrees() != value || self.Degrees() === '-0') self.setValue(value, null, null, value == 0 ? 1 : Math.sign(value));
      }
    }
  });

  self.Minutes = ko.pureComputed({
    read: function () {
      var x = Math.floor(Math.abs(self.Value()) * 60);
      return x % 60;
    },
    write: function (value) {
      if (self.Minutes() != value) self.setValue(null, value, null);
    }
  });

  self.Seconds = ko.pureComputed({
    read: function () {
      var x = Math.abs(self.Value()) * 3600;
      return (x % 60).toFixed(self.SecsDecs);
    },
    write: function (value) {
      var newVal = Number.parseFloat(value);
      if (self.Seconds() != newVal.toFixed(self.SecsDecs)) self.setValue(null, null, newVal);
    }
  });

  self.setValue = function (deg, min, sec, sign) {
    if (!deg && deg !== 0) deg = self.Degrees();
    if (!min && min !== 0) min = self.Minutes();
    if (!sec && sec !== 0) sec = self.Seconds();

    if (!sign) {
      if (deg == 0) sign = Math.sign(self.Value()) || 1;
      else sign = Math.sign(deg);
    }

    var x = sign * (Math.abs(deg) + min / 60.0 + sec / 3600.0);
    self.Value(x);
  };
};

$(function () {
  $(document).ajaxSend(function (event, request, settings) {
    Common.LogConsole('Start [' + settings.type + '] [' + settings.url + ']');
    settings._startTime = new Date();
  });

  $(document).ajaxError(function (event, xhr, settings, error) {
    var msg = xhr.responseJSON && xhr.responseJSON.errorMessage ? xhr.responseJSON.errorMessage : error;
    Common.LogConsole('Ajax Error! [' + settings.type + '] [' + settings.url + '] [' + msg + ']');
  });

  $(document).ajaxComplete(function (event, xhr, settings) {
    var span = new Date() - settings._startTime;
    if (xhr.responseJSON && xhr.responseJSON.errorMessage) {
      Common.LogConsole(
        'Error [' + settings.type + '] [' + settings.url + '] : [' + xhr.statusText + '] [' + span + ']ms [' + xhr.responseJSON.errorMessage + ']'
      );
    } else {
      Common.LogConsole('Done  [' + settings.type + '] [' + settings.url + '] : [' + xhr.statusText + '] [' + span + ']ms');
    }
    if (!xhr.responseJSON) {
      $('ul.sidebar-nav').addClass('loaded');
    }
  });
});

export { Common, JSPost, DialogManager, EncodeHTML, DecodeHTML, DegMinSecVM };
