import { Common } from './Common.js';

var LocationAddEditVM = function () {
  var self = this;

  if (RazorShared.isDebug) console.log('LocationAddEditVM locator');

  self.$container = $(document);
  //self.$form = $('');
  self.okCallback = function () {};

  self.SelectedMergeInto = 0;
  self.MergeIDList = [];

  self.Location = null;
  self.LocationsInArea = ko.observableArray([]);
  self.LocationOrigItemStatus = null;
  self.SelectedCrossCheckLocations = ko.observableArray([]);
  self.DMSMode = ko.observable(true);

  self.LatitudeDMS = null;
  self.LongitudeDMS = null;
  self.AddEditText = '';

  self.selectedCrossCheckLocationIDs = ko.pureComputed(function () {
    var selectedCCLocations = '';
    if (self.SelectedCrossCheckLocations() != undefined && self.SelectedCrossCheckLocations().length > 0)
      selectedCCLocations = $.map(self.SelectedCrossCheckLocations(), function (obj) {
        return obj.ID;
      }).join('|');
    return selectedCCLocations;
  });

  self.reloadAvailCrossCheckLocations = function (areaID) {
    if (!areaID) {
      self.LocationsInArea.removeAll();
      return;
    }

    $.get(
      RazorShared.baseUrl + 'LocationMgmt/GetLocationsInAreaForCrossCheck',
      { areaID: areaID, locatonID: self.Location.LocationID },
      function (data) {
        if (data.success) {
          self.LocationsInArea(data.LocationsInArea);
        } else {
          Common.Dialog('Error finding events', null, data.error.split('|').join('<br>'));
        }
      }
    ).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Find Events', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.init = function ($dlgcontainer, params) {
    if ($dlgcontainer) {
      self.$container = $dlgcontainer;
      self.$form = self.$container.find('form');
    }

    if (params.okCallback) self.okCallback = params.okCallback;

    var $title = self.$container.find('.modal-title');
    if (params.title) $title.text(params.title);

    if (params.locationID) self.ShowAddEdit(params.locationID);
    else self.ShowAddEdit(0);
  };

  self.load = function (jsonData) {
    self.AddEditText = jsonData.Location && jsonData.Location.LocationID ? 'Edit Location: ' + jsonData.Location.Name : 'Add Location';

    self.Location = new LocationVM(jsonData.Location);
    self.LocationsInArea(jsonData.LocationsInArea);
    self.LocationOrigItemStatus = self.Location.ItemStatusID();

    //self.LatitudeDMS = new DegMinSecVM(self.Location.Latitude);
    //self.LongitudeDMS = new DegMinSecVM(self.Location.Longitude);

    if (
      jsonData.Location != undefined &&
      jsonData.Location.CrossCheckLocationIDs != undefined &&
      jsonData.Location.CrossCheckLocationIDs.length > 0
    ) {
      jsonData.Location.CrossCheckLocationIDs.split('|').forEach(function (id) {
        var loc = self.LocationsInArea().find(function (item) {
          return item.ID == id;
        });
        if (loc) self.SelectedCrossCheckLocations().push(loc);
      });
    }

    ko.applyBindings(self, self.$container[0]);

    self.Location.AreaID.subscribe(function (areaID) {
      self.reloadAvailCrossCheckLocations(areaID);
    });
    self.Location.ItemStatusID.subscribe(function (itemStatus) {
      if (itemStatus != 1) self.checkForFutureEvents();
    });
  };

  self.ToggleDMSMode = function () {
    self.DMSMode(!self.DMSMode());
  };

  self.ShowAddEdit = function (locationID) {
    self.$container
      .find('#sectAddEdit')
      .load(RazorShared.baseUrl + 'LocationMgmt/AddEditLocation/' + (locationID ? locationID : ''), function (response, status, xhr) {
        if (status == 'error') Common.Dialog('Search', 'An error has occurred: ' + xhr.status + ' ' + xhr.statusText, null, xhr.status);
        else self.load(LocationAddEditVM.jsonData);
      });
  };

  self.checkForFutureEvents = function () {
    console.log(self.Location.ItemStatusID());
    console.log(self.LocationOrigItemStatus);

    $.get(RazorShared.baseUrl + 'LocationMgmt/HasFutureEvents', { id: self.Location.LocationID }, function (data) {
      if (data.success && data.hasFutureEvents) {
        Common.Dialog('Location Status Change', 'Cannot change status because there are future events at this Venue.', null, null);
        self.Location.ItemStatusID(self.LocationOrigItemStatus);
      } else if (!data.success) {
        Common.Dialog('Error checking for future events', null, data.error.split('|').join('<br>'));
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Check for future events for this location', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.Save = function () {
    var $usa = $('.usa');
    var isDisabled = $usa.attr('disabled');
    $usa.attr('disabled', false);

    var $locationForm = self.$container.find('#LocationForm');

    var validator = $locationForm.data('validator');
    validator.settings.ignore = ':hidden:not(select.countryid)';

    if (!$locationForm.validate().form()) return;

    if (isDisabled) $usa.attr('disabled', true);

    $.post(RazorShared.baseUrl + 'LocationMgmt/SaveLocation', $locationForm.serialize(), function (data) {
      if (!data.success) {
        Common.Dialog('Error saving location', null, data.error.split('|').join('<br>'));
      } else {
        self.$container.modal('hide');
        self.okCallback(data.Model);
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Save Model', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };
};

var LocationVM = function (data) {
  var self = this;

  self.LocationID = data.LocationID;
  self.AreaID = ko.observable(data.AreaID);
  self.ItemStatusID = ko.observable(data.ItemStatusID);

  //self.Latitude = data.Latitude;
  //self.Longitude = data.Longitude;
};

export default LocationAddEditVM;
