import { RequestActivityVM } from './RequestViewModels.js';
import moment from 'moment/min/moment-with-locales';
import { Common } from './Common.js';
import DialogMgr from './DialogMgr.js';

var WalkInEquipmentAddEditVM = function () {
  if (RazorShared.isDebug) console.log('WalkInEquipmentAddEditVM locator');

  var self = this;
  self.$container = $(document);
  self.$form = $('');
  self.$focusOnReturn = null;
  self.okCallback = function () {};

  self.WalkInEquipment = null; //ko.observable(null);
  self.AvailEquipmentTypes = ko.observableArray([]);
  self.AvailManufacturers = ko.observableArray([]);
  self.AvailModels = ko.observableArray([]);
  self.AvailFrequencyBlocks = ko.observableArray([]);
  self.AvailRequiredUses = ko.observableArray([]);
  self.CustomFrequencyBlock = ko.observable(null);
  self.AvailActivities = ko.observableArray([]);

  self.ActivitiesEnabled = ko.pureComputed(function () {
    return self.WalkInEquipment && self.WalkInEquipment.EventID();
  });
  self.HasNoActivities = ko.pureComputed(function () {
    return !self.WalkInEquipment || self.WalkInEquipment.WalkInEquipmentActivities().length == 0;
  });
  self.AddSelActivity = ko.observable();
  self.UnselActivities = ko.pureComputed(function () {
    return self
      .AvailActivities()
      .filter(function (a) {
        return (
          self.WalkInEquipment &&
          (a.AllowPartTime ||
            !self.WalkInEquipment.WalkInEquipmentActivities().find(function (ra) {
              return ra.ActivityID == a.ActivityID;
            }))
        );
      })
      .sort(Common.StringSortFunc('Description'));
  });
  self.AddingActivity = ko.observable(false);

  self.FreqBlockBalloonText = ko.pureComputed(function () {
    return (self.CustomFrequencyBlock() ? 'Edit the' : 'Add a') + ' custom list of tunable frequencies for this equipment.';
  });

  self.FreqBlockButtonText = ko.pureComputed(function () {
    return (self.CustomFrequencyBlock() ? 'Edit' : 'Add') + ' Custom';
  });

  self.init = function ($dlgcontainer, params) {
    if ($dlgcontainer) {
      self.$container = $dlgcontainer;
      self.$form = self.$container.find('form');
    }

    if (params.okCallback) {
      self.okCallback = params.okCallback;
    }

    self.load(WalkInEquipmentAddEditVM.jsonData, params.defaultActivityID);
  };

  self.load = function (jsonData, defaultActivityID) {
    self.AvailEquipmentTypes(jsonData.AvailEquipmentTypes.sort(Common.StringSortFunc('Name')) || []);
    self.AvailRequiredUses(jsonData.AvailRequiredUses.sort(Common.StringSortFunc('Name')) || []);
    self.AvailManufacturers((jsonData.AvailManufacturers || []).sort(Common.StringSortFunc('Name')));
    self.AvailModels((jsonData.AvailModels || []).sort(Common.StringSortFunc('Name')));
    self.AvailActivities(jsonData.AvailActivities || []);
    self.CustomFrequencyBlock(jsonData.CustomFrequencyBlock || null);

    self.WalkInEquipment = new WalkInEquipmentVM(jsonData.WalkInEquipment);
    self.SetModelInfo(self.WalkInEquipment.ModelID());

    self.setupFrequencyTokenField($('#WalkInEquipmentFrequencies'));

    if (self.WalkInEquipment.WalkInEquipmentActivities().length == 0) {
      var act;
      if (self.AvailActivities().length == 1) act = self.AvailActivities()[0];
      else if (defaultActivityID)
        act = self.AvailActivities().find(function (a) {
          return a.ActivityID == defaultActivityID;
        });

      if (act) self.SelectActivity(act);
    }

    ko.applyBindings(self, self.$container[0]);

    self.WalkInEquipment.ManufacturerID.subscribe(function (manufID) {
      self.reloadAvailModels(manufID);
    });

    self.WalkInEquipment.ModelID.subscribe(function (modelID) {
      self.SetModelInfo(modelID);
    });

    self.AddSelActivity.subscribe(function (activity) {
      self.SelectActivity(activity);
    });
  };

  self.reloadAvailModels = function (manufID) {
    self.AvailModels.removeAll();
    self.WalkInEquipment.ModelID(null);

    if (!manufID) {
      return;
    }

    $.get(RazorShared.baseUrl + 'EquipmentModel/GetModelsForManufacturer', { manufID: manufID }, function (data) {
      if (data.success) {
        self.AvailModels(data.AvailModels);
      } else {
        Common.Dialog('Error finding models', null, data.error.split('|').join('</br>'));
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Find Models', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.SetModelInfo = function (modelID) {
    var model = self.findModel(modelID);
    if (model) {
      self.AvailFrequencyBlocks(model.FrequencyBlocks);

      if (model.CanShowDetail) {
        self.WalkInEquipment.Power(model.Power ? model.Power.toFixed(0) : '');
        self.WalkInEquipment.Bandwidth(model.Bandwidth ? model.Bandwidth.toFixed(6) : '');
      }
    } else {
      self.WalkInEquipment.FrequencyBlockID(null);
      self.AvailFrequencyBlocks([]);
    }

    if (
      self.CustomFrequencyBlock() &&
      !self.AvailFrequencyBlocks().find(function (x) {
        return x.FrequencyBlockID == self.CustomFrequencyBlock().FrequencyBlockID;
      })
    )
      self.AvailFrequencyBlocks.push(self.CustomFrequencyBlock());

    if (self.AvailFrequencyBlocks().length == 1) self.WalkInEquipment.FrequencyBlockID(self.AvailFrequencyBlocks()[0].ID);
  };

  self.AddNewManufacturer = function (data, event) {
    self.$focusOnReturn = $(event.target);
    DialogMgr.ManufacturerAddEdit(0, self.NewManufacturerAdded);
  };

  self.NewManufacturerAdded = function (newManuf) {
    if (self.$focusOnReturn) self.$focusOnReturn = $('#ModelID').parent().find('button.dropdown-toggle');
    self.SetNewManufacturer(newManuf.ManufacturerID, newManuf.Name);
  };

  self.SetNewManufacturer = function (id, name) {
    if (!self.findManufacturer(id)) self.AvailManufacturers.push({ ID: id, Name: name });
    self.WalkInEquipment.ManufacturerID(id);
  };

  self.findManufacturer = function (manufID) {
    if (!manufID) return null;
    return self.AvailManufacturers().find(function (x) {
      return x.ID == manufID;
    });
  };

  self.AddNewModel = function (data, event) {
    self.$focusOnReturn = $(event.target);
    DialogMgr.ModelAdd(self.NewModelAdded, self.WalkInEquipment.ManufacturerID());
  };

  self.NewModelAdded = function (newModel) {
    self.SetNewManufacturer(newModel.ManufacturerID, newModel.ManufacturerName);
    if (self.$focusOnReturn) self.$focusOnReturn = $('#WalkInEquipmentFrequencies');

    if (!self.findModel(newModel.ModelID)) self.AvailModels.push(newModel);
    self.WalkInEquipment.ModelID(newModel.ModelID);
  };
  self.findModel = function (modelID) {
    if (!modelID) return null;
    return self.AvailModels().find(function (x) {
      return x.ModelID == modelID;
    });
  };

  self.SelectActivity = function (activity) {
    if (activity) {
      self.WalkInEquipment.WalkInEquipmentActivities.push(new RequestActivityVM(null, activity));
      $('.event-info button').addClass('disabled');
    }
    self.AddSelActivity(null);
    self.AddingActivity(false);
  };

  self.RemoveWalkInEquipmentActivity = function (walkInEquipmentActivity) {
    self.WalkInEquipment.WalkInEquipmentActivities.remove(walkInEquipmentActivity);
    $('.event-info .disabled').removeClass('disabled');
  };

  self.ShowAddActivity = function () {
    if (self.WalkInEquipment.WalkInEquipmentActivities && self.WalkInEquipment.WalkInEquipmentActivities().length >= 30) {
      Common.Dialog('Adding Activities', 'Maximum of 30 activities are allowed per request.  To add more please create a new request.');
    } else self.AddingActivity(true);
  };

  self.Save = function () {
    if (!self.$form.validate().form()) return;

    $.post(RazorShared.baseUrl + 'WalkInEquipment/SaveWalkInEquipment', ko.toJS(self.WalkInEquipment), function (data) {
      if (!data.success) {
        Common.Dialog('Error Saving Walk-In Equipment', null, data.error.split('|').join('<br>'));
      } else {
        $('#WalkInEquipmentFrequencies').tokenfield('destroy');
        self.$container.modal('hide');
        self.okCallback(data.WalkInEquipment);

        //if (self.AddAnother() && data.Model.WalkInEquipmentID) {
        //    self.$container.on('hidden.bs.modal', function (e) {
        //        var title = "Add WalkIn Equipment";
        //        DialogMgr.WalkInEquipmentDuplicate(data.Model.WalkInEquipmentID, title, self.okCallback);
        //    })
        //}
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Save Static Frequency', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.setupFrequencyTokenField = function ($fld) {
    $fld.on('tokenfield:createtoken tokenfield:edittoken', function (event) {
      if (!$.isNumeric(event.attrs.value)) {
        event.preventDefault();
        return false;
      }

      event.attrs.label = parseFloat(event.attrs.value).toFixed(5);

      // check for duplicate
      if (
        $(this)
          .tokenfield('getTokens')
          .find(function (token) {
            return token.label == event.attrs.label;
          })
      ) {
        event.preventDefault();
        return false;
      }

      return true;
    });

    $fld.on('tokenfield:createdtoken', function (e) {
      self.WalkInEquipment.AddFrequency(e.attrs.value);
    });

    $fld.on('tokenfield:removedtoken', function (e) {
      self.WalkInEquipment.RemoveFrequency(e.attrs.value);
    });

    $fld.tokenfield({
      createTokensOnBlur: true,
      delimiter: [',', ' ']
    });

    $fld.tokenfield(
      'setTokens',
      self.WalkInEquipment.Frequencies().map(function (f) {
        return f.FrequencyDisplay();
      })
    );
  };

  self.returnFocus = function () {
    if (self.$focusOnReturn) self.$focusOnReturn.focus();
    else self.$container.find('.modal-content').focus();
    self.$focusOnReturn = null;
  };
};

var WalkInEquipmentVM = function (jsonData) {
  var self = this;

  self.WalkInEquipmentID = jsonData.WalkInEquipmentID || 0;
  self.EventID = ko.observable(jsonData.EventID || 0);
  self.EventName = jsonData.EventName || '';

  self.RequestorName = ko.observable(jsonData.RequestorName || '');
  self.RequestorCompany = ko.observable(jsonData.RequestorCompany || '');
  self.RequestorPhone = ko.observable(jsonData.RequestorPhone || '');
  self.RequestorEmail = ko.observable(jsonData.RequestorEmail || '');

  self.ModelID = ko.observable(jsonData.ModelID || 0);
  self.ManufacturerID = ko.observable(jsonData.ManufacturerID || 0);
  self.FrequencyBlockID = ko.observable(jsonData.FrequencyBlockID || null);
  self.Fixed = ko.observable(jsonData.Fixed || false);
  self.EquipmentTypeID = ko.observable(jsonData.EquipmentTypeID || 0);
  self.RequiredUseID = ko.observable(jsonData.RequiredUseID || 0);
  self.HowUsed = ko.observable(jsonData.HowUsed || '');
  self.SignalTypeID = ko.observable(jsonData.SignalTypeID || 0);
  self.Power = ko.observable(jsonData.Power || '');
  self.Bandwidth = ko.observable(jsonData.Bandwidth || '');
  self.ItemStatusID = ko.observable(jsonData.ItemStatusID || 0);
  self.Notes = ko.observable(jsonData.Notes || '');

  self.AddEditText = jsonData.WalkInEquipmentID ? 'Edit' : 'Add';

  self.Frequencies = ko.observableArray(
    (jsonData.Frequencies || []).map(function (f) {
      return new WalkInEquipmentFrequencyVM(f);
    })
  );

  self.WalkInEquipmentActivities = ko.observableArray(
    jsonData.WalkInEquipmentActivities.map(function (ra) {
      return new WalkInEquipmentActivityVM(ra);
    }) || []
  );

  self.AddFrequency = function (freq) {
    freq = Number(freq);
    if (isNaN(freq)) return;
    if (
      !self.Frequencies().find(function (f) {
        return f.Frequency() == freq;
      })
    )
      self.Frequencies.push(new WalkInEquipmentFrequencyVM(freq));
  };

  self.RemoveFrequency = function (freq) {
    freq = Number(freq);
    if (isNaN(freq)) return;
    var exist = self.Frequencies().find(function (f) {
      return f.Frequency() == freq;
    });
    if (exist) self.Frequencies.remove(exist);
  };
};

var WalkInEquipmentActivityVM = function (data) {
  var self = this;
  self.WalkInEquipmentActivityID = data.WalkInEquipmentActivityID;
  self.WalkInEquipmentID = data.WalkInEquipmentID;
  self.EquipmentID = data.EquipmentID;
  self.ActivityID = data.ActivityID;
  self.ActivityName = data.ActivityName;
  self.ActivityDescription = data.ActivityDescription;
  self.AllowPartTime = data.AllowPartTime;
  self.GameDrivenTime = data.GameDrivenTime;
  self.ActivityStartDateTime = data.ActivityStartDateTime;
  self.ActivityEndDateTime = data.ActivityEndDateTime;
  self.ActivitySingleDate = data.ActivitySingleDate;
  self.ActivityStartQtrID = data.ActivityStartQtrID;
  self.ActivityEndQtrID = data.ActivityEndQtrID;
  self.ActivityStartGameClock = data.ActivityStartGameClock;
  self.ActivityEndGameClock = data.ActivityEndGameClock;
  self.LocationID = data.LocationID;
  self.LocationName = data.LocationName;

  self.StartTime = ko.observable(data.StartTime);
  self.EndTime = ko.observable(data.EndTime);
  self.StartQtrID = ko.observable(data.StartQtrID);
  self.EndQtrID = ko.observable(data.EndQtrID);
  self.StartGameClock = ko.observable(data.StartGameClock);
  self.EndGameClock = ko.observable(data.EndGameClock);

  self.StartGameTimeDisplay = ko.pureComputed(function () {
    return self.FormatDate(self.StartGameClock(), self.StartQtrID());
  });

  self.EndGameTimeDisplay = ko.pureComputed(function () {
    return self.FormatDate(self.EndGameClock(), self.EndQtrID());
  });

  self.AvailQuarters = ko.pureComputed(function () {
    return [
      {
        ID: 0,
        Name: 'Select'
      },
      {
        ID: 14,
        Name: 'Q1'
      },
      {
        ID: 13,
        Name: 'Q2'
      },
      {
        ID: 12,
        Name: 'Halftime'
      },
      {
        ID: 11,
        Name: 'Q3'
      },
      {
        ID: 10,
        Name: 'Q4'
      },
      {
        ID: 1,
        Name: 'Post'
      }
    ];
  });

  self.AvailMinutes = ko.pureComputed(function () {
    return [
      {
        time: '00:15:00',
        disp: '15:00'
      },
      {
        time: '00:14:00',
        disp: '14:00'
      },
      {
        time: '00:13:00',
        disp: '13:00'
      },
      {
        time: '00:12:00',
        disp: '12:00'
      },
      {
        time: '00:11:00',
        disp: '11:00'
      },
      {
        time: '00:10:00',
        disp: '10:00'
      },
      {
        time: '00:09:00',
        disp: '9:00'
      },
      {
        time: '00:08:00',
        disp: '8:00'
      },
      {
        time: '00:07:00',
        disp: '7:00'
      },
      {
        time: '00:06:00',
        disp: '6:00'
      },
      {
        time: '00:05:00',
        disp: '5:00'
      },
      {
        time: '00:04:00',
        disp: '4:00'
      },
      {
        time: '00:03:00',
        disp: '3:00'
      },
      {
        time: '00:02:00',
        disp: '2:00'
      },
      {
        time: '00:01:00',
        disp: '1:00'
      },
      {
        time: '00:00:00',
        disp: '0:00'
      }
    ];
  });

  self.FormatDate = function (clock, gameQtr) {
    if (!clock || !gameQtr) return '';
    var qtr = self.AvailQuarters().find(function (q) {
      return q.ID == gameQtr;
    });
    if (qtr) return qtr.Name + ' ' + moment().startOf('day').add(moment.duration(clock)).format('mm:ss');
    else return 'Not specified';
  };
};

var WalkInEquipmentFrequencyVM = function (data) {
  var self = this;
  if (typeof data === 'number') {
    self.WalkInEquipmentFrequencyID = 0;
    self.WalkInEquipmentID = 0;
    self.Frequency = ko.observable(data);
    self.FrequencyStatusID = ko.observable(0); // pending
    //self.IsHidden = false;
    self.Notes = ko.observable('');
  } else {
    self.WalkInEquipmentFrequencyID = data.WalkInEquipmentFrequencyID || 0;
    self.WalkInEquipmentID = data.WalkInEquipmentID || 0;
    self.Frequency = ko.observable(data.Frequency || null);
    self.FrequencyStatusID = ko.observable(data.FrequencyStatusID || 0);
    //self.IsHidden = data.IsHidden || false;
    self.Notes = ko.observable(data.Notes || '');
  }

  self.FrequencyDisplay = ko.pureComputed(function () {
    return self.Frequency().toFixed(5);
  });

  self.DisplayClass = ko.pureComputed(function () {
    switch (self.FrequencyStatusID()) {
      case 0:
        return 'pending';
      case 1:
        return 'approved';
      case 2:
        return 'assigned';
      case 3:
        return 'rejected';
      case 4:
        return 'partapproved';
      default:
        return '';
    }
  });

  self.IsAssigned = ko.pureComputed(function () {
    return [1, 2, 4].indexOf(self.FrequencyStatusID()) > -1;
  });
  self.IsPending = ko.pureComputed(function () {
    return self.FrequencyStatusID() == 0;
  });
  self.IsRejected = ko.pureComputed(function () {
    return self.FrequencyStatusID() == 3;
  });
  self.CanAddNote = ko.pureComputed(function () {
    return !self.Notes() && self.IsAssigned();
  });
};

export default WalkInEquipmentAddEditVM;
