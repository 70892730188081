import { parsePhoneNumberFromString, AsYouType } from 'libphonenumber-js';
import { Common } from './Common.js';
import DialogMgr from './DialogMgr.js';

var UserEditVM = function () {
  if (RazorShared.isDebug) console.log('UserEditVM locator');

  var self = this;

  self.$container = $(document);
  self.$form = $('');
  self.okCallback = function () {};

  self.AvailCompanies = ko.observableArray([]);
  self.selectedCompanyID = ko.observable();
  self.selectedAreaIDs = ko.observable();
  self.isNew = false;
  self.SubmitAttempt = ko.observable(false);

  self.NonUSPhone = ko.observable(false);
  self.NonUSMobile = ko.observable(false);
  self.isMobileValid = ko.observable(true);
  self.isPhoneValid = ko.observable(true);

  self.defaultCountryISOCode = ko.observable('US');

  self.init = function ($dlgcontainer, params) {
    if ($dlgcontainer) {
      self.$container = $dlgcontainer;
      self.$form = self.$container.find('form');
    }

    if (params.okCallback) self.okCallback = params.okCallback;

    var $title = self.$container.find('.modal-title');
    self.isNew = params.isNew;
    if (params.isNew) $title.text('Add User');
    else $title.text('Edit User: ' + params.fullName);

    var role = self.$form.find('#AppRoleID').val();
    if (role) self.NewRoleHandler(role);

    self.selectedAreaIDs((UserEditVM.jsonData.AppUserAreaIDs || '').replace(' ', '').split(','));
    self.selectedCompanyID(UserEditVM.jsonData.CompanyID);
    $('#AppUserAreaIDs').val(self.selectedAreaIDs().join(','));

    self.NonUSMobile(UserEditVM.jsonData.NonUSMobile);
    self.NonUSPhone(UserEditVM.jsonData.NonUSPhone);

    if (UserEditVM.jsonData.Mobile && UserEditVM.jsonData.Mobile.length > 0)
      $('#Mobile').val(self.UpdatePhoneFormat(self.NonUSMobile(), UserEditVM.jsonData.Mobile));

    if (UserEditVM.jsonData.Phone && UserEditVM.jsonData.Phone.length > 0)
      $('#Phone').val(self.UpdatePhoneFormat(self.NonUSPhone(), UserEditVM.jsonData.Phone));

    if (UserEditVM.companyData) {
      self.AvailCompanies(UserEditVM.companyData.sort(Common.StringSortFunc('Name')) || []);
    }

    ko.applyBindings(self, self.$container[0]);

    $('#Mobile').keyup(function () {
      var val_old = $(this).val();
      var countryCode = self.defaultCountryISOCode(); //$("#companyCountryCode").val() || self.defaultCountryISOCode();
      if ((countryCode != self.defaultCountryISOCode() || self.NonUSMobile()) && !val_old.startsWith('+')) {
        val_old = '+' + val_old;
      }
      var newString = new AsYouType(countryCode).input(val_old);
      $(this).focus().val('').val(newString);
    });

    $('#Phone').keyup(function () {
      var val_old = $(this).val();
      var countryCode = self.defaultCountryISOCode(); //$("#companyCountryCode").val() || self.defaultCountryISOCode();
      if ((countryCode != self.defaultCountryISOCode() || self.NonUSPhone()) && !val_old.startsWith('+')) {
        val_old = '+' + val_old;
      }
      var newString = new AsYouType(countryCode).input(val_old);
      $(this).focus().val('').val(newString);
    });
  };

  // REPLACED BY KENDO
  //self.SetupTokenField = function () {
  //   self.$areaTokenField = $('#AreaString');

  //   // We will have just reloaded the dialog div, so we need to rehook tokenfield handlers here
  //   self.$areaTokenField.tokenfield({
  //      autocomplete: {
  //         source: [],
  //         delay: 100
  //      },
  //      showAutocompleteOnFocus: true
  //   });

  //   self.$areaTokenField.on('tokenfield:createtoken', function (event) {
  //      self.onCreateToken(event);
  //      setTimeout(function () {
  //         $('#AreaString-tokenfield').blur();
  //         $('#AreaString-tokenfield').focus();
  //         $('#AreaString-tokenfield').click();
  //      }, 0)
  //   });

  //   self.$areaTokenField.on('tokenfield:edittoken', function (event) {
  //      return false;
  //   });

  //   self.$areaTokenField.on('tokenfield:createdtoken', function (event) {
  //      self.setAvailableTokens();
  //   });

  //   self.$areaTokenField.on('tokenfield:removedtoken', function (event) {
  //      self.setAvailableTokens();
  //   });

  //   // refresh...
  //   self.tokenChoices = JSON.parse($('#hdnAreas').val());

  //   self.$areaTokenField.tokenfield('setTokens', self.$areaTokenField.val());
  //   self.setAvailableTokens();

  //}

  self.CompanySearch = function (data, event) {
    self.$focusOnReturn = $(event.target);
    DialogMgr.CompanySearch(self.CompanySelected, true);
  };

  self.CompanySelected = function (company) {
    if (company) {
      if (
        !self.AvailCompanies().find(function (c) {
          return c.ID == company.CompanyID;
        })
      )
        self.AvailCompanies.push({ ID: company.CompanyID, Name: company.Name });
      self.selectedCompanyID(company.CompanyID);
      $('#Address1').val(company.Address1);
      $('#Address2').val(company.Address2);
      $('#CountryID').val(company.CountryID);
      $('#CountryID').trigger('chosen:updated');
      $('#City').val(company.City);
      $('#StateCode').val(company.StateCode);
      $('#StateCode').trigger('chosen:updated');
      $('#ZipCode').val(company.ZipCode);
    }
    self.$focusOnReturn = $('#AppUserCompanyID');
  };

  self.ChangeRole = function (control) {
    self.NewRoleHandler($(control).val());
  };

  self.NewRoleHandler = function (val) {
    var EFC = 'EFC';
    var User = 'Vendor';

    var areaFields = self.$form.find('.areas');
    if (val == EFC) {
      areaFields.show();
    } else {
      areaFields.hide();
      self.selectedAreaIDs(null);
      $('#AppUserAreaIDs').val('');
    }

    var passwordFields = self.$form.find('.passwords');
    if (self.isNew && val == User) {
      passwordFields.show();
      $('#resetPwd').prop('checked', true);
    } else {
      passwordFields.hide();
      $('#resetPwd').prop('checked', false);
    }
  };

  self.onAreaChange = function (item) {
    $('#AppUserAreaIDs').val(item.value().join(', '));
  };

  self.SaveUser = function () {
    var $usa = $('.usa');
    var isDisabled = $usa.attr('disabled');
    $usa.attr('disabled', false);

    self.SubmitAttempt(true);

    if (!self.NonUSMobile() && $('#Mobile').val() && $('#Mobile').val().length > 0) {
      var parseMobile = parsePhoneNumberFromString($('#Mobile').val(), self.defaultCountryISOCode());
      self.isMobileValid(parseMobile && parseMobile.isValid());
    } else if (self.NonUSMobile() || ($('#Mobile').val() && $('#Mobile').val().length == 0)) self.isMobileValid(true);

    if (!self.NonUSPhone() && $('#Phone').val() && $('#Phone').val().length > 0) {
      var parsePhone = parsePhoneNumberFromString($('#Phone').val(), self.defaultCountryISOCode());
      self.isPhoneValid(parsePhone && parsePhone.isValid());
    } else if (self.NonUSPhone() || ($('#Phone').val() && $('#Phone').val().length == 0)) self.isPhoneValid(true);

    if (!self.$form.validate().form() || !self.selectedCompanyID() || !self.isMobileValid() || !self.isPhoneValid()) return;

    if (isDisabled) $usa.attr('disabled', true);

    $.post(RazorShared.baseUrl + 'UserMgmt/SaveUser', self.$form.serialize(), function (data) {
      if (!data.success) {
        Common.Dialog('Error saving user', null, data.error.split('|').join('<br>'));
      } else {
        self.$container.modal('hide');
        self.okCallback(data.appUser);
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Save User', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.UpdatePhoneFormat = function (nonUS, val_old) {
    //var countryCode = $("#companyCountryCode").val() || self.defaultCountryISOCode();

    if (!val_old || val_old.length == 0) return;

    let newString = val_old;
    var startsWithPlus = val_old.startsWith('+');
    if (nonUS) {
      if (!startsWithPlus) val_old = '+' + val_old;
      newString = new AsYouType().input(val_old);
      //$("#Phone").focus().val('').val(newString);
    } else {
      if (startsWithPlus) val_old = val_old.substring(1);
      //self.phoneFormatter = new AsYouType(self.selectedCountryISOCode());
      //var newString = self.phoneFormatter.input(val_old);
      newString = new AsYouType('US').input(val_old);
      //$("#Phone").focus().val('').val(newString);
    }

    return newString;
  };

  self.FormatMobile = function (nonUSMobile) {
    var currentMobile = $('#Mobile').val();

    if (currentMobile && currentMobile.length > 0) $('#Mobile').val(self.UpdatePhoneFormat(nonUSMobile, currentMobile));
  };

  self.FormatPhone = function (nonUSPhone) {
    var currentPhone = $('#Phone').val();

    if (currentPhone && currentPhone.length > 0) $('#Phone').val(self.UpdatePhoneFormat(nonUSPhone, currentPhone));
  };

  self.onCreateToken = function (event) {
    // make sure token matches an existing area but not duplicate of one already selected

    var isManual = event.attrs.value === event.attrs.label; // manual entry won't have our value

    var areaToken = self.tokenChoices.find(function (token) {
      return token.value == event.attrs.value || (isManual && token.label == event.attrs.value);
    });
    if (areaToken && isManual) event.attrs.value = areaToken.value;

    var duplicate = self.$areaTokenField.tokenfield('getTokens').find(function (token) {
      return token.value == event.attrs.value;
    });

    if (areaToken && !duplicate) {
      event.attrs.label = areaToken.label; // make sure to display correct label on init
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  };

  self.setAvailableTokens = function () {
    var curTokens = self.$areaTokenField.tokenfield('getTokens');
    var availTokens = $.map(self.tokenChoices, function (token) {
      if (
        curTokens.find(function (t2) {
          return t2.value == token.value;
        })
      )
        return null; // eliminates it from mapped array
      else return token; // retains it in mapped array
    });
    self.$areaTokenField.data('bs.tokenfield').$input.autocomplete({ source: availTokens });
  };
};

export default UserEditVM;
