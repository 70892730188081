import { RequestNoteVM, RequestNoteListVM } from './RequestViewModels.js';
import { Common, EncodeHTML } from './Common.js';

var RequestNoteListEditVM = function () {
  if (RazorShared.isDebug) console.log('RequestNoteListEditVM locator');

  var self = this;
  self.$container = $(document);
  self.$form = $('');

  self.okCallback = function () {};

  self.ReadOnly = false;

  self.NewNote = function (noteText) {};
  self.CreateNote = function (noteText) {
    return self.NewNote(noteText);
  };

  self.RequestNoteList = new RequestNoteListVM(self.CreateNote);

  self.init = function ($dlgcontainer, params) {
    if ($dlgcontainer) {
      self.$container = $dlgcontainer;
      self.$form = self.$container.find('form');
    }

    if (params.okCallback) self.okCallback = params.okCallback;

    var $title = self.$container.find('.modal-title');
    if (params.title) $title.text(params.title);

    var initData = RequestNoteListEditVM.jsonData;

    self.ReadOnly = initData.ReadOnly;

    if (initData.RequestEquipmentID) {
      self.NewNote = function (noteText) {
        return new RequestNoteVM(
          {
            RequestID: initData.RequestID,
            RequestEquipmentID: initData.RequestEquipmentID,
            Note: noteText ? noteText : ''
          },
          0,
          true
        );
      };
    } else {
      self.RequestNoteList.LoadEquipment(initData.EquipmentList);
      self.NewNote = function (noteText) {
        return new RequestNoteVM(
          {
            RequestID: initData.RequestID,
            Note: noteText ? noteText : ''
          },
          0,
          true
        );
      };
    }

    var notes = ko.observableArray(
      (initData.Notes || []).map(function (x) {
        return new RequestNoteVM(x, 0);
      })
    );

    self.RequestNoteList.LoadList(notes, self.ReadOnly);

    $(window).resize(function () {
      self.RequestNoteList.checkNoteHeight();
    });

    ko.applyBindings(self, self.$container[0]);

    // NOT USEFUL FROM WHAT ED CAN TELL. SCROLLING NOW HAPPENS IN REQUEST VIEWMODEL.JS
    // var myDiv = $(".noteContainer");
    // self.$container.on("shown.bs.modal", function () {
    //    myDiv.animate({
    //       scrollTop: myDiv.prop("scrollHeight") - myDiv.height()
    //    }, 500);
    // });
  };

  self.Save = function (event) {
    if (event.which == 13) return false;

    var success = self.RequestNoteList.SaveAddNote(); // make sure to save even if they didnt
    if (!success) return;

    self.RequestNoteList.RequestNotes().forEach(function (n) {
      if (n.Note() != undefined) n.Note(EncodeHTML(n.Note()));
    });

    var postdata = $.param({
      notes: ko.toJS(self.RequestNoteList.RequestNotes),
      removed: self.RequestNoteList.RemovedNoteIDs
    });

    $.post(RazorShared.baseUrl + 'Request/SaveRequestNoteList', postdata, function (data) {
      if (!data.success) {
        Common.Dialog('Error saving Notes', null, data.error.split('|').join('<br>'));
      } else {
        self.$container.modal('hide');
        self.okCallback(data.requestID);
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Save Notes', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };
};

export default RequestNoteListEditVM;
