import Cookies from 'js-cookie';
import { Common } from './Common.js';
import DialogMgr from './DialogMgr.js';

var EquipmentTypeMgmtVM = function (jsonData) {
  var self = this;

  self.defaultListCount = 50;
  self.loadedCount = 0;
  self.cookieName = 'efcEquipmentTypeSearch';
  var searchCookie = Cookies.getJSON(self.cookieName);

  self.EquipmentTypeSearch = new EquipmentTypeSearch(searchCookie || jsonData.EquipmentTypeSearch, self.defaultListCount);
  self.EquipmentTypeSearchHold = new EquipmentTypeSearch(searchCookie || jsonData.EquipmentTypeSearch, self.defaultListCount);

  self.LoadList = function (callback) {
    $('#loadMessage').show();
    $('#sectEquipmentTypeList').load(
      RazorShared.baseUrl + 'EquipmentModel/GetEquipmentTypeList',
      ko.toJS(self.EquipmentTypeSearch),
      function (response, status, xhr) {
        if (status == 'error') Common.Dialog('Search', 'An error has occurred: ' + xhr.status + ' ' + xhr.statusText, null, xhr.status);
        else self.loadedCount = $('#numLoaded').val();
        $('#loadMessage').hide();
      }
    );
    Cookies.set(self.cookieName, ko.toJS(self.EquipmentTypeSearch), {
      expires: 30
    });
  };

  self.ApplyFilters = function () {
    var temp = self.EquipmentTypeSearch;
    self.EquipmentTypeSearch = new EquipmentTypeSearch(ko.toJS(self.EquipmentTypeSearchHold), self.defaultListCount);
    self.EquipmentTypeSearch.SortBy = temp.SortBy;
    self.EquipmentTypeSearch.SortDesc = temp.SortDesc;
    self.LoadList();
  };

  self.ListMore = function () {
    if (self.EquipmentTypeSearch.RetrieveCount > self.loadedCount)
      // already loaded everything
      return;
    self.EquipmentTypeSearch.RetrieveCount += self.defaultListCount;
    self.LoadList();
  };

  self.SetSort = function (sort) {
    if (self.EquipmentTypeSearch.SortBy == sort) {
      self.EquipmentTypeSearch.SortDesc = !self.EquipmentTypeSearch.SortDesc;
    } else {
      self.EquipmentTypeSearch.SortBy = sort;
      self.EquipmentTypeSearch.SortDesc = false;
    }
    self.LoadList();
  };

  self.ClearSearch = function () {
    self.EquipmentTypeSearch.ClearSearch(self.defaultListCount);
    self.EquipmentTypeSearchHold.ClearSearch(self.defaultListCount);
    self.LoadList();
  };

  self.AddEquipmentType = function () {
    DialogMgr.AddEditEquipmentType(0, self.LoadList);
  };

  self.EditEquipmentType = function (equipmentTypeID, name) {
    DialogMgr.AddEditEquipmentType(equipmentTypeID, self.LoadList);
  };

  self.ActivateEquipmentType = function (id) {
    $.post(RazorShared.baseUrl + 'EquipmentModel/ActivateEquipmentType/' + id, function (data) {
      if (data.success) self.LoadList();
      else Common.Dialog('Error activating Equipment Type', null, data.error.split('|').join('<br>'));
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Activate Equipment Type', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.DeactivateEquipmentType = function (id) {
    $.post(RazorShared.baseUrl + 'EquipmentModel/DeactivateEquipmentType/' + id, function (data) {
      if (data.success) self.LoadList();
      else Common.Dialog('Error deactivating Equipment Type', null, data.error.split('|').join('<br>'));
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Deactivate Equipment Type', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.setupSearchToggle = function ($fld) {
    var toggleCookieName = self.cookieName + '_displayToggled';
    var cookie = Cookies.get(toggleCookieName);
    if (cookie) $fld.click();

    $fld.on('displayToggled', function () {
      if ($(this).hasClass('toggled')) Cookies.set(toggleCookieName, 'toggled');
      else Cookies.remove(toggleCookieName);
    });
  };
};

var EquipmentTypeSearch = function (jsonData, defaultCount) {
  var self = this;

  self.SortBy = jsonData.SortBy || 0;
  self.SortDesc = jsonData.SortDesc || false;
  self.RetrieveCount = defaultCount;
  self.RetrieveStartAt = jsonData.RetrieveStartAt || null;

  self.DescriptionLike = ko.observable(jsonData.DescriptionLike || null);

  self.ClearSearch = function (defaultCount) {
    if (defaultCount == undefined) return;

    self.DescriptionLike(null);

    self.RetrieveCount = defaultCount;
    self.RetrieveStartAt = null;
  };
};

export default EquipmentTypeMgmtVM;
