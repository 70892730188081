import Cookies from 'js-cookie';
import { Common } from './Common.js';
import DialogMgr from './DialogMgr.js';

var LocationMgmtVM = function (jsonData) {
  var self = this;

  self.defaultListCount = 50;
  self.loadedCount = 0;
  self.cookieName = 'efcLocationSearch';
  var searchCookie = Cookies.getJSON(self.cookieName);
  self.LocationSearch = new LocationSearch(searchCookie || jsonData.LocationSearch, self.defaultListCount);
  self.LocationSearchHold = new LocationSearch(searchCookie || jsonData.LocationSearch, self.defaultListCount);

  self.Areas = ko.observableArray((jsonData.Areas || []).sort(Common.StringSortFunc('Name')));
  self.LocationTypes = ko.observableArray((jsonData.LocationTypes || []).sort(Common.StringSortFunc('Value')));
  self.Countries = ko.observableArray(jsonData.Countries || []);
  self.States = ko.observableArray(jsonData.States || []);
  self.Statuses = ko.observableArray(jsonData.Statuses || []);

  self.LoadList = function (callback) {
    $('#loadMessage').show();
    $('#sectLocationList').load(RazorShared.baseUrl + 'LocationMgmt/GetLocationList', ko.toJS(self.LocationSearch), function (response, status, xhr) {
      if (status == 'error') Common.Dialog('Search', 'An error has occurred: ' + xhr.status + ' ' + xhr.statusText, null, xhr.status);
      else self.loadedCount = $('#numLoaded').val();
      $('#loadMessage').hide();
    });
    Cookies.set(self.cookieName, ko.toJS(self.LocationSearch), { expires: 30 });
  };

  self.ApplyFilters = function () {
    var temp = self.LocationSearch;
    self.LocationSearch = new LocationSearch(ko.toJS(self.LocationSearchHold), self.defaultListCount);
    self.LocationSearch.SortBy = temp.SortBy;
    self.LocationSearch.SortDesc = temp.SortDesc;
    self.LoadList();
  };

  self.ListMore = function () {
    if (self.LocationSearch.RetrieveCount > self.loadedCount)
      // already loaded everything
      return;
    self.LocationSearch.RetrieveCount += self.defaultListCount;
    self.LoadList();
  };

  self.SetSort = function (sort) {
    if (self.LocationSearch.SortBy == sort) {
      self.LocationSearch.SortDesc = !self.LocationSearch.SortDesc;
    } else {
      self.LocationSearch.SortBy = sort;
      self.LocationSearch.SortDesc = false;
    }
    self.LoadList();
  };

  self.ClearSearch = function () {
    self.LocationSearch.ClearSearch(self.defaultListCount);
    self.LocationSearchHold.ClearSearch(self.defaultListCount);
    self.LoadList();
  };

  self.EditLocation = function (locationID) {
    DialogMgr.LocationAddEdit(locationID, self.LoadList);
  };

  self.AddLocation = function () {
    DialogMgr.LocationAddEdit(0, self.LoadList);
  };

  self.ActivateLocation = function (id) {
    $.post(RazorShared.baseUrl + 'LocationMgmt/ActivateLocation/' + id, function (data) {
      if (data.success) self.LoadList();
      else Common.Dialog('Error activating Location', null, data.error.split('|').join('<br>'));
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Activate Location', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.DeactivateLocation = function (id) {
    $.post(RazorShared.baseUrl + 'LocationMgmt/DeactivateLocation/' + id, function (data) {
      if (data.success) self.LoadList();
      else {
        if (data.hasFutureEvents)
          Common.Dialog('Location Status Change', 'Cannot change status because there are future events at this Venue.', null, null);
        else Common.Dialog('Error deactivating Location', null, data.error.split('|').join('<br>'));
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Deactivate Location', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.ImportSpectrumScan = function (id) {
    console.log('ImportSpectrumScan LocationID=' + id);

    function ProcessFile(f, contents) {
      console.log('name: ' + f.name + '\ntype: ' + f.type + '\nsize: ' + f.size + ' bytes');

      var patt = /[0-9 a-z]+\.spa/gi;

      if (patt.test(f.name)) {
        console.log('valid filename');

        Common.BlockUI();

        setTimeout(function () {
          $.post(RazorShared.baseUrl + 'LocationMgmt/ImportSpectrumScan/?id=' + id + '&fileName=' + f.name, contents, function (data) {
            if (data.success) Common.Dialog('Import Success', "Successfully imported spectrum scan from file '" + f.name + "'");
            else Common.Dialog('Error importing spectrum scan', null, data.error.split('|').join('<br>'));
          })
            .fail(function (XMLHttpRequest, textStatus, errorThrown) {
              Common.Dialog('Error importing spectrum scan', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
            })
            .always(function () {
              $.unblockUI();
              self.LoadList();
            });
        }, 100);
      } else {
        Common.Dialog('Error', "Invalid file selected: '" + f.name + "'");
      }
    }

    if (window.File && window.FileReader && window.FileList && window.Blob) {
      var input = $(document.createElement('input'));
      input.attr('type', 'file');
      input.attr('accept', '.spa');
      input.on('change', function (e) {
        self.ReadSingleFile(e, ProcessFile);
      });
      input.trigger('click');
    } else {
      Common.Dialog('Error', 'The File APIs are not fully supported by your browser, please contact support.');
    }
  };

  self.ReadSingleFile = function (evt, callback) {
    var f = evt.target.files[0]; //first file only

    if (f) {
      var r = new FileReader();
      r.onload = function (e) {
        var contents = e.target.result;
        callback(f, contents);
      };
      r.readAsText(f);
    } else {
      Common.Dialog('Error', 'Failed to load file, please contact support.');
    }
  };

  self.RemoveScan = function (id, name) {
    Common.DialogConfirmation('Remove Spectrum Scans', 'Are you sure you want to remove all spectrum scans for ' + name + '?', null, function () {
      self.RemoveScanConfirmed(id);
    });
  };

  self.RemoveScanConfirmed = function (id) {
    Common.BlockUI();
    $.post(RazorShared.baseUrl + 'LocationMgmt/RemoveSpectrumScan/' + id, function (data) {
      if (data.success) self.LoadList();
      else Common.Dialog('Error removing spectrum scan', null, data.error.split('|').join('<br>'));
    })
      .fail(function (XMLHttpRequest, textStatus, errorThrown) {
        Common.Dialog('Remove Spectrum Scans', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
      })
      .always(function () {
        $.unblockUI();
      });
  };

  self.setupSearchToggle = function ($fld) {
    var toggleCookieName = self.cookieName + '_displayToggled';
    var cookie = Cookies.get(toggleCookieName);
    if (cookie) $fld.click();

    $fld.on('displayToggled', function () {
      if ($(this).hasClass('toggled')) Cookies.set(toggleCookieName, 'toggled');
      else Cookies.remove(toggleCookieName);
    });
  };

  self.setCountryChangeTrigger = function ($fld) {
    self.LocationSearchHold.CountryIDs.subscribe(function (newValues) {
      if (newValues.length == 0) $fld.val(null);
      else if (newValues.indexOf(RazorShared.CountryID_USA) >= 0) $fld.val(RazorShared.CountryID_USA);
      else $fld.val(1);

      $fld.change();
    });
  };

  self.UpdateAllSelecListSelectedItemSort = function () {
    Common.ShowSelectedItemsOnTop('#searchAreaID');
    Common.ShowSelectedItemsOnTop('#searchCountryID');
    Common.ShowSelectedItemsOnTop('#searchStateCode');
  };
};

var LocationSearch = function (jsonData, defaultCount) {
  var self = this;

  self.SortBy = jsonData.SortBy || 0;
  self.SortDesc = jsonData.SortDesc || false;
  self.RetrieveCount = defaultCount;
  self.RetrieveStartAt = jsonData.RetrieveStartAt || null;

  self.AreaIDs = ko.observableArray(jsonData.AreaIDs || []);
  self.NameLike = ko.observable(jsonData.NameLike || null);
  self.CityLike = ko.observable(jsonData.CityLike || null);
  self.States = ko.observableArray(jsonData.States || []);
  self.CountryIDs = ko.observableArray(jsonData.CountryIDs || []);
  self.OnlyVenues = ko.observable(jsonData.OnlyVenues || false);
  self.LocationTypes = ko.observableArray(jsonData.LocationTypes || []);
  self.Statuses = ko.observableArray(jsonData.Statuses || [1, 3]);

  self.ClearSearch = function (defaultCount) {
    if (defaultCount == undefined) return;

    self.AreaIDs([]);
    self.NameLike(null);
    self.CityLike(null);
    self.States([]);
    self.CountryIDs([]);
    self.OnlyVenues(false);
    self.LocationTypes([]);
    self.Statuses([1, 3]);

    self.RetrieveCount = defaultCount;
    self.RetrieveStartAt = null;
  };
};

export default LocationMgmtVM;
