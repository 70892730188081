import { RequestNoteVM, RequestNoteListVM, RequestEquipmentVM } from './RequestViewModels.js';
import { Common, EncodeHTML } from './Common.js';
import DialogMgr from './DialogMgr.js';

var RequestEquipmentEditVM = function () {
  if (RazorShared.isDebug) console.log('RequestEquipmentEditVM locator');

  var self = this;
  self.$container = $(document);
  self.$form = $('');
  self.$focusOnReturn = null;

  self.okCallback = function () {};

  self.ChildDialog = false;
  self.isEdit = ko.observable(false);
  self.AddAnother = ko.observable(false);
  self.ReadOnly = false;
  self.FromAssignFrequency = false;
  self.EditFrequenciesFromAssign = ko.observable(false);

  self.dialogClass = ko.pureComputed(function () {
    return self.isEdit() ? 'editEquipment' : 'addEquipment';
  });

  self.RequestEquipment = null;

  self.AvailManufacturers = ko.observableArray([]);
  self.AvailModels = ko.observableArray([]);
  self.AvailFrequencyBlocks = ko.observableArray([]);
  self.CanChooseSignalType = ko.observable(true);
  self.AvailEquipmentTypes = ko.observableArray([]);
  self.AvailRequiredUses = ko.observableArray([]);
  self.AddNoteText = ko.observable('');
  self.ModelPower = ko.observable(null);
  self.ModelBandwidth = ko.observable(null);
  self.ModelRepeaterPair = false;
  self.ModelRepeaterSplit = null;
  self.CustomFrequencyBlock = ko.observable(null);

  self.ModelsEnabled = ko.pureComputed(function () {
    return self.RequestEquipment ? self.RequestEquipment.ManufacturerID : false;
  });

  self.FrequencyBlocksEnabled = ko.pureComputed(function () {
    return self.RequestEquipment ? self.RequestEquipment.ModelID : false;
  });

  self.FreqBlockBalloonText = ko.pureComputed(function () {
    return (self.CustomFrequencyBlock() ? 'Edit the' : 'Add a') + ' custom list of tunable frequencies for this equipment.';
  });

  self.FreqBlockButtonText = ko.pureComputed(function () {
    return (self.CustomFrequencyBlock() ? 'Edit' : 'Add') + ' Custom';
  });

  self.init = function ($dlgcontainer, params) {
    if ($dlgcontainer) {
      self.$container = $dlgcontainer;
      self.$form = self.$container.find('form');
    }

    if (params.okCallback) self.okCallback = params.okCallback;

    var $title = self.$container.find('.modal-title');
    if (params.title) $title.text(params.title);

    if (params.isEdit) self.isEdit(true);

    if (params.fromAssignFrequency) self.FromAssignFrequency = params.fromAssignFrequency;

    if (params.jsonInitData) {
      //child of active Add/Edit Request
      self.ChildDialog = true;
      self.load(RequestEquipmentEditVM.jsonData, params.jsonInitData);
    } else {
      self.load(RequestEquipmentEditVM.jsonData); // from MVC model
    }
  };

  self.load = function (mvcData, jsData) {
    self.AvailEquipmentTypes(mvcData.AvailEquipmentTypes.sort(Common.StringSortFunc('Name')) || []);
    self.AvailRequiredUses(mvcData.AvailRequiredUses.sort(Common.StringSortFunc('Name')) || []);
    self.AvailManufacturers(mvcData.AvailManufacturers.sort(Common.StringSortFunc('Name')) || []);
    self.AvailModels(mvcData.AvailModels.sort(Common.StringSortFunc('Name')) || []);
    self.CustomFrequencyBlock(mvcData.CustomFrequencyBlock || null);

    if (self.ChildDialog) {
      self.RequestEquipment = new RequestEquipmentVM(jsData);
    } else {
      self.RequestEquipment = new RequestEquipmentVM(mvcData.RequestEquipment);
      self.EditFrequenciesFromAssign = mvcData.EditFrequenciesFromAssign;
    }

    self.AppUserID = self.RequestEquipment.RequestAppUserID;
    self.RequestNoteList.LoadList(self.RequestEquipment.RequestNotes, mvcData.ReadOnly);

    self.ReadOnly = mvcData.ReadOnly;

    // handle saved but deactivated mfg
    if (self.RequestEquipment.ManufacturerID() && !self.findManufacturer(self.RequestEquipment.ManufacturerID()))
      self.AvailManufacturers.push({
        ID: self.RequestEquipment.ManufacturerID(),
        Name: self.RequestEquipment.ManufacturerName()
      });

    if (self.RequestEquipment.ManufacturerID() && !self.findModel(self.RequestEquipment.ModelID())) {
      // need to reload model list and restore selections...
      self.reloadAvailModels(self.RequestEquipment.ManufacturerID(), self.RequestEquipment.ModelID(), self.RequestEquipment.FrequencyBlockID(), true);
    } else {
      self.SetModelInfo(self.findModel(self.RequestEquipment.ModelID()), true);
    }

    self.setupFrequencyTokenField($('#RequestedFrequencies'), self.ReadOnly);

    if (self.FromAssignFrequency && !self.EditFrequenciesFromAssign) $('#RequestedFrequencies').tokenfield('disable');

    ko.applyBindings(self, self.$container[0]);

    if (self.ReadOnly) {
      self.$form.find(':input').attr('disabled', 'disabled');
    } else {
      self.RequestEquipment.ManufacturerID.subscribe(function (manufID) {
        var manuf = self.findManufacturer(manufID);
        self.RequestEquipment.ManufacturerName(manuf ? manuf.Name : '');
        self.reloadAvailModels(manufID);
      });

      self.RequestEquipment.EquipmentTypeID.subscribe(function (equipTypeID) {
        var equip = self.findEquipmentType(equipTypeID);
        self.RequestEquipment.EquipmentTypeName(equip ? equip.Name : '');
      });

      self.RequestEquipment.ModelID.subscribe(function (modelID) {
        var model = self.findModel(modelID);
        self.SetModelInfo(model);
      });

      self.CanChooseSignalType.extend({ notify: 'always' });
      self.RequestEquipment.SignalTypeID.extend({ notify: 'always' });
    }
  };

  self.reloadAvailModels = function (manufID, initModelID, initFreqBlockID, initialLoad) {
    self.AvailModels.removeAll();

    if (!manufID) {
      self.RequestEquipment.ModelID(null);
      return;
    }

    $.get(RazorShared.baseUrl + 'EquipmentModel/GetModelsForManufacturer', { manufID: manufID, appUserID: self.AppUserID }, function (data) {
      if (data.success) {
        self.AvailModels(data.AvailModels.sort(Common.StringSortFunc('Name')));
      } else {
        Common.Dialog('Error finding models', null, data.error.split('|').join('</br>'));
      }
      if (initModelID) self.ModelListLoaded(initModelID, initFreqBlockID, initialLoad);
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Find Models', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
      self.ModelListLoaded(initModelID, initFreqBlockID, initialLoad);
    });
  };

  self.ModelListLoaded = function (initModelID, initFreqBlockID, initialLoad) {
    if (initModelID) {
      var model = self.findModel(initModelID);
      if (!model) {
        self.NewModelAdded({
          ModelID: initModelID,
          Name: self.RequestEquipment.ModelName(),
          ManufacturerID: self.RequestEquipment.ManufacturerID()
        });
      }
      self.RequestEquipment.ModelID(initModelID);
      self.SetModelInfo(model, initialLoad, initFreqBlockID);
    } else {
      self.RequestEquipment.ModelID(null);
    }
  };

  self.SetModelInfo = function (model, initialLoad, initFreqBlockID) {
    if (model) {
      self.RequestEquipment.ModelName(model.Name);
      self.CanChooseSignalType(model.SignalTypeID != 1 && model.SignalTypeID != 2);
      if (!initialLoad && model.SignalTypeID != 3) self.RequestEquipment.SignalTypeID(model.SignalTypeID);
      self.ModelRepeaterPair = model.RepeaterPair;
      self.ModelRepeaterSplit = model.RepeaterSplit;

      self.AvailFrequencyBlocks(model.FrequencyBlocks);
      if (self.CustomFrequencyBlock()) self.AvailFrequencyBlocks.push(self.CustomFrequencyBlock());
      if (self.AvailFrequencyBlocks() && self.AvailFrequencyBlocks().length == 1)
        self.RequestEquipment.FrequencyBlockID(self.AvailFrequencyBlocks()[0].ID);
      else if (
        initFreqBlockID &&
        self.AvailFrequencyBlocks().find(function (x) {
          return x.ID == initFreqBlockID;
        })
      )
        self.RequestEquipment.FrequencyBlockID(initFreqBlockID);

      if (model.CanShowDetail) {
        if (self.RequestEquipment.IsEquipmentFromStatic) {
          self.ModelPower(self.RequestEquipment.Power);
          self.ModelBandwidth(self.RequestEquipment.Bandwidth);
        } else {
          //if(!initialLoad) {
          self.ModelPower(model.Power ? model.Power.toFixed(0) : '(Not Specified)');
          self.ModelBandwidth(model.Bandwidth ? model.Bandwidth.toFixed(6) : '(Not Specified)');
        }
      } else {
        self.ModelPower('Not Available');
        self.ModelBandwidth('Not Available');
      }
    } else {
      self.RequestEquipment.ModelName('');
      self.CanChooseSignalType(true);

      self.RequestEquipment.FrequencyBlockID(null);
      self.AvailFrequencyBlocks([]);

      self.ModelPower('');
      self.ModelBandwidth('');
    }

    self.RequestEquipment.SignalTypeID(self.RequestEquipment.SignalTypeID()); // force notify for drop-down enable/disable
  };

  self.NewNote = function (noteText) {
    return new RequestNoteVM({ Note: noteText ? noteText : '' }, self.RequestEquipment._equipmentTrack, true);
  };
  self.RequestNoteList = new RequestNoteListVM(self.NewNote);

  self.ViewFrequencyBlock = function () {
    if (self.RequestEquipment.FrequencyBlockID()) DialogMgr.ShowFrequencyListForBlock(self.RequestEquipment.FrequencyBlockID());
  };

  self.findManufacturer = function (manufID) {
    if (!manufID) return null;
    return self.AvailManufacturers().find(function (x) {
      return x.ID == manufID;
    });
  };

  self.findModel = function (modelID) {
    if (!modelID) return null;
    return self.AvailModels().find(function (x) {
      return x.ModelID == modelID;
    });
  };

  self.findEquipmentType = function (equipTypeID) {
    if (!equipTypeID) return null;
    return self.AvailEquipmentTypes().find(function (x) {
      return x.ID == equipTypeID;
    });
  };

  self.checkValid = function (continueCallback) {
    if (self.AddNoteText() && self.RequestEquipment.RequestNotes().length == 0)
      self.RequestEquipment.RequestNotes.push(self.NewNote(self.AddNoteText()));

    if (!self.RequestEquipment.NumBackupFreq()) self.RequestEquipment.NumBackupFreq(0); // temporary to avoid validation issue with null

    let invalidFrequencies = $('div#equipFrequencies').find('div.token.invalid');

    if (self.$form.validate().form() && invalidFrequencies.length == 0) {
      if (typeof continueCallback == 'function') continueCallback();

      //if (self.RequestEquipment.FrequencyBlockID()) {
      //   if (typeof continueCallback == 'function') continueCallback();
      //} else {
      //   Common.DialogConfirmation('No Frequency Block', 'Do you want to save this Equipment with no Frequency Block selected? You will not be able to submit the request without selecting one.', null, continueCallback);
      //}
    }
    self.sendingToRequest = false;
    $.unblockUI();
  };

  self.Save = function () {
    self.checkValid(self.FinishSave);
  };

  self.FinishSave = function () {
    var success = self.RequestNoteList.SaveAddNote(); // make sure to save even if they didnt
    if (!success) return;

    self.RequestNoteList.RequestNotes().forEach(function (n) {
      n.Note(EncodeHTML(n.Note()));
    });

    Common.BlockUI();
    $.post(RazorShared.baseUrl + 'Request/SaveRequestEquipment', ko.toJS(self.RequestEquipment), function (data) {
      if (!data.success) {
        Common.Dialog('Error saving Request Equipment', null, data.error.split('|').join('<br>'));
      } else {
        $('#RequestedFrequencies').tokenfield('destroy');
        self.$container.modal('hide');

        if (!self.ChildDialog && !self.FromAssignFrequency)
          //Editing from dashboard, send requestid to refresh single request
          self.okCallback(data.RequestEquipment.RequestID);
        else self.okCallback(data.RequestEquipment);

        if (self.AddAnother() && !self.ChildDialog) {
          self.$container.on('hidden.bs.modal', function (e) {
            DialogMgr.RequestEquipmentAdd(self.RequestEquipment.RequestID, self.LoadList);
          });
        }
      }
    })
      .fail(function (XMLHttpRequest, textStatus, errorThrown) {
        Common.Dialog('Save Request Equipment', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
      })
      .always(function () {
        $.unblockUI();
      });
  };

  self.sendingToRequest = false;
  self.SendToRequest = function () {
    if (self.sendingToRequest)
      // To avoid duplication by double click
      return;

    Common.BlockUI();
    self.sendingToRequest = true;
    self.checkValid(self.FinishSendToRequest);
  };

  self.FinishSendToRequest = function () {
    self.$container.modal('hide');
    self.$container.on('hidden.bs.modal', function (e) {
      self.okCallback(ko.toJS(self.RequestEquipment), self.AddAnother());
    });
  };

  self.AddNewManufacturer = function (data, event) {
    self.$focusOnReturn = $(event.target);
    DialogMgr.ManufacturerAddEdit(0, self.NewManufacturerAdded, self.RequestEquipment.RequestAppUserID);
  };

  self.NewManufacturerAdded = function (newManuf) {
    if (self.$focusOnReturn) self.$focusOnReturn = $('#ModelID').parent().find('button.dropdown-toggle');
    self.SetNewManufacturer(newManuf.ManufacturerID, newManuf.Name);
  };

  self.SetNewManufacturer = function (id, name) {
    if (!self.findManufacturer(id)) self.AvailManufacturers.push({ ID: id, Name: name });
    self.RequestEquipment.ManufacturerID(id);
  };

  self.AddNewModel = function (data, event) {
    self.$focusOnReturn = $(event.target);
    DialogMgr.ModelAdd(self.NewModelAdded, self.RequestEquipment.ManufacturerID(), self.RequestEquipment.RequestAppUserID);
  };

  self.NewModelAdded = function (newModel) {
    self.SetNewManufacturer(newModel.ManufacturerID, newModel.ManufacturerName);
    if (self.$focusOnReturn) self.$focusOnReturn = $('#FrequencyBlockID').parent().find('button.dropdown-toggle');

    if (!self.findModel(newModel.ModelID)) self.AvailModels.push(newModel);
    self.RequestEquipment.ModelID(newModel.ModelID);
  };

  self.AddEditFrequencyBlock = function (data, event) {
    self.$focusOnReturn = $(event.target);
    if (self.CustomFrequencyBlock()) DialogMgr.FrequencyBlockEdit(self.CustomFrequencyBlock().ID, self.FrequencyBlockAdded);
    else DialogMgr.FrequencyBlockAdd(self.FrequencyBlockAdded, 'R', '');
  };

  self.FrequencyBlockAdded = function (freqBlk) {
    if (self.$focusOnReturn) self.$focusOnReturn = $('#NumPrimaryFreq');

    if (self.CustomFrequencyBlock()) {
      self.AvailFrequencyBlocks.remove(self.CustomFrequencyBlock());
    }
    self.CustomFrequencyBlock(freqBlk);
    self.AvailFrequencyBlocks.push(freqBlk);
    self.RequestEquipment.FrequencyBlockID(freqBlk.ID);
  };

  self.frequencyInvalid = ko.observable(false);

  self.setupFrequencyTokenField = function ($fld, isReadOnly) {
    if (isReadOnly) {
      $fld.on('tokenfield:edittoken tokenfield:removetoken', function (event) {
        event.preventDefault();
        return false;
      });
    } else {
      var reString = '^\\s*0*(?=.*[1-9])\\d{1,7}(?:\\.\\d{0,6})?\\s*$'; //"^\d{1,7}(\.\d{0,5})?$";
      var re = new RegExp(reString);

      $fld.on('tokenfield:createtoken tokenfield:edittoken', function (event) {
        if (!$.isNumeric(event.attrs.value)) {
          event.preventDefault();
          return false;
        }

        event.attrs.label = parseFloat(event.attrs.value).toFixed(5);

        // check for duplicate
        if (
          $(this)
            .tokenfield('getTokens')
            .find(function (token) {
              return token.label == event.attrs.label;
            })
        ) {
          event.preventDefault();
          return false;
        }

        return true;
      });

      $fld.on('tokenfield:createdtoken', function (e) {
        var dataVal = Number(e.attrs.value);
        $(e.relatedTarget).attr('data-val', isNaN(dataVal) ? '' : dataVal);

        self.frequencyInvalid(false);
        if (!re.test(e.attrs.value)) {
          $(e.relatedTarget).addClass('invalid');
          self.frequencyInvalid(true);
        }

        self.RequestEquipment.AddFrequency(e.attrs.value, e.attrs.pairForFreq || null);

        if (
          !e.attrs.initToken &&
          !e.attrs.isRepeaterPair &&
          self.ModelRepeaterPair &&
          self.ModelRepeaterSplit &&
          self.RequestEquipment.FrequencyBlockID()
        ) {
          if (Number(e.attrs.value) && !isNaN(e.attrs.value)) {
            self.addFrequnecyPair($fld, self.RequestEquipment.ModelID(), self.RequestEquipment.FrequencyBlockID(), e.attrs.value);
          }
        }
      });

      $fld.on('tokenfield:removedtoken', function (e) {
        var pairFreq = self.RequestEquipment.RemoveFrequency(e.attrs.value);
        if (pairFreq) self.removeFrequencyPair($fld, pairFreq);

        if (!re.test(e.attrs.value)) self.frequencyInvalid(false);
      });
    }

    $fld.tokenfield({
      createTokensOnBlur: true,
      delimiter: [',', ' ']
    });

    $fld.tokenfield(
      'setTokens',
      self.RequestEquipment.Frequencies().map(function (f) {
        return { value: f.FrequencyDisplay(), initToken: true };
      }),
      false,
      false
    );
  };

  self.returnFocus = function () {
    if (self.$focusOnReturn) self.$focusOnReturn.focus();
    else self.$container.find('.modal-content').focus();
    self.$focusOnReturn = null;
  };

  self.addFrequnecyPair = function ($fld, modelID, frequencyBlockID, freq) {
    if (modelID != self.RequestEquipment.ModelID() || frequencyBlockID != self.RequestEquipment.FrequencyBlockID()) return;

    var params = {
      modelID: self.RequestEquipment.ModelID(),
      frequencyBlockID: self.RequestEquipment.FrequencyBlockID(),
      freq: freq
    };

    $.post(RazorShared.baseUrl + 'Request/GetFrequencyPairToAdd', params, function (data) {
      if (data.success && data.freqToAdd) {
        $fld.tokenfield('createToken', {
          value: data.freqToAdd.toString(),
          isRepeaterPair: true,
          pairForFreq: freq
        });
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Add Repeater Pair Frequency', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.removeFrequencyPair = function ($fld, pairFreq) {
    var pairToken = $("div.token[data-val='" + pairFreq + "']");

    if (pairToken && pairToken.length > 0 && $(pairToken).find('a')) $(pairToken).find('a').trigger('click');
  };
};

export default RequestEquipmentEditVM;
