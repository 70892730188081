import moment from 'moment/min/moment-with-locales';
import { RequestVM, RequestNoteVM, RequestNoteListVM, RequestActivityVM, RequestEquipmentVM, RequestOnsiteContactVM } from './RequestViewModels.js';
import { AsYouType } from 'libphonenumber-js';
import { Common, EncodeHTML } from './Common.js';
import DialogMgr from './DialogMgr.js';

var RequestEditVM = function () {
  //if (RazorShared.isDebug)
  console.log('RequestEditVM locator');

  var self = this;
  self.$container = $(document);
  self.$form = $('');
  self.$focusOnReturn = null;
  self.okCallback = function () {};
  self.ReadOnly = false;

  self.currentStep = ko.observable(1);
  self.StepDirection = 0;

  self.Request = null;

  self.AvailAreas = ko.observableArray([]);
  self.SelectedRequestAreaID = ko.observable(null);

  self.AvailVenues = ko.observableArray([]);
  self.SelectedRequestVenueID = ko.observable(null);

  self.EventSearchTerm = ko.observable('');
  self.AvailEvents = ko.observableArray([]);
  self.EventsEnabled = true;
  self.SelectedRequestEventIDs = ko.observableArray([]);
  self.OriginalEventID = 0;
  self.EventChanged = false;
  self.origRequestJSON = ''; // Used for cancel confirmation

  // Track change in original value to determine if dashboard needs to resort after save or just update one in place.
  self.companyIDOrig = null;
  self.requestorIDOrig = null;
  self.requestStatusIDOrig = null;
  self.resortDashboard = ko.pureComputed(function () {
    return (
      (self.OriginalEventID != self.Request.EventID() &&
        (RazorShared.Dashboard.RequestSearch.SortBy() == 1 || RazorShared.Dashboard.RequestSearch.SortBy() == 2)) ||
      (self.companyIDOrig != self.Request.CompanyID() && RazorShared.Dashboard.RequestSearch.SortBy() == 5) ||
      (self.requestorIDOrig != self.Request.AppUserID() && RazorShared.Dashboard.RequestSearch.SortBy() == 3) ||
      (self.requestStatusIDOrig != self.Request.RequestStatusID() && RazorShared.Dashboard.RequestSearch.SortBy() == 6)
    );
  });

  self.SearchingEvents = ko.observable(false);

  self.SelectAllEvents = ko.pureComputed({
    read: function () {
      return self.SelectedRequestEventIDs().length == self.AvailEvents().length;
    },
    write: function (val) {
      if (val) {
        self.SelectedRequestEventIDs(
          self.AvailEvents().map(function (item) {
            return item.EventID;
          }) || []
        );
      } else self.SelectedRequestEventIDs.removeAll();
    },
    owner: self
  });

  self.SelectedEventsCount = ko.pureComputed(function () {
    return self.SelectedRequestEventIDs().length;
  });

  self.SelEventDate = ko.pureComputed(function () {
    if (!self.Request || !self.Request.EventID()) return '';
    var selEvent = self.AvailEvents().find(function (e) {
      return e.EventID == self.Request.EventID();
    });
    if (!self.Request.CurrUserCanCreateEventInPast && moment(selEvent.EventEndDate).add(48, 'hours').isBefore(moment(), 'day')) {
      self.Request.EventID(null);
      self.ShowPastEventMessage();
      return '';
    } else return selEvent ? moment(selEvent.EventDate).format('l') : '';
  });
  self.ShowPastEvent = ko.observable(false);
  self.ShowPastEventMessage = function () {
    Common.Dialog('Invalid Event Date', 'Please select event date that is not in past');
  };
  self.formatDate = function (eventDate) {
    return eventDate ? moment(eventDate).format('l') : '';
  };

  self.EquipmentMissingFreqBlock = ko.pureComputed(function () {
    return '';
  });

  self.HasNoEquipments = ko.pureComputed(function () {
    return !self.Request || self.Request.RequestEquipments().length == 0;
  });
  self.RequestEquipmentCount = ko.pureComputed(function () {
    if (!self.Request) return 0;
    return self.Request.RequestEquipments().length;
  });

  self.AvailActivities = ko.observableArray([]);
  self.widths = ko.observable();
  self.OriginalActivityIDs = [];
  self.SkipActivitySelection = ko.observable(false);
  self.SelectedRequestActivityIDs = ko.observableArray([]);
  self.SelectAllActivities = ko.pureComputed({
    read: function () {
      return self.SelectedRequestActivityIDs().length == self.AvailActivities().length;
    },
    write: function (val) {
      if (val) {
        self.SelectedRequestActivityIDs(
          self.AvailActivities().map(function (item) {
            return item.ActivityID;
          }) || []
        );
      } else self.SelectedRequestActivityIDs.removeAll();
    },
    owner: self
  });
  self.SelectedRequestActivities = ko.pureComputed(function () {
    var list = [];
    if (self.Request) {
      self.SelectedRequestActivityIDs().forEach(function (id) {
        var sa = self.AvailActivities().find(function (a) {
          return a.ActivityID == id;
        });
        if (sa != null && sa != undefined && !list.includes(sa)) list.push(sa);
      });
    }
    return list;
  });
  self.HasNoActivities = ko.pureComputed(function () {
    if (!self.Request) return true;

    var _eventWithoutActivity = false;
    self.SelectedRequestEventIDs().forEach(function (id) {
      var _eventActivities = self.SelectedRequestActivities().filter(function (a) {
        return a.EventID == id;
      });
      if (!_eventActivities || _eventActivities.length == 0) _eventWithoutActivity = true;
    });

    return _eventWithoutActivity;
  });
  self.AvailActivitiesCount = ko.pureComputed(function () {
    if (!self.AvailActivities) return 0;
    return self.AvailActivities().length;
  });

  self.AvailCompanies = ko.observableArray([]);

  self.AvailContacts = ko.observableArray([]);
  self.HasNoContacts = ko.pureComputed(function () {
    return !self.Request || self.Request.RequestOnsiteContacts().length == 0;
  });
  self.SelContactIDs = ko.observableArray([]);
  self.SelContacts = ko.pureComputed(function () {
    var list = [];
    if (self.Request) {
      self.SelContactIDs().forEach(function (id) {
        var c = self.AvailContacts().find(function (ac) {
          return ac.OnsiteContactID == id;
        });
        if (c != null && c != undefined) list.push(c);
      });
    }
    return list;
  });
  self.AvailContactsCount = ko.pureComputed(function () {
    if (!self.AvailContacts) return 0;
    return self.AvailContacts().length;
  });

  self.AddAnother = ko.observable(false);
  self.SubmitAttempt = ko.observable(false);

  self.EquipmentList = ko.observableArray([]);
  self.AllNotes = ko.observableArray([]);
  self.RemovedNoteIDs = [];

  self.AvailUsersForRequest = ko.observableArray([]);
  self.CanCreateRequestForUsers = ko.observable(false);
  self.RemoveActivityWarningShown = false;

  self.scrollEvents = ko.observable(false);
  self.scrollActivities = ko.observable(false);
  self.scrollActivitiesConfirm = ko.observable(false);
  self.scrollEquipment = ko.observable(false);
  self.scrollEquipmentConfirm = ko.observable(false);
  self.scrollContacts = ko.observable(false);
  self.scrollContactsConfirm = ko.observable(false);

  self.NoteSaved = function (note) {
    let requestEquipment;
    if (note.RequestEquipmentID()) {
      requestEquipment = self.Request.RequestEquipments().find(function (e) {
        return e.RequestEquipmentID == note.RequestEquipmentID();
      });
      if (requestEquipment) requestEquipment.RequestNotes.push(note);
    } else if (note._equipmentTrack() != undefined && note._equipmentTrack() != -1) {
      requestEquipment = self.Request.RequestEquipments().find(function (e) {
        return e._equipmentTrack == note._equipmentTrack();
      });
      if (requestEquipment) {
        note._noteTrack = requestEquipment.RequestNotes().length;
        requestEquipment.RequestNotes.push(note);
      }
    } else {
      self.Request.RequestNotes.push(note);
    }
  };

  self.NoteRemoved = function (noteID, requestEquipmentID) {
    self.RemovedNoteIDs.push(noteID);
    var removenote;
    if (requestEquipmentID == 0) {
      removenote = self.Request.RequestNotes().find(function (rn) {
        return rn.RequestNoteID == noteID;
      });
      if (removenote) self.Request.RequestNotes.remove(removenote);
    } else {
      var requestEquipment = self.Request.RequestEquipments().find(function (e) {
        return e.RequestEquipmentID == requestEquipmentID;
      });
      if (requestEquipment) {
        removenote = requestEquipment.RequestNotes().find(function (n) {
          return n.RequestNoteID == noteID;
        });
        if (removenote) requestEquipment.RequestNotes.remove(removenote);
      }
    }
  };

  self.NoteEdited = function (note, selectedEquipment) {
    var fromEquipment = null;
    var toEquipment = null;
    if (note != undefined) {
      if (selectedEquipment != undefined) {
        //Remove note from
        if (note.RequestEquipmentID() != null && note.RequestEquipmentID() != 0)
          fromEquipment = self.Request.RequestEquipments().find(function (e) {
            return e.RequestEquipmentID == note.RequestEquipmentID();
          });
        else if (note._equipmentTrack() != null && note._equipmentTrack() != -1)
          fromEquipment = self.Request.RequestEquipments().find(function (e) {
            return e._equipmentTrack == note._equipmentTrack();
          });
        //Move note to
        if (selectedEquipment.ID != null && selectedEquipment.ID != 0)
          toEquipment = self.Request.RequestEquipments().find(function (e) {
            return e.RequestEquipmentID == selectedEquipment.ID;
          });
        else if (selectedEquipment._equipmentTrack != null && selectedEquipment._equipmentTrack != -1)
          toEquipment = self.Request.RequestEquipments().find(function (e) {
            return e._equipmentTrack == selectedEquipment._equipmentTrack;
          });

        if (toEquipment) toEquipment.RequestNotes.push(note);

        if (fromEquipment) fromEquipment.RequestNotes.remove(note);
        else self.Request.RequestNotes.remove(note);
      } else {
        //Remove note from
        if (note.RequestEquipmentID() != null && note.RequestEquipmentID() != 0)
          fromEquipment = self.Request.RequestEquipments().find(function (e) {
            return e.RequestEquipmentID == note.RequestEquipmentID();
          });
        else if (note._equipmentTrack() != null && note._equipmentTrack() != -1)
          fromEquipment = self.Request.RequestEquipments().find(function (e) {
            return e._equipmentTrack == note._equipmentTrack();
          });

        if (fromEquipment) {
          self.Request.RequestNotes.push(note);
          fromEquipment.RequestNotes.remove(note);
        }
      }
    }
  };

  self.NewNote = function (noteText) {};
  self.CreateNote = function (noteText) {
    var newNote = self.NewNote(noteText);
    newNote.SetSaveFunc(self.NoteEdited);
    newNote.UpdatedEquipmentFirst = true;
    return newNote;
  };

  self.RequestNoteList = new RequestNoteListVM(self.CreateNote, self.NoteSaved, self.NoteRemoved);

  self.init = function ($dlgcontainer, params) {
    if ($dlgcontainer) {
      self.$container = $dlgcontainer;
      self.$form = self.$container.find('form');
    }

    if (params.okCallback) self.okCallback = params.okCallback;
    if (params.addAnother) self.AddAnother(params.addAnother);

    var $title = self.$container.find('.modal-title');
    if (params.title) $title.text(params.title);

    self.load(RequestEditVM.jsonData);

    $('#EventSearchTerm').on('keyup', function (e) {
      if (e.keyCode === 13) {
        event.preventDefault();
        self.SearchEvents();
      }
    });
  };
  self.formatWidths = function (activities) {
    var eChars = 0;
    var aChars = 0;
    activities().forEach(function (a) {
      //eChars = a.EventName();
      //aChars = a.ActivityName();
      eChars = Math.max(a.EventName.length, eChars);
      aChars = Math.max(a.ActivityName.length, aChars);
    });
    var eWidth = Math.round(635 * (eChars / (aChars + eChars)));
    var aWidth = 635 - eWidth;

    return [eWidth + 'px', aWidth + 'px'];
  };

  self.load = function (jsonData) {
    self.ReadOnly = jsonData.ReadOnly;
    // load models & arrays
    self.CanCreateRequestForUsers(jsonData.CanCreateRequestForUsers);
    self.AvailUsersForRequest((jsonData.AvailUsersForRequest || []).sort(Common.StringSortFunc('Name')));
    self.AvailEvents(jsonData.AvailEvents || []);
    self.AvailActivities(
      jsonData.AvailActivities.map(function (a) {
        return new RequestActivityVM(null, a);
      }) || []
    );
    self.widths(self.formatWidths(self.AvailActivities));

    self.AvailCompanies(jsonData.AvailCompanies.sort(Common.StringSortFunc('Name')) || []);
    self.AvailContacts(jsonData.AvailOnsiteContacts || []);
    self.AvailContacts().forEach(function (c) {
      c.Phone = self.UpdatePhoneFormat(c.NonUSPhone, c.Phone);
    });

    self.Request = new RequestVM(jsonData.Request);

    self.SelectedRequestAreaID(self.Request.AreaID());
    self.SelectedRequestVenueID(self.Request.VenueID());
    if (self.Request.EventID() && self.Request.EventID() > 0) {
      self.SelectedRequestEventIDs.push(self.Request.EventID());
      self.OriginalEventID = self.Request.EventID();
      if (self.AvailActivities().length == 1 && self.Request.RequestActivities() && self.Request.RequestActivities().length > 0)
        self.SkipActivitySelection(true);
    }

    if (self.Request.CurrUserCanCreateEventInPast && self.SelEventDate()) {
      if (moment().isAfter(self.SelEventDate(), 'day')) self.ShowPastEvent(true);
    }

    if (self.Request && self.Request.RequestActivities() && self.Request.RequestActivities().length > 0) {
      self.Request.RequestActivities().forEach(function (a) {
        self.SelectedRequestActivityIDs.push(a.ActivityID);
        self.OriginalActivityIDs.push(a.ActivityID);
      });
    }

    if (self.Request && self.Request.RequestOnsiteContacts() && self.Request.RequestOnsiteContacts().length > 0) {
      self.Request.RequestOnsiteContacts().forEach(function (c) {
        self.SelContactIDs.push(c.OnsiteContactID);
        var sac = self.AvailContacts().find(function (ac) {
          return ac.OnsiteContactID == c.OnsiteContactID;
        });
        if (sac != null && sac != undefined) sac.SendEmails = c.SendEmails;
      });
    }

    self.NewNote = function (noteText) {
      return new RequestNoteVM(
        {
          RequestID: self.Request.RequestID,
          Note: noteText ? noteText : '',
          UpdatedEquipmentFirst: true
        },
        0,
        true
      );
    };

    self.Request.RequestNotes().forEach(function (n) {
      n._equipmentTrack(-1);
      n.SetSaveFunc(self.NoteEdited);
      n.UpdatedEquipmentFirst = true;
      self.AllNotes.push(n);
    });

    if (self.Request.RequestEquipments() != undefined) {
      var numEquipments = self.Request.RequestEquipments().length;
      for (let i = 0; i < numEquipments; i++) {
        var eq = self.Request.RequestEquipments()[i];
        self.EquipmentList.push({
          ID: eq.RequestEquipmentID,
          _equipmentTrack: eq._equipmentTrack,
          Name: eq.EquipmentTypeName() + ' - ' + eq.HowUsed()
        });
        if (self.Request.RequestEquipments()[i].RequestNotes() != undefined) {
          for (let j = 0; j < self.Request.RequestEquipments()[i].RequestNotes().length; j++) {
            self.Request.RequestEquipments()
              [i].RequestNotes()
              .forEach(function (n) {
                n._equipmentTrack(self.Request.RequestEquipments()[i]._equipmentTrack);
                n.SetSaveFunc(self.NoteEdited);
                n.UpdatedEquipmentFirst = true;
              });
            self.AllNotes.push(self.Request.RequestEquipments()[i].RequestNotes()[j]);
          }
        }
      }
    }

    self.RequestNoteList.LoadList(self.AllNotes, self.ReadOnly);
    self.RequestNoteList.LoadEquipment(ko.toJS(self.EquipmentList));

    self.origRequestJSON = ko.toJSON(self.Request);

    // Track change in original value to determine if dashboard needs to resort after save or just update one in place.
    self.companyIDOrig = self.Request.CompanyID();
    self.requestorIDOrig = self.Request.AppUserID();
    self.requestStatusIDOrig = self.Request.RequestStatusID();

    ko.applyBindings(self, self.$container[0]);

    if (self.ReadOnly) {
      self.$form.find(':input').attr('disabled', 'disabled');
      self.$form.find('.enableReadOnly').attr('disabled', null);
    } else {
      self.Request.AppUserID.subscribe(function (appUserID) {
        self.reloadAvailContacts(appUserID);
        self.updateRequestCompany(appUserID);
      });
      self.ShowPastEvent.subscribe(function (showPastEvent) {
        self.SearchEvents();
      });
      self.AvailActivitiesCount.subscribe(function (c) {
        self.scrollActivities($($('#activitiesWizard').children('.wTBody')).hasScrollBar());
      });
      self.RequestEquipmentCount.subscribe(function (c) {
        self.scrollEquipment($($('#equipmentWizard').children('.wTBody')).hasScrollBar());
      });
      self.AvailContactsCount.subscribe(function (c) {
        self.scrollContacts($($('#contactsWizard').children('.wTBody')).hasScrollBar());
      });
      self.SelectedEventsCount.subscribe(function (c) {
        self.EventChanged = true;
      });
    }

    // DJM addition 4.227 for EFC-1336
    function mobileAndTabletCheck() {
      let check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
            a
          ) ||
          // eslint-disable-next-line no-useless-escape
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    }

    if (mobileAndTabletCheck()) $('#divRequest').addClass('mobile');

    self.UpdateScrollHeight();
  };

  self.NextStep = function () {
    self.StepDirection = 1;

    if (self.currentStep() == 1) {
      if (self.Request.AppUserID() == 0 || self.Request.CompanyID() == 0) return false;
    }
    self.SwitchStep(self.currentStep(), self.currentStep() + 1);
  };

  self.PreviousStep = function () {
    self.StepDirection = -1;

    if (self.currentStep() == 3 && self.SkipActivitySelection()) self.SwitchStep(self.currentStep(), self.currentStep() - 2);
    else self.SwitchStep(self.currentStep(), self.currentStep() - 1);
  };

  self.NextDisabled = ko.pureComputed(function () {
    if (self.currentStep() == 6) return true;
    if (self.currentStep() == 1 && !self.ActivityTabClickable()) return true;
    if (self.currentStep() == 2 && (self.SelectedRequestActivityIDs().length == 0 || self.HasNoActivities())) return true;
    if (self.currentStep() == 3 && (!self.Request.RequestEquipments || self.Request.RequestEquipments().length == 0)) return true;
    return false;
  });

  self.PreviousDisabled = ko.pureComputed(function () {
    return self.currentStep() == 1;
  });

  self.RequestEventChanged = function () {
    self.EventChanged = true;
    if (self.SelectedRequestEventIDs().length == 0 && self.Request.EventID() > 0) self.SelectedRequestEventIDs.push(self.Request.EventID());
    else if (self.SelectedRequestEventIDs().length > 0 && self.Request.EventID() > 0) self.SelectedRequestEventIDs()[0] = self.Request.EventID();
  };

  self.ActivityTabClickable = ko.pureComputed(function () {
    return self.Request.AppUserID() > 0 && self.Request.CompanyID() > 0 && self.SelectedRequestEventIDs().length > 0;
  });

  self.EquipmentTabClickable = ko.pureComputed(function () {
    return self.ActivityTabClickable() && self.SelectedRequestActivityIDs().length > 0;
  });

  self.ContactsTabClickable = ko.pureComputed(function () {
    return self.EquipmentTabClickable() && self.Request.RequestEquipments && self.Request.RequestEquipments().length > 0;
  });

  self.NotesTabClickable = ko.pureComputed(function () {
    return self.ContactsTabClickable();
  });

  self.ConfirmationTabClickable = ko.pureComputed(function () {
    return self.NotesTabClickable();
  });

  self.TabClick = function (tabnum) {
    if (self.currentStep() == 1) {
      if (tabnum == 2) self.StepDirection = 1;
      else if (tabnum > 2) self.StepDirection = 0;
    }

    switch (tabnum) {
      case 1:
        self.SwitchStep(self.currentStep(), 1);
        break;
      case 2:
        if (!self.ActivityTabClickable()) return;
        self.SwitchStep(self.currentStep(), 2);
        break;
      case 3:
        if (!self.EquipmentTabClickable()) return;
        self.SwitchStep(self.currentStep(), 3);
        break;
      case 4:
        if (!self.ContactsTabClickable()) return;
        self.SwitchStep(self.currentStep(), 4);
        break;
      case 5:
        if (!self.NotesTabClickable()) return;
        self.SwitchStep(self.currentStep(), 5);
        break;
      case 6:
        if (!self.ConfirmationTabClickable()) return;
        self.SwitchStep(self.currentStep(), 6);
        break;
    }
  };

  self.SwitchStep = function (step, newStep) {
    if (step == 1) {
      //console.log($($('.modal-body')[1]).height());
      $('.requestEdit .modal-body').css('min-height', $($('.modal-body')[1]).height() + 20);
      if (newStep == 2) self.StepDirection = 1;
      else if (newStep > 2) self.StepDirection = 0;

      //if (self.EventChanged || self.Request.EventID() == 0)
      //   self.SelectedRequestActivityIDs.removeAll();
      self.reloadAvailActivities();
      if (self.Request.EventID() > 0 && self.OriginalEventID == self.Request.EventID()) {
        self.OriginalActivityIDs.forEach(function (a) {
          self.SelectedRequestActivityIDs().push(a);
        });
      }
    }

    self.RequestNoteList.RequestConfirmationView(newStep == 6);
    switch (newStep) {
      case 1:
        break;
      case 2:
        break;
      case 3:
        if (self.Request.RequestEquipments().length == 0) self.AddNewEquipment();
        break;
      case 4:
        break;
      case 5:
        break;
      case 6:
        if (self.SelectedRequestEventIDs().length == 0 || self.SelectedRequestActivityIDs().length == 0) return;
        break;
    }

    self.currentStep(newStep);

    self.UpdateScrollHeight();
  };

  self.SearchEvents = function () {
    var ajaxParam = {};
    ajaxParam.searchTerm = self.EventSearchTerm().trim();
    ajaxParam.currentOnly = !self.ShowPastEvent();
    ajaxParam.currentEventID = self.Request.EventID();
    ajaxParam.newRequestSelectedEventIDs = self.SelectedRequestEventIDs();

    self.SearchingEvents(true);

    $.post(RazorShared.baseUrl + 'Request/GetEventsByStringSearch', ajaxParam, function (data) {
      if (data.success) {
        self.AvailEvents(data.AvailEvents || []);
        self.AvailActivities.removeAll();
        self.Request.RequestActivities.removeAll();
        self.scrollEvents($($('#eventsWizard').children('.wTBody')).hasScrollBar());
        self.SelectedRequestEventIDs(data.SelectedEventIDs || []);
      } else {
        Common.Dialog('Error finding activities', null, data.error.split('|').join('<br>'));
      }
    })
      .fail(function (XMLHttpRequest, textStatus, errorThrown) {
        Common.Dialog('Find Activities', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
      })
      .always(function () {
        self.SearchingEvents(false);
      });
  };

  self.reloadAvailActivities = function () {
    self.AvailActivities.removeAll();
    self.Request.RequestActivities.removeAll();

    if (!self.SelectedRequestEventIDs) return;
    if (self.SelectedRequestEventIDs().length == 0) return;

    $.post(RazorShared.baseUrl + 'Request/GetActivitiesForEvents', { eventIDs: self.SelectedRequestEventIDs() }, function (data) {
      if (data.success) {
        self.AvailActivities.removeAll();
        self.Request.RequestActivities.removeAll();

        self.AvailActivities(
          data.AvailActivities.map(function (activity) {
            return new RequestActivityVM(null, activity);
          }) || []
        );

        self.widths(self.formatWidths(self.AvailActivities));
        self.SkipActivitySelection(data.skipActivitySelection);
        if (self.SkipActivitySelection()) {
          self.AvailActivities().forEach(function (a) {
            self.SelectedRequestActivityIDs.push(a.ActivityID);
          });
          if (self.StepDirection == 1)
            // && !self.NavigatingFromEventTab())
            self.NextStep();
        } else if (self.currentStep() == 3 && self.EventChanged == true) {
          self.SwitchStep(3, 2);
          self.EventChanged = false;
        }
      } else {
        Common.Dialog('Error finding activities', null, data.error.split('|').join('<br>'));
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Find Activities', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.SelectedRequestActivitiesSorted = ko.pureComputed(function () {
    var sortedActivities = (self.SelectedRequestActivities() || []).sort(Common.DateSortAsc('MainEventDate'));
    return sortedActivities;
  });

  self.UpdateRequest = function () {
    if (self.SelectedRequestEventIDs().length == 0 || self.SelectedRequestActivityIDs().length == 0) return;

    self.Request.EventID(self.SelectedRequestEventIDs()[0]);
    var activities = self.SelectedRequestActivities().filter(function (ra) {
      return ra.EventID == self.SelectedRequestEventIDs()[0];
    });
    self.Request.RequestActivities.removeAll();
    if (activities && activities.length > 0) {
      activities.forEach(function (a) {
        self.Request.RequestActivities.push(a);
      });
    }

    self.Request.RequestOnsiteContacts.removeAll();
    self.SelContacts().forEach(function (contact) {
      self.Request.RequestOnsiteContacts.push(contact);
    });
  };

  self.reloadAvailContacts = function (appUserID) {
    if (!appUserID) {
      self.Request.AppUserName('');
      self.AvailContacts.removeAll();
      self.Request.RequestOnsiteContacts.removeAll(); //Remove since new user may not have these as available contacts
      return;
    }
    var selectedUser = self.AvailUsersForRequest().find(function (u) {
      return u.ID == appUserID;
    });
    if (selectedUser) self.Request.AppUserName(selectedUser.Name);

    $.get(RazorShared.baseUrl + 'OnsiteContacts/GetContactsForUser', { appUserID: appUserID }, function (data) {
      if (data.success) {
        self.AvailContacts.removeAll();
        self.Request.RequestOnsiteContacts.removeAll(); //Remove since new user may not have these as available contacts
        self.AvailContacts(data.AvailContacts);
        self.AvailContacts().forEach(function (c) {
          c.Phone = self.UpdatePhoneFormat(c.NonUSPhone, c.Phone);
        });
        //self.Request.AppUserID(appUserID);
        //what to do if request has contact already selected
      } else {
        Common.Dialog('Error finding on site contacts', null, data.error.split('|').join('<br>'));
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Find On Site Contacts', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.UpdatePhoneFormat = function (nonUS, val_old) {
    if (!val_old || val_old.length == 0) return;

    let newString = val_old;
    var startsWithPlus = val_old.startsWith('+');
    if (nonUS) {
      if (!startsWithPlus) val_old = '+' + val_old;
      newString = new AsYouType().input(val_old);
    } else {
      if (startsWithPlus) val_old = val_old.substring(1);
      newString = new AsYouType('US').input(val_old);
    }

    return newString;
  };

  self.updateRequestCompany = function (appUserID) {
    if (!appUserID) {
      self.Request.CompanyID(null);
      return;
    }

    var selectedUser = self.AvailUsersForRequest().find(function (u) {
      return u.ID == appUserID;
    });
    if (selectedUser) {
      var company = self.AvailCompanies().find(function (c) {
        return c.ID == selectedUser.ParentID;
      });
      if (company) {
        self.Request.CompanyID(company.ID);
        self.Request.CompanyName(company.Name);
      } else {
        self.Request.CompanyID(null);
        self.Request.CompanyName('');
      }
      $('#CompanyID').trigger('chosen:updated');
    }
  };

  self.CompanyChanged = function () {
    var company = self.AvailCompanies().find(function (c) {
      return c.ID == self.Request.CompanyID();
    });
    if (company) {
      self.Request.CompanyName(company.Name);
    }
  };

  self.CompanySearch = function (data, event) {
    self.$focusOnReturn = $(event.target);
    DialogMgr.CompanySearch(self.CompanySelected);
  };

  self.CompanySelected = function (companyID, newCompanyName) {
    if (companyID > 0) {
      if (
        !self.AvailCompanies().find(function (c) {
          return c.ID == companyID;
        })
      )
        self.AvailCompanies.push({ ID: companyID, Name: newCompanyName });
      self.Request.CompanyID(companyID);
      self.Request.CompanyName(newCompanyName);
      $('#CompanyID').trigger('chosen:updated');
    }
    self.$focusOnReturn = $('#CompanyID');
  };

  self.AddNewUser = function (data, event) {
    self.$focusOnReturn = $(event.target);
    DialogMgr.UserAddEdit(0, null, self.NewUserAdded);
  };

  self.NewUserAdded = function (newUser) {
    if (newUser) {
      self.AvailUsersForRequest.push({
        ID: newUser.AppUserID,
        Name: newUser.FullName + ' - ' + newUser.Company
      });
      self.AvailUsersForRequest().sort(Common.StringSortFunc('Name'));
      self.Request.AppUserID(newUser.AppUserID);
      $('#AppUserID').trigger('chosen:updated');
    }
  };

  self.AddNewContact = function (data, event) {
    self.$focusOnReturn = $(event.target);
    DialogMgr.OnsiteContactAdd(self.Request.AppUserID(), 'Add Contact', self.NewContactAdded);
  };

  self.NewContactAdded = function (newContact) {
    if (newContact) {
      self.AvailContacts.push(new RequestOnsiteContactVM(newContact));
      self.SelContactIDs.push(newContact.OnsiteContactID);
    }
  };

  self.AddNewEquipment = function (data, event) {
    if (event != null) self.$focusOnReturn = $(event.target);
    DialogMgr.ChildRequestEquipmentAddEdit(
      'Add Equipment For Request',
      self.NewEquipmentAdded,
      { RequestAppUserID: self.Request.AppUserID() },
      false
    );
  };

  self.NewEquipmentAdded = function (newEquip, addAnother) {
    if (newEquip) {
      var newidx = self.Request.RequestEquipments().length + 1;
      var newequipVM = new RequestEquipmentVM(newEquip, newidx);
      self.Request.RequestEquipments.push(newequipVM);

      self.EquipmentList.push({
        ID: newEquip.RequestEquipmentID,
        _equipmentTrack: newidx,
        Name: newEquip.EquipmentTypeName + ' - ' + newEquip.HowUsed
      });
      self.RequestNoteList.EquipmentList.push({
        ID: newEquip.RequestEquipmentID,
        _equipmentTrack: newidx,
        Name: newEquip.EquipmentTypeName + ' - ' + newEquip.HowUsed
      });

      newequipVM.RequestNotes().forEach(function (n) {
        n._equipmentTrack(newidx);
        n.SetSaveFunc(self.NoteEdited);
        n.UpdatedEquipmentFirst = true;
        self.RequestNoteList.RequestNotes.push(n);
      });

      window.setTimeout(function () {
        $('#AddEquipmentBtn').focus();
      }, 10);

      if (addAnother)
        DialogMgr.ChildRequestEquipmentAddEdit(
          'Add Equipment For Request',
          self.NewEquipmentAdded,
          { RequestAppUserID: self.Request.AppUserID() },
          false
        );
    }
  };

  self.RemoveEquipment = function (equip) {
    self.Request.RequestEquipments.remove(equip);
  };

  self.EditEquipment = function (equip) {
    DialogMgr.ChildRequestEquipmentAddEdit('Edit Equipment For Request', self.EquipmentEdited, ko.toJS(equip), true);
  };

  self.ViewEquipment = function (equip) {
    DialogMgr.RequestEquipmentView(equip.RequestEquipmentID);
  };

  self.EquipmentEdited = function (equip, addAnother) {
    if (equip) {
      var existIdx = self.Request.RequestEquipments.indexOf(
        self.Request.RequestEquipments().find(function (x) {
          return x._equipmentTrack == equip._equipmentTrack;
        })
      );
      if (existIdx > -1) {
        //self.Request.RequestEquipments.splice(existIdx, 1, equip);

        // Update notes
        equip.RequestNotes.forEach(function (n) {
          if (ko.isObservable(n._equipmentTrack)) n._equipmentTrack(equip._equipmentTrack);
          else n._equipmentTrack = equip._equipmentTrack;
        });

        // Update equip list for notes
        var origEquip = self.Request.RequestEquipments()[existIdx];
        if (origEquip.EquipmentTypeID() != equip.EquipmentTypeID) {
          let equipListItemIdx = self.EquipmentList.indexOf(
            self.EquipmentList().find(function (x) {
              return x._equipmentTrack == origEquip._equipmentTrack;
            })
          );
          self.EquipmentList.splice(equipListItemIdx, 1, {
            ID: equip.RequestEquipmentID,
            _equipmentTrack: equip._equipmentTrack,
            Name: equip.EquipmentTypeName + ' - ' + equip.HowUsed
          });
          self.RequestNoteList.EquipmentList.splice(equipListItemIdx, 1, {
            ID: equip.RequestEquipmentID,
            _equipmentTrack: equip._equipmentTrack,
            Name: equip.EquipmentTypeName + ' - ' + equip.HowUsed
          });
        }

        // Update request equipments
        self.Request.RequestEquipments.splice(existIdx, 1, new RequestEquipmentVM(equip, existIdx));
      } else {
        // it's new...
        self.NewEquipmentAdded(equip);
      }

      if (addAnother)
        DialogMgr.ChildRequestEquipmentAddEdit(
          'Add Equipment For Request',
          self.NewEquipmentAdded,
          { RequestAppUserID: self.Request.AppUserID() },
          false
        );
    }
  };

  self.RemoveRequestActivity = function (requestActivity) {
    self.Request.RequestActivities.remove(requestActivity);
  };

  self.Save = function () {
    // VALIDATE...
    if (!self.$form.validate().form()) return false;

    self.RequestNoteList.RequestNotes().forEach(function (n) {
      n.Note(EncodeHTML(n.Note()));
    });

    var updateID = self.Request.RequestID; //New request will have 0 for ID which will reload all requests on dashboard so it can re-sort
    if (self.resortDashboard()) updateID = 0;

    var ajaxParams = {};
    ajaxParams.model = ko.toJS(self.Request);
    ajaxParams.eventIDs = self.SelectedRequestEventIDs();
    ajaxParams.activities = self.SelectedRequestActivities();

    Common.BlockUI();
    $.post(RazorShared.baseUrl + 'Request/SaveRequest', ajaxParams, function (data) {
      if (!data.success) {
        $.unblockUI();
        Common.Dialog('Error saving request', null, data.error.split('|').join('<br>'));
        if (data.emailError && data.emailError == true) {
          self.$container.modal('hide');
          self.okCallback(updateID);
        }
      } else {
        $.unblockUI();
        self.$container.modal('hide');
        self.okCallback(updateID); // TODO: what to return?

        if (self.AddAnother())
          DialogMgr.RequestAddAnother(
            self.Request.AppUserID(),
            self.Request.EventID(),
            self.Request.VenueID(),
            self.Request.AreaID(),
            self.Request.CompanyID(),
            'Add Request',
            self.okCallback
          );
      }
    })
      .fail(function (XMLHttpRequest, textStatus, errorThrown) {
        $.unblockUI();
        Common.Dialog('Save Request', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
      })
      .always(function () {
        self.submitBtnDisabled(false);
      });
  };

  self.submitBtnDisabled = ko.observable(false); //To prevent duplicate by quickly clicking multiple times
  self.Submit = function () {
    self.submitBtnDisabled(true);
    var success = self.RequestNoteList.SaveAddNote(); // make sure to save even if they didnt
    if (!success) {
      self.submitBtnDisabled(false);
      return;
    }

    self.UpdateRequest();

    self.SubmitAttempt(true); // Used for validation
    if (self.HasNoActivities() || self.HasNoEquipments() || self.EquipmentMissingFreqBlock()) return;

    Common.DialogConfirmation(
      'Confirm Submit Request',
      'Changes to the request will no longer be allowed.',
      function () {
        self.submitBtnDisabled(false);
      },
      self.SubmitConfirmed
    );
  };

  self.CancelSave = function () {
    if (!self.ReadOnly && self.origRequestJSON != ko.toJSON(self.Request)) {
      //Activities are retrieved after switching from 1st tab since event can change.  So switching tab will always make object different even when nothing has changed.
      Common.DialogConfirmation('Cancel Save', 'Discard Changes & Cancel Saving Request?', null, function () {
        self.$container.modal('hide');
      });
    } else {
      //This will happen only when user never switch from 1st tab on existing request.  Company is added to new request when dialog opens so it will always show discard message.
      self.$container.modal('hide');
    }
  };

  self.SubmitConfirmed = function () {
    self.Request.RequestStatusID(1); // submitted
    self.Save();
  };

  self.SaveDraft = function () {
    var success = self.RequestNoteList.SaveAddNote(); // make sure to save even if they didnt
    if (!success) return;
    self.SubmitAttempt(false);
    self.Request.RequestStatusID(5); // draft
    self.Save();
  };

  self.SaveSubmitted = function () {
    var success = self.RequestNoteList.SaveAddNote(); // make sure to save even if they didnt
    if (!success) return;

    self.SubmitAttempt(true);
    if (self.HasNoActivities() || self.HasNoEquipments()) return; // || self.EquipmentMissingFreqBlock()
    self.Request.SavingSubmittedRequest(true);
    self.Save();
  };

  self.returnFocus = function () {
    if (self.$focusOnReturn) self.$focusOnReturn.focus();
    else self.$container.find('.modal-content').focus();
    self.$focusOnReturn = null;
  };

  self.UpdateScrollHeight = function () {
    self.scrollEvents($($('#eventsWizard').children('.wTBody')).hasScrollBar());
    self.scrollActivities($($('#activitiesWizard').children('.wTBody')).hasScrollBar());
    self.scrollEquipment($($('#equipmentWizard').children('.wTBody')).hasScrollBar());
    self.scrollContacts($($('#contactsWizard').children('.wTBody')).hasScrollBar());

    if (self.currentStep() == 6) {
      self.scrollActivitiesConfirm($($('#activitiesWizardConfirm').children('.wTBody')).hasScrollBar());
      self.scrollEquipmentConfirm($($('#equipmentWizardConfirm').children('.wTBody')).hasScrollBar());
      self.scrollContactsConfirm($($('#contactsWizardConfirm').children('.wTBody')).hasScrollBar());
    }
  };
};

export default RequestEditVM;
