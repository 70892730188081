import { Common } from './Common.js';

var AreaAddEditVM = function (jsonData) {
  var self = this;
  if (RazorShared.isDebug) console.log('AreaAddEditVM locator');

  self.$container = $(document);
  self.$form = $('');
  self.okCallback = function () {};
  self.Area = null;
  self.AddEditText = '';

  self.init = function ($dlgcontainer, params) {
    if ($dlgcontainer) {
      self.$container = $dlgcontainer;
      self.$form = self.$container.find('form');
    }

    if (params.okCallback) {
      self.okCallback = params.okCallback;
    }

    self.load(AreaAddEditVM.jsonData);
  };

  self.load = function (jsonData) {
    self.Area = new AreaVM(jsonData.Area);

    self.AddEditText = self.Area.AreaID ? 'Edit Area: ' + self.Area.Name() : 'Add Area';

    ko.applyBindings(self, self.$container[0]);
  };

  self.Save = function () {
    if (!self.$form.validate().form()) return;

    $.post(RazorShared.baseUrl + 'LocationMgmt/SaveArea', self.$form.serialize(), function (data) {
      if (!data.success) {
        Common.Dialog('Error saving area', null, data.error.split('|').join('<br>'));
      } else {
        self.$container.modal('hide');
        self.okCallback();
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Save Area', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };
};

var AreaVM = function (jsonData) {
  var self = this;

  self.AreaID = jsonData.AreaID || 0;
  self.Name = ko.observable(jsonData.Name || '');
};

export default AreaAddEditVM;
